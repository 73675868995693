import React, { useEffect, useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Modal } from "reactstrap";
import { useProfileUpdateMutation } from "../../API/profileAPI";
import { Countries } from "../../assets/data/countries";
import { setUpdatedUser } from "../../redux/authSlice";
import { profileModal } from "../../redux/modalSlice";

export const Profile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({});
  const [formError, setFormError] = useState("");
  const [selected, setSelected] = useState("");
  const [profileUpdate, { data, isLoading, error, isError, isSuccess }] = useProfileUpdateMutation();

  const profileModalState = useSelector((state) => state.modal.profileModal);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    // console.log("user ::", user);
    setFormData(user);
    let countryCode = "";
    for (const [key, value] of Object.entries(Countries)) {
      if (value === user?.country) {
        countryCode = key;
      }
    }
    setSelected(countryCode);
  }, []);

  const inputHandler = (event, fieldName) => {
    formData[fieldName] = event.target.value;
    setFormData(formData);
  };

  //for Image Cropping :: start
  const [imageToCrop, setImageToCrop] = useState(undefined);
  const [croppedImage, setCroppedImage] = useState(undefined);
  const [imageFile, setImageFile] = useState("");

  const onUploadFile = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setImageToCrop(reader.result));
      reader.readAsDataURL(event.target.files[0]);
      setImageFile(event.target.files);
    }
  };
  //for Image Cropping ::end

  const onSubmit = (e) => {
    e.preventDefault();
    formData["country"] = Countries[selected] ? Countries[selected] : formData.country;
    formData["profile_image"] = imageFile ? imageFile : formData.profile_image;
    delete formData.token;
    delete formData.email_verified_at;
    delete formData.email;
    delete formData.role;
    delete formData.id;
    delete formData.is_active;
    if (formData.full_name !== "" && formData.email !== "" && formData.password !== "") {
      profileUpdate(formData);
    } else {
      setFormError("email or password is wrong.");
    }
  };

  useEffect(() => {
    if (isSuccess && data.status === 200) {
      // console.log("data from profile :: ", data);
      dispatch(setUpdatedUser(data));
      dispatch(profileModal(false));
      navigate(0);
    }
  }, [isSuccess]);

  return (
    // <div
    //   className="modal fade"
    //   id="viewprofile"
    //   tabindex="-1"
    //   aria-labelledby="exampleModalLabel"
    //   aria-hidden="true"
    // >
    //   <div className="modal-dialog modal-dialog-centered">
    //     <div className="modal-content login_form">
    <Modal isOpen={profileModalState} centered={true} contentClassName="login_form">
      <div className="modal-header">
        <h1 className="modal-title" id="exampleModalLabel">
          Profile
        </h1>
        <button
          type="button"
          className="btn-close"
          onClick={() => dispatch(profileModal(false))}
          // data-bs-dismiss="modal"
          aria-label="Close"
        >
          <i className="fas fa-times"></i>
        </button>
      </div>
      <div className="modal-body">
        <form className="form_field" encType="multipart/form-data">
          <div className="profile_img">
            <div className="edit_userimage">
              <img src={imageToCrop ? imageToCrop : formData?.profile_image} alt="" title="" />
              <input type="file" className="form-control" id="inputGroupFile01" onChange={onUploadFile} />
            </div>
          </div>
          <div className="mb-3">
            <label className="mb-1">Your Full Name</label>
            <input type="text" className="form-control" placeholder="Your Name" defaultValue={formData?.full_name} onChange={(e) => inputHandler(e, "full_name")} />
          </div>
          <div className="mb-3">
            <label className="mb-1">Your Email Address</label>
            <input type="text" className="form-control" placeholder="Email Address" value={formData?.email} onChange={(e) => inputHandler(e, "email")} disabled />
          </div>
          <div className="mb-3">
            <label className="mb-1">About</label>
            <textarea className="form-control" rows={4} placeholder="What are some of your favorite places, products, things, etc.? (ex: MeUndies, Startup outdoor gear, Patagonia, Joni Mitchell, Los Angeles, the desert, Norway)" onChange={(e) => inputHandler(e, "about")} defaultValue={formData?.about}></textarea>
            {/* <input
              type="text"
              className="form-control"
              placeholder="Email Address"
              value={formData?.email}
              onChange={(e) => inputHandler(e, "email")}
              disabled
            /> */}
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="mb-3">
                <label className="mb-1">Choose Country</label>
                {/* <input
                  type="text"
                  className="form-control"
                  placeholder="Username"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value=""
                  id="country_selector"
                /> */}
                <ReactFlagsSelect
                  selected={selected}
                  onSelect={(code) => {
                    setSelected(code);
                    document.getElementById("rfs-btn").click();
                  }}
                  searchable
                  searchPlaceholder="search country"
                  className="country_selectinput"
                />
              </div>
            </div>
            <div className="modal-footer">
              <button type="buttom" className="btn btn-primary save" onClick={(e) => onSubmit(e)}>
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
    //     </div>
    //   </div>
    // </div>
  );
};
