// DraggableItem.js
import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { TrailPostHeader } from "./TrailPostHeader";
import { TrailPostCard } from "./TrailPostCard";
import { useAddHeaderPostMutation, useRemoveHeaderPostMutation } from "../API/trailHeaderAPI";

const DraggableItem = ({ postHeaders, trailSlug, trailId, userHasAccess }) => {
  const [data, setData] = useState([]);
  const [draggedPostId, setDraggedPostId] = useState(null);

  useEffect(() => {
    const headers = JSON.parse(JSON.stringify(postHeaders));
    setData(headers);
  }, [postHeaders]);

  const onDragStart = (start) => {
    setDraggedPostId(data[start.source.droppableId].heading_post[start.source.index].id);
  };

  const [addHeaderPost, { data: addHeaderPostData, isLoading: addHeaderPostIsLoading, error: addHeaderPostError, isError: addHeaderPostIsError, isSuccess: addHeaderPostIsSuccess }] = useAddHeaderPostMutation();
  const [deleteHeaderPost, { data: deleteHeaderPostData, isLoading: deleteHeaderPostIsLoading, error: deleteHeaderPostError, isError: deleteHeaderPostIsError, isSuccess: deleteHeaderPostIsSuccess }] = useRemoveHeaderPostMutation();

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const sourceIndex = parseInt(result.source.droppableId);
    const destIndex = parseInt(result.destination.droppableId);

    const sourceList = data[sourceIndex]?.heading_post || [];
    const destList = data[destIndex]?.heading_post || [];

    if (sourceIndex === destIndex) {
      // Reorder within the same header
      const updatedList = reorder(sourceList, result.source.index, result.destination.index);

      setData((prevData) => {
        const newData = [...prevData];
        newData[sourceIndex] = {
          ...newData[sourceIndex],
          heading_post: updatedList,
        };
        return newData;
      });

      // Make API call to update post order
      deletePostOrder(data[sourceIndex]?.heading_id, draggedPostId);
      updatePostOrder(data[sourceIndex]?.heading_id, draggedPostId);
    } else {
      // Move between headers
      const [movedItem] = sourceList.splice(result.source.index, 1);
      destList.splice(result.destination.index, 0, movedItem);

      setData((prevData) => {
        const newData = [...prevData];
        newData[sourceIndex] = {
          ...newData[sourceIndex],
          heading_post: sourceList,
        };
        newData[destIndex] = {
          ...newData[destIndex],
          heading_post: destList,
        };
        return newData;
      });

      // Make API calls to update post order
      deletePostOrder(data[sourceIndex]?.heading_id, draggedPostId);
      updatePostOrder(data[destIndex]?.heading_id, draggedPostId);
    }

    setDraggedPostId(null); // Reset dragged post ID
  };

  const updatePostOrder = (header_id, post_id) => {
    const formData = new FormData();
    formData.append("header_id", header_id);
    formData.append("post_id", post_id);
    addHeaderPost(formData);
  };
  const deletePostOrder = (header_id, post_id) => {
    const formData = new FormData();
    formData.append("header_id", header_id);
    formData.append("post_id", post_id);
    formData.append("trail_id", trailId);
    deleteHeaderPost(formData);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <div className="list">
        {data.map((header, headerIndex) => (
          <Droppable key={header.heading_id} droppableId={headerIndex.toString()} direction="vertical">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps} className={header.heading_post.length ? "headerx w-100" : "w-100"}>
                {userHasAccess ? (
                  // Render draggable content if the user has access
                  <React.Fragment>
                    {header.heading_id !== 0 && <TrailPostHeader header={header} trailSlug={trailSlug} userHasAccess={userHasAccess} />}
                    {header.heading_post.map((item, index) => (
                      <Draggable key={`${header.heading_id}-${item.id}`} draggableId={`${header.heading_id}-${item.id}`} index={index}>
                        {(provided, snapshot) => (
                          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="drag_icon">
                            <div className="item col-lg-12 col-md-12 col-sm-12 trail_width_responsive trail_post trail_post_1">
                              <TrailPostCard trail_post={item} key={item.slug + "_" + index} deletePostOrder={() => deletePostOrder(header.heading_id, item.id)} userHasAccess={userHasAccess} />
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </React.Fragment>
                ) : (
                  // Render non-draggable content if the user does not have access
                  <React.Fragment>
                    {header.heading_id !== 0 && <TrailPostHeader header={header} trailSlug={trailSlug} userHasAccess={userHasAccess} />}
                    {header.heading_post.map((item, index) => (
                      <div key={`${header.heading_id}-${item.id}-${index}`} className="item col-lg-12 col-md-12 col-sm-12 trail_width_responsive trail_post trail_post_1">
                        <TrailPostCard trail_post={item} key={item.slug + "_" + index} />
                        {/* Render other content as needed */}
                      </div>
                    ))}
                    {provided.placeholder}
                  </React.Fragment>
                )}
              </div>
            )}
          </Droppable>
        ))}
      </div>
    </DragDropContext>
  );
};

export default DraggableItem;
