import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Modal } from "reactstrap";
import { Link } from "react-router-dom";
import { updatesModal } from "../../redux/modalSlice";
import { useUpdatesOfPostMutation } from "../../API/postAPI";
import { useEffect } from "react";
import { config } from "../../config";
import { toast } from "react-toastify";

export const Updates = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({});
  const [formError, setFormError] = useState({});
  const [btnDisable, setBtnDisable] = useState(true);

  const user = JSON.parse(localStorage.getItem("user"));

  const [updatesOfPost, { data, isLoading, error, isError, isSuccess }] = useUpdatesOfPostMutation();

  const updatesModalState = useSelector((state) => state.modal.updatesModal);
  const updatesModalDataState = useSelector((state) => state.modal.updatesModalData);

  const inputHandler = (event, fieldName) => {
    if (event.target.value !== "") {
      formData[fieldName] = event.target.value;
      setFormData(formData);
    } else {
      setFormError(fieldName + " is required.");
    }
    if (formData && formData.updates !== "") {
      setBtnDisable(false);
    }
  };
  const onSubmit = () => {
    formData["post_id"] = updatesModalDataState?.id;
    if (formData.updates !== "") {
      updatesOfPost(formData);
    } else {
      setFormError("please enter valid text.");
    }
  };

  useEffect(() => {
    if (isSuccess && data.status === 200) {
      dispatch(updatesModal({ modal: false }));
      navigate(0);
      toast(data.message, {
        ...config.toast_config,
        type: "success",
      });
    } else if (isSuccess && data.status === 422) {
      toast(data.message, {
        ...config.toast_config,
        type: "error",
      });
    }
  }, [isSuccess]);

  // console.log("updatesModalDataState :: ", updatesModalDataState);
  return (
    <Modal isOpen={updatesModalState} centered={true} contentClassName="login_form">
      <div className="modal-header">
        <h1 className="modal-title" id="exampleModalLabel">
          Post's Updates
        </h1>
        <button type="button" className="btn-close" aria-label="Close" onClick={() => dispatch(updatesModal({ modal: false, postId: undefined }))}>
          <i className="fas fa-times"></i>
        </button>
      </div>
      <div className="modal-body">
        <form className="form_field">
          <div className="mt-3">
            <label className="mb-1">updates</label>
            <textarea className="form-control" rows="4" placeholder="updates" defaultValue={updatesModalDataState?.data} name="updates" onChange={(e) => inputHandler(e, "updates")}></textarea>
          </div>
        </form>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          // data-bs-toggle="modal"
          // data-bs-target="#thankyou"
          className="btn btn-primary save"
          onClick={() => onSubmit()}
          disabled={btnDisable}
        >
          Submit
        </button>
      </div>
    </Modal>
  );
};
