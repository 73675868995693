import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetAnalyticsQuery, useGetUserTagsAnalyticsQuery, useGetViewAllAnalyticsQuery } from "../../API/analyticsAPI";
import { AES, enc } from "crypto-js";
import { config } from "../../config";

export const PostAnalytics = () => {
  const { id } = useParams();
  const [selectedTab, setSelectedTab] = useState("week");

  //Set Anlaytics Data::start
  const { data: analyticalData, isLoading: analyticalDataIsLoading, isSuccess: analyticalDataIsSuccess, isError: analyticalDataIsError, error: analyticalDataError } = useGetViewAllAnalyticsQuery({ duration: selectedTab, post_id: id });
  const [postAnalytics, setPostAnalytics] = useState({});
  useEffect(() => {
    if (analyticalData && analyticalData.status === 200 && analyticalData.data && analyticalData.data.length) {
      setPostAnalytics(analyticalData.data[0]);
    }
  }, [analyticalData, analyticalDataIsSuccess, selectedTab]);
  //Set Anlaytics Data::end

  //Set Tags Data::start
  const { data: analyticalTagData, isLoading: analyticalTagDataIsLoading, isSuccess: analyticalTagDataIsSuccess, isError: analyticalTagDataIsError, error: analyticalTagDataError } = useGetUserTagsAnalyticsQuery(id);
  const [postTagAnalytics, setPostTagAnalytics] = useState([]);
  useEffect(() => {
    console.log("analyticalTagData :: ", analyticalTagData);
    if (analyticalTagData && analyticalTagData.status === 200 && analyticalTagData.data && analyticalTagData.data.length) {
      setPostTagAnalytics(analyticalTagData.data[0].tag_vote);
      // setPostTagAnalytics([
      //   {
      //     tag: "#outdoorsy",
      //     vote: 1,
      //   },
      //   {
      //     tag: "#outdoorsy",
      //     vote: 1,
      //   },
      //   {
      //     tag: "#outdoorsy",
      //     vote: 1,
      //   },
      //   {
      //     tag: "#outdoorsy",
      //     vote: 1,
      //   },
      //   {
      //     tag: "#outdoorsy",
      //     vote: 1,
      //   },
      //   {
      //     tag: "#outdoorsy",
      //     vote: 1,
      //   },
      //   {
      //     tag: "#outdoorsy",
      //     vote: 1,
      //   },
      //   {
      //     tag: "#outdoorsy",
      //     vote: 1,
      //   },
      // ]);
    }
    console.log("postTagAnalytics   :: ", postTagAnalytics);
  }, [analyticalTagData, analyticalTagDataIsSuccess]);
  //Set Tags Data::end

  //Set Trends Data::start
  const { data: analyticalTrendData, isLoading: analyticalTrendDataIsLoading, isSuccess: analyticalTrendDataIsSuccess, isError: analyticalTrendDataIsError, error: analyticalTrendDataError } = useGetAnalyticsQuery(id);
  const [postTrendAnalytics, setPostTrendAnalytics] = useState([]);
  useEffect(() => {
    let anaData = [];
    if (analyticalTrendData && analyticalTrendData.status === 200 && analyticalTrendData.data && analyticalTrendData.data.top3Tag && analyticalTrendData.data.top3Tag.length) {
      analyticalTrendData.data.top3Tag.forEach((tag, index) => {
        if (tag.post_id == id) {
          if (tag.tag_vote && tag.tag_vote.length) {
            anaData = tag.tag_vote;
          }
        }
      });
    }

    if (anaData && anaData.length) setPostTrendAnalytics(anaData);
  }, [analyticalTrendData, analyticalTrendDataIsSuccess]);
  //Set Trends Data::end
  return (
    <>
      <div className="row">
        <div className="col-8">
          <div className="subscriptions_tab">
            <div className="col-lg-3 col-md-4 m-auto">
              <ul className="nav nav-pills mb-4 nav-justified" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className={selectedTab === "week" ? "nav-link active" : "nav-link"} id="pills-week-tab" data-bs-toggle="pill" data-bs-target="#pills-week" type="button" role="tab" aria-controls="pills-User" aria-selected="true" onClick={() => setSelectedTab("week")}>
                    Week
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className={selectedTab === "month" ? "nav-link active" : "nav-link"} id="pills-month-tab" data-bs-toggle="pill" data-bs-target="#pills-month" type="button" role="tab" aria-controls="pills-Vendor" aria-selected="false" onClick={() => setSelectedTab("month")}>
                    Month
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className={selectedTab === "year" ? "nav-link active" : "nav-link"} id="pills-year-tab" data-bs-toggle="pill" data-bs-target="#pills-year" type="button" role="tab" aria-controls="pills-Vendor" aria-selected="false" onClick={() => setSelectedTab("year")}>
                    Year
                  </button>
                </li>
              </ul>
            </div>

            <div className="tab-content" id="pills-tabContent">
              {/* <div className="row">
          <h4 className="mb-3">
            <b>Post Name: </b>
            {postAnalytics.hasOwnProperty("post_name") ? postAnalytics.post_name : ""}
          </h4>
        </div> */}
              <div className="tab-pane fade show active" id="pills-week" role="tabpanel" aria-labelledby="pills-week-tab" tabIndex="0">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Analytics Name</th>
                      <th scope="col">Previous {selectedTab}</th>
                      <th scope="col">Current {selectedTab}</th>
                      <th scope="col">Change(%)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {postAnalytics.hasOwnProperty("fav") ? (
                      <tr>
                        <th scope="row">Favourites</th>
                        <td>{postAnalytics["fav"].prevoius}</td>
                        <td>{postAnalytics["fav"].current}</td>
                        <td>
                          <i className={postAnalytics["fav"].percentage === 0 ? "" : postAnalytics["fav"].percentage > 0 ? "fa fa-arrow-up text-success mr-1" : "fa fa-arrow-down text-danger mr-1"}></i>
                          <span style={{ paddingLeft: "10px" }}>{postAnalytics["fav"].percentage + " %"} </span>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <th scope="row">Favourites</th>
                        <td>N/A</td>
                        <td>N/A</td>
                        <td>N/A</td>
                      </tr>
                    )}
                    {postAnalytics.hasOwnProperty("trailPost") ? (
                      <tr>
                        <th scope="row">Trail Post</th>
                        <td>{postAnalytics["trailPost"].prevoius}</td>
                        <td>{postAnalytics["trailPost"].current}</td>
                        <td>
                          <i className={postAnalytics["trailPost"].percentage === 0 ? "" : postAnalytics["trailPost"].percentage > 0 ? "fa fa-arrow-up text-success mr-1" : "fa fa-arrow-down text-danger mr-1"}></i>
                          <span style={{ paddingLeft: "10px" }}>{postAnalytics["trailPost"].percentage + " %"} </span>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <th scope="row">Trail Post</th>
                        <td>N/A</td>
                        <td>N/A</td>
                        <td>N/A</td>
                      </tr>
                    )}
                    {postAnalytics.hasOwnProperty("tag") ? (
                      <tr>
                        <th scope="row">Tag</th>
                        <td>{postAnalytics["tag"].prevoius}</td>
                        <td>{postAnalytics["tag"].current}</td>
                        <td>
                          <i className={postAnalytics["tag"].percentage === 0 ? "" : postAnalytics["tag"].percentage > 0 ? "fa fa-arrow-up text-success mr-1" : "fa fa-arrow-down text-danger mr-1"}></i>
                          <span style={{ paddingLeft: "10px" }}>{postAnalytics["tag"].percentage + " %"} </span>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <th scope="row">Tag</th>
                        <td>N/A</td>
                        <td>N/A</td>
                        <td>N/A</td>
                      </tr>
                    )}
                    {postAnalytics.hasOwnProperty("share") ? (
                      <tr>
                        <th scope="row">Share</th>
                        <td>{postAnalytics["share"].prevoius}</td>
                        <td>{postAnalytics["share"].current}</td>
                        <td>
                          <i className={postAnalytics["share"].percentage === 0 ? "" : postAnalytics["share"].percentage > 0 ? "fa fa-arrow-up text-success mr-1" : "fa fa-arrow-down text-danger mr-1"}></i>
                          <span style={{ paddingLeft: "10px" }}>{postAnalytics["share"].percentage + " %"} </span>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <th scope="row">Share</th>
                        <td>N/A</td>
                        <td>N/A</td>
                        <td>N/A</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="tab-pane fade" id="pills-month" role="tabpanel" aria-labelledby="pills-month-tab" tabIndex="0">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Analytics Name</th>
                      <th scope="col">Previous {selectedTab}</th>
                      <th scope="col">Current {selectedTab}</th>
                      <th scope="col">Change(%)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {postAnalytics.hasOwnProperty("fav") ? (
                      <tr>
                        <th scope="row">Favourites</th>
                        <td>{postAnalytics["fav"].prevoius}</td>
                        <td>{postAnalytics["fav"].current}</td>
                        <td>
                          <i className={postAnalytics["fav"].percentage === 0 ? "" : postAnalytics["fav"].percentage > 0 ? "fa fa-arrow-up text-success mr-1" : "fa fa-arrow-down text-danger mr-1"}></i>
                          <span style={{ paddingLeft: "10px" }}>{postAnalytics["fav"].percentage + " %"} </span>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <th scope="row">Favourites</th>
                        <td>N/A</td>
                        <td>N/A</td>
                        <td>N/A</td>
                      </tr>
                    )}
                    {postAnalytics.hasOwnProperty("trailPost") ? (
                      <tr>
                        <th scope="row">Trail Post</th>
                        <td>{postAnalytics["trailPost"].prevoius}</td>
                        <td>{postAnalytics["trailPost"].current}</td>
                        <td>
                          <i className={postAnalytics["trailPost"].percentage === 0 ? "" : postAnalytics["trailPost"].percentage > 0 ? "fa fa-arrow-up text-success mr-1" : "fa fa-arrow-down text-danger mr-1"}></i>
                          <span style={{ paddingLeft: "10px" }}>{postAnalytics["trailPost"].percentage + " %"} </span>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <th scope="row">Trail Post</th>
                        <td>N/A</td>
                        <td>N/A</td>
                        <td>N/A</td>
                      </tr>
                    )}
                    {postAnalytics.hasOwnProperty("tag") ? (
                      <tr>
                        <th scope="row">Tag</th>
                        <td>{postAnalytics["tag"].prevoius}</td>
                        <td>{postAnalytics["tag"].current}</td>
                        <td>
                          <i className={postAnalytics["tag"].percentage === 0 ? "" : postAnalytics["tag"].percentage > 0 ? "fa fa-arrow-up text-success mr-1" : "fa fa-arrow-down text-danger mr-1"}></i>
                          <span style={{ paddingLeft: "10px" }}>{postAnalytics["tag"].percentage + " %"} </span>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <th scope="row">Tag</th>
                        <td>N/A</td>
                        <td>N/A</td>
                        <td>N/A</td>
                      </tr>
                    )}
                    {postAnalytics.hasOwnProperty("share") ? (
                      <tr>
                        <th scope="row">Share</th>
                        <td>{postAnalytics["share"].prevoius}</td>
                        <td>{postAnalytics["share"].current}</td>
                        <td>
                          <i className={postAnalytics["share"].percentage === 0 ? "" : postAnalytics["share"].percentage > 0 ? "fa fa-arrow-up text-success mr-1" : "fa fa-arrow-down text-danger mr-1"}></i>
                          <span style={{ paddingLeft: "10px" }}>{postAnalytics["share"].percentage + " %"} </span>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <th scope="row">Share</th>
                        <td>N/A</td>
                        <td>N/A</td>
                        <td>N/A</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="tab-pane fade" id="pills-year" role="tabpanel" aria-labelledby="pills-year-tab" tabIndex="0">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Analytics Name</th>
                      <th scope="col">Previous {selectedTab}</th>
                      <th scope="col">Current {selectedTab}</th>
                      <th scope="col">Change(%)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {postAnalytics.hasOwnProperty("fav") ? (
                      <tr>
                        <th scope="row">Favourites</th>
                        <td>{postAnalytics["fav"].prevoius}</td>
                        <td>{postAnalytics["fav"].current}</td>
                        <td>
                          <i className={postAnalytics["fav"].percentage === 0 ? "" : postAnalytics["fav"].percentage > 0 ? "fa fa-arrow-up text-success mr-1" : "fa fa-arrow-down text-danger mr-1"}></i>
                          <span style={{ paddingLeft: "10px" }}>{postAnalytics["fav"].percentage + " %"} </span>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <th scope="row">Favourites</th>
                        <td>N/A</td>
                        <td>N/A</td>
                        <td>N/A</td>
                      </tr>
                    )}
                    {postAnalytics.hasOwnProperty("trailPost") ? (
                      <tr>
                        <th scope="row">Trail Post</th>
                        <td>{postAnalytics["trailPost"].prevoius}</td>
                        <td>{postAnalytics["trailPost"].current}</td>
                        <td>
                          <i className={postAnalytics["trailPost"].percentage === 0 ? "" : postAnalytics["trailPost"].percentage > 0 ? "fa fa-arrow-up text-success mr-1" : "fa fa-arrow-down text-danger mr-1"}></i>
                          <span style={{ paddingLeft: "10px" }}>{postAnalytics["trailPost"].percentage + " %"} </span>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <th scope="row">Trail Post</th>
                        <td>N/A</td>
                        <td>N/A</td>
                        <td>N/A</td>
                      </tr>
                    )}
                    {postAnalytics.hasOwnProperty("tag") ? (
                      <tr>
                        <th scope="row">Tag</th>
                        <td>{postAnalytics["tag"].prevoius}</td>
                        <td>{postAnalytics["tag"].current}</td>
                        <td>
                          <i className={postAnalytics["tag"].percentage === 0 ? "" : postAnalytics["tag"].percentage > 0 ? "fa fa-arrow-up text-success mr-1" : "fa fa-arrow-down text-danger mr-1"}></i>
                          <span style={{ paddingLeft: "10px" }}>{postAnalytics["tag"].percentage + " %"} </span>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <th scope="row">Tag</th>
                        <td>N/A</td>
                        <td>N/A</td>
                        <td>N/A</td>
                      </tr>
                    )}
                    {postAnalytics.hasOwnProperty("share") ? (
                      <tr>
                        <th scope="row">Share</th>
                        <td>{postAnalytics["share"].prevoius}</td>
                        <td>{postAnalytics["share"].current}</td>
                        <td>
                          <i className={postAnalytics["share"].percentage === 0 ? "" : postAnalytics["share"].percentage > 0 ? "fa fa-arrow-up text-success mr-1" : "fa fa-arrow-down text-danger mr-1"}></i>
                          <span style={{ paddingLeft: "10px" }}>{postAnalytics["share"].percentage + " %"} </span>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <th scope="row">Share</th>
                        <td>N/A</td>
                        <td>N/A</td>
                        <td>N/A</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="col-4">
          {postTagAnalytics && postTagAnalytics.length ? (
            <div className="my-3 p-3 " style={{ borderRadius: "20px" }}>
              <h4 className="heading_title text-center">User Tags</h4>
              <table className="table table-striped ">
                <thead>
                  <th>Tag</th>
                  <th>Vote</th>
                </thead>
                <tbody className="overflow-auto">
                  {postTagAnalytics.map((tag, index) => (
                    <tr key={"analyticsTagData_" + index}>
                      <td>{tag.tag}</td>
                      <td>{tag.vote}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-8">
          <h4 className="heading_title text-center">Trends</h4>
          <div className="d-flex rightside_detail">
            {postTrendAnalytics.length
              ? postTrendAnalytics.map((tag, index) => (
                  <div className="d-flex justify-content-between btn_save btn_outline m-2" key={"analyticsTagData_" + index}>
                    <span>{tag.tag}</span>
                    <span>{tag.vote}</span>
                  </div>
                ))
              : ""}
          </div>
        </div>
      </div>
    </>
  );
};
