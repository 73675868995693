import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Modal } from "reactstrap";
import { useAddTrailMutation, useUpdateTrailMutation } from "../../API/trailAPI";
import { addTrailModal, yourTrailsModal } from "../../redux/modalSlice";
import { WithContext as ReactTags } from "react-tag-input";
import * as Yup from "yup";
import { Formik } from "formik";
import { config } from "../../config";
import { toast } from "react-toastify";

export const TrailAdd = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const initialValues = { trail_name: "", trail_type: 1, trail_description: "", tags: [] };
  const [editFormData, setEditFormData] = useState(false);

  const [formError, setFormError] = useState("");
  const addTrailModalState = useSelector((state) => state.modal.addTrailModal);

  const closeModal = () => {
    dispatch(addTrailModal(false));
    localStorage.removeItem("editTrail");
  };
  const handleTagClick = (index) => {
    // console.log("The tag at index " + index + " was clicked");
  };

  useEffect(() => {
    const trail = localStorage.getItem("editTrail") ? JSON.parse(localStorage.getItem("editTrail")) : undefined;

    if (trail) {
      setEditFormData(true);
    }
  }, [addTrailModalState]);

  const [addTrail, { data: trailData, isLoading, error, isError, isSuccess }] = useAddTrailMutation();
  const [updateTrail, { data: updateTrailPostData, isLoading: updateTrailPostDataIsLoading, isSuccess: updateTrailPostDataIsSuccess, isError: updateTrailPostDataIsError, error: updateTrailPostDataError }] = useUpdateTrailMutation();
  const postIdState = useSelector((state) => state.modal.postId);

  const onSubmit = (values) => {
    const formData = postIdState ? { ...values, trail_post: postIdState } : { ...values };
    const trail = localStorage.getItem("editTrail") ? JSON.parse(localStorage.getItem("editTrail")) : undefined;
    formData["trail_id"] = trail?.id;
    formData["tags"] = values.tags
      ? JSON.stringify(
        values.tags.map((e) => {
          return { trail_tag: e.text };
        })
      )
      : undefined;
    if (editFormData) {
      updateTrail(formData);
    } else {
      addTrail(formData);
    }
  };

  useEffect(() => {
    if ((trailData?.status === 200 && isSuccess) || (updateTrailPostData?.status === 200 && updateTrailPostDataIsSuccess)) {
      if (localStorage.getItem("editTrail")) localStorage.removeItem("editTrail");
      if (isSuccess && trailData.status === 200) {
        dispatch(yourTrailsModal({ modal: false, post_id: undefined }));
        toast(trailData.message, {
          ...config.toast_config,
          type: "success",
        });
      } else if (isSuccess && trailData.status === 422) {
        toast(trailData.message, {
          ...config.toast_config,
          type: "error",
        });
      }
      closeModal();
      navigate(0);
    } else {
      if (editFormData) {
        setFormError(updateTrailPostData?.message);
      } else {
        setFormError(trailData?.message);
      }
    }
  }, [isSuccess, updateTrailPostDataIsSuccess]);

  const KeyCodes = {
    tab: 9,
    space: 32,
    comma: 188,
    enter: 13,
  };

  const TrailAddSchema = Yup.object().shape({
    trail_name: Yup.string().required("Trail Name is required."),
    trail_description: Yup.string().required("Trail Description is required."),
    trail_type: Yup.number().required("Trail Type is required."),
    tags: Yup.array()
      .min(0, "Min one tag is required.")
      .of(Yup.object().shape({ id: Yup.string().required(), text: Yup.string().required() })),
  });

  const delimiters = [KeyCodes.tab, KeyCodes.space, KeyCodes.comma, KeyCodes.enter];

  return (
    <Modal isOpen={addTrailModalState} centered={true} contentClassName="login_form" toggle={closeModal}>
      <div className="modal-header">
        <h1 className="modal-title" id="exampleModalLabel">
          {/* <Link to="#">
            <i
              className="fas fa-chevron-left me-3"
              style={{ color: "#66737F", fontSize: "15px" }}
            ></i>
          </Link> */}
          Add a trail list
        </h1>
        <button type="button" className="btn-close" aria-label="Close" onClick={() => closeModal()}>
          <i className="fas fa-times"></i>
        </button>
      </div>

      <Formik
        initialValues={{
          trail_name: localStorage.getItem("editTrail") ? JSON.parse(localStorage.getItem("editTrail"))?.trail_name : initialValues.trail_name,
          trail_type: localStorage.getItem("editTrail") ? JSON.parse(localStorage.getItem("editTrail"))?.trail_type : initialValues.trail_type,
          trail_description: localStorage.getItem("editTrail") ? JSON.parse(localStorage.getItem("editTrail"))?.trail_description : initialValues.trail_description,
          tags: localStorage.getItem("editTrail")
            ? JSON.parse(localStorage.getItem("editTrail"))?.tags?.map((tag) => {
              return {
                id: tag.tag_name,
                text: tag.tag_name,
              };
            }) || []
            : initialValues.tags,
        }}
        validationSchema={TrailAddSchema}
        onSubmit={(values) => {
          onSubmit(values);
        }}
      >
        {({ errors, touched, handleSubmit, values, handleChange, setFieldValue }) => (
          <div>
            <div className="modal-body">
              {formError !== "" ? <div className="my-2 text-danger">{formError}</div> : ""}

              <form className="form_field">
                <div>
                  <label className="mb-2">Trail list name</label>
                  <input type="text" className="form-control" placeholder="Trail list name" name="trail_name" value={values.trail_name} onChange={handleChange} />
                  {errors.trail_name && touched.trail_name && <p className="text-danger">{errors.trail_name}</p>}
                </div>
                <div>
                  <label className="mb-1">Trail Type</label>
                  <div className="d-flex justify-between">
                    <div className="form-check" style={{ marginRight: "20px" }}>
                      <input className="form-check-input" type="radio" name="trail_type" value={1} onChange={() => setFieldValue("trail_type", 1)} checked={values.trail_type == 1} />
                      <label className="form-check-label" htmlFor="flexRadioDefault1">
                        Public
                      </label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="trail_type" value={2} onChange={() => setFieldValue("trail_type", 2)} checked={values.trail_type == 2} />
                      <label className="form-check-label" htmlFor="flexRadioDefault2">
                        Private
                      </label>
                    </div>
                  </div>
                </div>
                <div className="mt-2">
                  <label className="mb-2">Trail Description</label>
                  <textarea className="form-control" rows="3" placeholder="Trail Description" name="trail_description" value={values.trail_description} onChange={handleChange}></textarea>
                  {errors.trail_description && touched.trail_description && <p className="text-danger">{errors.trail_description}</p>}
                </div>
                {console.log("values: ", values)}
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                  <label className="mb-1">Your #tag</label>
                  <ReactTags
                    classNames={{
                      tags: "tagsClass",
                      tagInput: "tagInputClass",
                      tagInputField: "form-control",
                      selected: "selectedClass",
                      tag: "tagClass",
                      remove: "removeClass",
                      suggestions: "suggestionsClass",
                      activeSuggestion: "activeSuggestionClass",
                      editTagInput: "editTagInputClass",
                      editTagInputField: "editTagInputField",
                      clearAll: "clearAllClass",
                    }}
                    tags={values.tags}
                    delimiters={delimiters}
                    handleDelete={(i) => {
                      setFieldValue(
                        "tags",
                        values.tags.filter((tag, index) => index !== i)
                      );
                    }}
                    handleAddition={(tag) => {
                      console.log(tag);
                      setFieldValue("tags", [...values.tags, tag]);
                    }}
                    handleDrag={(tag, currPos, newPos) => {
                      const newTags = values.tags.slice();

                      newTags.splice(currPos, 1);
                      newTags.splice(newPos, 0, tag);

                      // re-render
                      setFieldValue("tags", newTags);
                    }}
                    handleTagClick={handleTagClick}
                    inputFieldPosition="bottom"
                    autocomplete
                    allowUnique
                    autofocus={false}
                  />
                  {errors["tags"] && touched.tags && <div className="col-12 text-danger">{errors["tags"]}</div>}
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button type="submit" className="btn btn-primary save" onClick={() => handleSubmit(values)}>
                {editFormData ? "Update" : "Create"}
              </button>
            </div>
          </div>
        )}
      </Formik>
    </Modal>
  );
};
