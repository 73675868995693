import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Modal } from "reactstrap";
import { Link } from "react-router-dom";
import { useAddThruhikeConciergeMutation } from "../../API/trailAPI";
import { thruhikeConciergeModal, thruhikeConciergeThanksModal } from "../../redux/modalSlice";
import { useEffect } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { config } from "../../config";
import { toast } from "react-toastify";

export const ThruhikeConcierge = () => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();

  const [formData, setFormData] = useState({});
  const [conciergeType, setConciergeType] = useState([]);

  const [emailError, setEmailError] = useState("");
  const [conciergeTypeError, setConciergeTypeError] = useState("");
  const [customInspirationError, setCustomInspirationError] = useState("");

  const user = JSON.parse(localStorage.getItem("user"));

  const [addThruhikeConcierge, { data, isLoading, error, isError, isSuccess }] = useAddThruhikeConciergeMutation();

  const thruhikeConciergeModalState = useSelector((state) => state.modal.thruhikeConciergeModal);

  const closeModal = () => {
    dispatch(thruhikeConciergeModal(false));
    setEmailError("");
    setConciergeTypeError("");
    setCustomInspirationError("");
    setConciergeType([]);
  };


  const onSubmit = (values) => {
    addThruhikeConcierge({ ...values, concierge_type: JSON.stringify(values.concierge_type) });

  };

  useEffect(() => {
    if (isSuccess && data.status === 200) {
      closeModal();
      // toast(data.message, {
      //   ...config.toast_config,
      //   type: "success",
      // });
      dispatch(thruhikeConciergeThanksModal(true));
    } else if (isSuccess && data.status === 422) {
      toast(data.message, {
        ...config.toast_config,
        type: "error",
      });
    }
  }, [isSuccess]);

  const ThruHikeConciergeSchema = Yup.object().shape(
    {
      concierge_email: Yup.string().email("Please enter valid email").required("Email is required."),
      concierge_type: Yup.array()
        .min(1, "Min one item selection is required.")
        .of(Yup.object().shape({ type: Yup.string().required() })),
      concierge_other: Yup.string().when(["concierge_type"], {
        is: (type) => {
          return type.filter((e) => e.type === "Other").length > 0;
        },
        then: (schema) => schema.required("Other is required."),
      }),
      custom_inspiration: Yup.string()
    },
    [["concierge_type"]]
  );

  return (
    <Modal isOpen={thruhikeConciergeModalState} centered={true} contentClassName="login_form" toggle={closeModal}>
      <div className="modal-header">
        <h1 className="modal-title" id="exampleModalLabel">
          Thruhike Concierge
        </h1>
        <button type="button" className="btn-close" aria-label="Close" onClick={() => closeModal()}>
          <i className="fas fa-times"></i>
        </button>
      </div>
      <div className="modal-body">
        <p>
          Need help planning? We can help! Fee dependent on request. Also, check out our
          <Link className="link-popup" to="/subscriptions" onClick={() => dispatch(thruhikeConciergeModal(false))}>
            consumer subscriptions.
          </Link>
        </p>
      </div>

      <Formik
        initialValues={{
          concierge_email: JSON.parse(localStorage.getItem("user"))?.email,
          concierge_type: [],
          concierge_other: "",
        }}
        validationSchema={ThruHikeConciergeSchema}
        onSubmit={(values) => {
          onSubmit(values);
        }}
      >
        {({ errors, touched, handleSubmit, values, handleChange, isInitialValid, isValid, setFieldValue }) => (
          <div>
            <div className="modal-body">
              <form className="form_field">
                <div>
                  <label className="mb-1">Your Email Address</label>
                  <input type="text" name="concierge_email" className="form-control" placeholder="Email Address" onChange={handleChange} value={values.concierge_email} />
                  {errors.concierge_email && touched.concierge_email && <p className="text-danger">{errors.concierge_email}</p>}
                </div>
                <div className="mt-3">
                  <label className="mb-1">What concierge services are you interested in?</label>
                  {errors.concierge_type && <p className="text-danger">{errors.concierge_type}</p>}

                  {["Book my trip", "Order my holiday presents", "Manage my brand", "Travel or brand consultation", "Other"].map((type) => (
                    <div className="form-check" key={type}>
                      <input
                        className="form-check-input option-input radio"
                        type="checkbox"
                        value={type}
                        onChange={() => {
                          const index = values.concierge_type.findIndex((e) => e.type === type);
                          if (index === -1) {
                            values.concierge_type = [...values.concierge_type, { type: type }];
                            setFieldValue({ ...values });
                          } else {
                            values.concierge_type.splice(index, 1);
                            setFieldValue({ ...values });
                          }
                        }}
                        checked={values.concierge_type.filter((e) => e.type === type).length > 0}
                      />
                      <label className="form-check-label" htmlFor="flexRadioDefault1">
                        {type}
                      </label>
                    </div>
                  ))}
                </div>

                {values.concierge_type && values.concierge_type.filter((e) => e.type === "Other").length > 0 && (
                  <div className="mt-3">
                    <input type="text" className="form-control" name="concierge_other" placeholder="Other" onChange={handleChange} value={values.concierge_other} />
                  </div>
                )}

                {errors.concierge_other && <p className="text-danger">{errors.concierge_other}</p>}

                <div className="mt-3">
                  <label className="mb-1">Anything else you'd like us to know?</label>
                  <textarea className="form-control" id="" rows="4" placeholder="i.e. a specific vibe you'd like for the trail list or any initial places/products you want to provide as inspiration" onChange={handleChange} name="custom_inspiration" value={values.custom_inspiration}></textarea>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary save" onClick={() => handleSubmit(values)} disabled={!isValid}>
                Submit
              </button>
            </div>
          </div>
        )}
      </Formik>
    </Modal>
  );
};
