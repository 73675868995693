import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal } from "reactstrap";
import { useAddTrailPostMutation, useGetMyTrailsQuery } from "../../API/trailAPI";
import { TrailListCard } from "../../components/TrailListCard";
import { config } from "../../config";
import { addTrailModal, yourTrailsModal } from "../../redux/modalSlice";
import NoFoundComponent from "../NoFoundComponent";

export const YourTrails = () => {
  const dispatch = useDispatch();
  const [searchKey, setSearchKey] = useState("");

  const [addTrailPost, { data: addTrailToPostData, isLoading: addTrailToPostLoad, isSuccess: addTrailToPostSuccess, isError: addTrailToPostIsError, error: addTrailToPostError }] = useAddTrailPostMutation();

  const { data: trails, isLoading, isSuccess, isError, error } = useGetMyTrailsQuery(searchKey);

  const yourTrailsModalState = useSelector((state) => state.modal.yourTrailsModal);
  const postIdState = useSelector((state) => state.modal.postId);

  const addTrailToPost = (id) => {
    // console.log("trail-Id ::", id);
    // console.log("post-Id ::", postIdState);
    if (postIdState !== undefined) {
      addTrailPost({ post_id: postIdState, trail_id: id });
    }
  };

  useEffect(() => {
    if (addTrailToPostSuccess && addTrailToPostData.status === 200) {
      toast(addTrailToPostData.message, {
        ...config.toast_config,
        type: "success",
      });
      dispatch(yourTrailsModal({ modal: false, postId: undefined }));
    } else if (addTrailToPostSuccess && addTrailToPostData.status === 422) {
      toast(addTrailToPostData.message, {
        ...config.toast_config,
        type: "error",
      });
    }
  }, [addTrailToPostSuccess]);

  return (
    // <div
    //   className="modal fade"
    //   id="traipopup"
    //   tabIndex="-1"
    //   aria-labelledby="exampleModalLabel"
    //   aria-hidden="true"
    // >
    //   <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    //     <div className="modal-content">
    <Modal isOpen={yourTrailsModalState} centered={true} contentClassName="login_form">
      <div className="modal-header">
        <h1 className="modal-title" id="exampleModalLabel">
          Your Trails
        </h1>
        <button type="button" className="btn-close" aria-label="Close" onClick={() => dispatch(yourTrailsModal({ modal: false, postId: undefined }))}>
          <i className="fas fa-times"></i>
        </button>
      </div>
      <div className="modal-body">
        <form className="d-flex search_box col-md-12" role="search">
          <input className="form-control" type="search" placeholder="Search your trail" aria-label="Search" onChange={(e) => setSearchKey(e.target.value)} />
          <button className="" type="submit">
            <img src={require("../../assets/images/Serach.png")} alt="" title="" />
          </button>
        </form>
        <div className="text-center my-4">
          <Link to="#" className="create_trail_btn" onClick={() => dispatch(addTrailModal(true))}>
            <i className="fas fa-plus me-2"></i> Create Your Trail
          </Link>
        </div>
        {isSuccess && trails?.data.length ? <TrailListCard data={trails?.data} classList="col-md-12" displayHeart={false} link={false} clickFunction={addTrailToPost} /> : <NoFoundComponent message="Trails" />}
      </div>
    </Modal>
    //     </div>
    //   </div>
    // </div>
  );
};
