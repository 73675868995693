import React from "react";
import ReactLoading from "react-loading";

export const Loader = () => {
  return (
    <div className="loader-container">
      <ReactLoading type="spin" color="#fff" className="loader" />
    </div>
  );
};
