import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useSigninUserMutation } from "../../API/authAPI";
import { setSignUpUser, setUser } from "../../redux/authSlice";
import $ from "jquery";
import { Modal } from "reactstrap";
import { forgotPasswordModal, introSliderModal, logInModal, signUpModal, verifyEmailModal } from "../../redux/modalSlice";
import { useEffect } from "react";
import { Link } from "react-router-dom";

import * as Yup from "yup";
import { Formik } from "formik";
import { config } from "../../config";
import { toast } from "react-toastify";

const IntroSlider = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [index, setIndex] = useState(0);

  const introSliderModalState = useSelector((state) => state.modal.introSliderModal);
  console.log(" :: introSliderModalState :: ", introSliderModalState);
  return (
    <Modal isOpen={introSliderModalState} centered={true} contentClassName="login_form">
      <div className="modal-header">
        <h1 className="modal-title text-center m-auto" id="exampleModalLabel">
          <img src={require("../../assets/images/favicon.png")} alt="" title="" />
          <p className="model_title_header">Thruhike</p>
        </h1>
        <button
          type="button"
          className="btn-close"
          // data-bs-dismiss="modal"
          // aria-label="Close"
          onClick={() => dispatch(introSliderModal(false))}
        >
          <i className="fas fa-times"></i>
        </button>
      </div>
      <div className="modal-body">
        <p className={index === 0 ? "d-block" : "d-none"}>
          <strong style={{fontSize:"20px"}}>How to search</strong> <br /> (example cozy Scandinavian cafe,candlelit Italian restaurant, etc.)
          Make sure to fill out about me in profile and take personalization quiz
        </p>
        <p className={index === 1 ? "d-block" : "d-none"}>
          What is trail list and how to make one list of places and products (like an itinerary)
        </p>
        <p className={index === 2 ? "d-block" : "d-none"}>
          How to interact with people favorite, share, etc. Make sure to fill out about me in profile and take personalization quiz
        </p>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-primary save"
          onClick={() => {
            let tmpIndex = index + 1;
            console.log("tmpIndex :: ", tmpIndex);
            if (tmpIndex < 3) {
              setIndex(tmpIndex);
            } else {
              dispatch(introSliderModal(false));
            }
          }}
        >
          Next
        </button>
      </div>
    </Modal>
  );
};

export default IntroSlider;
