import React from "react";
import { TrailPostHeader } from "../../components/TrailPostHeader";
import DraggableComponent from "../../components/DraggableItem";
import DraggableItem from "../../components/DraggableItem";
import { Loader } from "../../components/Loader";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { config } from "../../config";
import { AddHeader } from "../trail-header/AddHeader";
import { addTagModal, addTrailModal, logInModal, tagArrayUpdated, trailHeaderModal } from "../../redux/modalSlice";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useDeleteTrailMutation, useDownVoteTrailMutation, useFavouriteTrailMutation, useGetTrailsPostsQuery, useUnFavouriteTrailMutation, useUpVoteTrailMutation } from "../../API/trailAPI";
import { toast } from "react-toastify";
import { useEffect } from "react";
import NoFoundComponent from "../NoFoundComponent";
import { DeleteHeader } from "../trail-header/DeleteHeader";

export const TrailLatest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { id } = useParams();

  const searchText = useSelector((state) => state.modal.searchText);
  const [favourite, setFavourite] = useState(0);
  const [readMore, setReadMore] = useState(false);
  const location = useSelector((state) => state.modal.location);

  const { data: viewTrailPosts, isLoading, isSuccess, isError, error } = useGetTrailsPostsQuery({ id, searchText, location });

  const [upVotePost, { data: upVotePostTagData, isLoading: upVotePostTagIsLoading, isSuccess: upVotePostTagIsSuccess, isError: upVotePostTagIsError, error: upVotePostTagError }] = useUpVoteTrailMutation();
  const [downVotePost, { data: downVoteData, isLoading: downVoteIsLoading, isSuccess: downVoteIsSuccess, isError: downVoteIsError, error: downVoteError }] = useDownVoteTrailMutation();

  const [deleteTrail, { data: deleteTrailData, isLoading: deleteTrailDataIsLoading, isSuccess: deleteTrailDataIsSuccess, isError: deleteTrailDataIsError, error: deleteTrailDataError }] = useDeleteTrailMutation();
  // console.log("viewTrailPosts ::", viewTrailPosts);

  const [favouriteTrail, { data: favouriteTrailData, isLoading: favouriteTrailIsLoading, isSuccess: favouriteTrailIsSuccess, isError: favouriteTrailIsError, error: favouriteTrailError }] = useFavouriteTrailMutation();
  const [unFavouriteTrail, { data: unFavouriteTrailData, isLoading: unFavouriteTrailIsLoading, isSuccess: unFavouriteTrailIsSuccess, isError: unFavouriteTrailIsError, error: unFavouriteTrailError }] = useUnFavouriteTrailMutation();
  useEffect(() => {
    setFavourite(viewTrailPosts?.data?.favorite);
  }, [viewTrailPosts]);

  useEffect(() => {
    if (deleteTrailDataIsSuccess) {
      if (deleteTrailData.status === 200) {
        toast(deleteTrailData.message, {
          ...config.toast_config,
          type: "success",
        });
        navigate("/trail");
      } else {
        toast(deleteTrailData.message, {
          ...config.toast_config,
          type: "error",
        });
      }
    }
  }, [deleteTrailDataIsSuccess]);

  const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {};

  //post tag vote feature ::start
  const [tagArray, setTagArray] = useState([]);
  const updateTagStats = (data) => {
    let { postId, tag_name } = data;
    const tmpTagArray = tagArray.map((tagI) => {
      console.log(tagI, tag_name);
      if (tagI.tag_name === tag_name) {
        return tagI.user_vote ? { ...tagI, count: tagI.count - 1, user_vote: !tagI.user_vote } : { ...tagI, count: tagI.count + 1, user_vote: !tagI.user_vote }; // Using object spread syntax to update the 'count' value
      }
      return tagI;
    });
    setTagArray(tmpTagArray);
  };
  const handleTagVote = (postId) => {
    console.log("postId :: ", postId);
    updateTagStats(postId);
    if (postId.user_vote) {
      dispatch(downVotePost({ trail_id: postId.postId, tag_id: postId.tag, tag_name: postId.tag_name }));
    } else {
      dispatch(upVotePost({ trail_id: postId.postId, tag_id: postId.tag, tag_name: postId.tag_name }));
    }
  };
  useEffect(() => {
    setTagArray(viewTrailPosts?.data?.trailtags);
    return () => { };
  }, [viewTrailPosts]);

  //post tag vote feature ::end
  const tagArrayUpdatedState = useSelector((state) => state.modal.tagArrayUpdated);
  useEffect(() => {
    if (tagArrayUpdatedState) {
      addNewTag();
    }
  }, [tagArrayUpdatedState]);

  const addNewTag = () => {
    // alert(tagArrayUpdatedState);
    const tagList = localStorage.getItem("tagList") ? JSON.parse(localStorage.getItem("tagList")) : undefined;
    const postId = localStorage.getItem("trailId") ? JSON.parse(localStorage.getItem("trailId")) : undefined;
    const tmpTagArray = [...tagArray];

    if (tagList && tagList.length > 0 && viewTrailPosts?.data?.id === postId) {
      tagList.forEach((tag) => {
        tmpTagArray.push({ tag_name: tag.trail_tag, owned: 1, user_vote: 0, count: 0 });
      });
      localStorage.removeItem("tagList");
      localStorage.removeItem("trailId");
    }
    setTagArray(tmpTagArray);
    dispatch(tagArrayUpdated(false));
  };

  const token = localStorage.getItem("token");

  if (isLoading) {
    return <Loader />;
  } else {
    console.log("viewTrailPosts :: ", viewTrailPosts);
    // const trailsData = {
    //   status: 200,
    //   message: "Success.",
    //   data: {
    //     id: 8,
    //     user_id: 1,
    //     trail_name: "Downeast Maine",
    //     trail_type: 1,
    //     trail_description: "",
    //     slug: "downeast-maine",
    //     trailtags: [
    //       {
    //         tag_id: 9,
    //         tag_name: "#jolly",
    //         owned: 0,
    //         count: 0,
    //         user_vote: 0,
    //       },
    //       {
    //         tag_id: 10,
    //         tag_name: "#malai",
    //         owned: 0,
    //         count: 0,
    //         user_vote: 0,
    //       },
    //     ],
    //     user_name: "Thruhike Scout",
    //     profile_image: "https://ik.imagekit.io/h56kvej7o/profile_images/profile_pic.png?tr=w-90,h-90,c-force",
    //     favorite: 0,
    //     trail_headers: [
    //       {
    //         id: 0,
    //         header_name: "",
    //         trail_posts: [
    //           {
    //             id: 718,
    //             user_id: 1,
    //             post_name: "Cutler Coast Hike",
    //             category_id: 8,
    //             address: "ME-191, Cutler, ME 04626, USA",
    //             city: "Cutler",
    //             state: "ME",
    //             country: "United States",
    //             zipcode: "04626",
    //             updates: "",
    //             description:
    //               '"Those seeking a taste of wilderness along Maine\'s coast will enjoy exploring the Cutler Coast Public Lands, a 12,234-acre expanse of blueberry barrens, woodlands and peatlands with 4.5 miles of headlands (interspersed by pocket coves and cobble beaches) overlooking the Bay of Fundy. Hikers can enjoy 10 miles of trails, three remote tent sites and spectacular views from the property\'s steep cliffs-part of the dramatic "Bold Coast" that extends from Cutler to Lubec.." - AllTrails website',
    //             share_link: "https://share.gothruhike.com/ABHhqFb55yb",
    //             slug: "cutler-coast-hike",
    //             favorite: 0,
    //             profile_image: "https://ik.imagekit.io/h56kvej7o/profile_images/profile_pic.png?tr=w-90,h-90,c-force",
    //             tagstats: [
    //               {
    //                 tag_name: "#adventure",
    //                 owned: 0,
    //                 count: 0,
    //                 user_vote: 0,
    //               },
    //               {
    //                 tag_name: "#outdoorsy",
    //                 owned: 0,
    //                 count: 0,
    //                 user_vote: 0,
    //               },
    //               {
    //                 tag_name: "#hike",
    //                 owned: 0,
    //                 count: 0,
    //                 user_vote: 0,
    //               },
    //               {
    //                 tag_name: "#free",
    //                 owned: 0,
    //                 count: 0,
    //                 user_vote: 0,
    //               },
    //             ],
    //             hasVideo: 0,
    //             pivot: {
    //               trail_id: 8,
    //               post_id: 718,
    //             },
    //             category: {
    //               id: 8,
    //               category_name: "Outing",
    //             },
    //             tags: [
    //               {
    //                 id: 8233,
    //                 user_id: 1,
    //                 tag_name: "#adventure",
    //               },
    //               {
    //                 id: 8234,
    //                 user_id: 1,
    //                 tag_name: "#outdoorsy",
    //               },
    //               {
    //                 id: 8235,
    //                 user_id: 1,
    //                 tag_name: "#hike",
    //               },
    //               {
    //                 id: 8236,
    //                 user_id: 1,
    //                 tag_name: "#free",
    //               },
    //             ],
    //             media: [
    //               {
    //                 id: 5775,
    //                 air_table_media_id: "",
    //                 media_name: "https://ik.imagekit.io/h56kvej7o/media_image/media_image_4_1683046923.jpg?tr=w-900,h-600,c-force",
    //                 type: 1,
    //               },
    //               {
    //                 id: 5776,
    //                 air_table_media_id: "",
    //                 media_name: "https://ik.imagekit.io/h56kvej7o/media_image/media_image_5_1683046924.jpg?tr=w-900,h-600,c-force",
    //                 type: 1,
    //               },
    //               {
    //                 id: 5777,
    //                 air_table_media_id: "",
    //                 media_name: "https://ik.imagekit.io/h56kvej7o/media_image/media_image_6_1683046924.jpg?tr=w-900,h-600,c-force",
    //                 type: 1,
    //               },
    //             ],
    //             favorites: "",
    //           },
    //         ],
    //       },
    //       {
    //         id: 1,
    //         header_name: "Top Food in California",
    //         trail_posts: [
    //           {
    //             id: 725,
    //             user_id: 1,
    //             post_name: "Tinder Hearth",
    //             category_id: 2,
    //             address: "1452 Coastal Rd, Brooksville, ME 04617, USA",
    //             city: "Brooksville",
    //             state: "ME",
    //             country: "United States",
    //             zipcode: "04617",
    //             updates: "",
    //             description: '"Tinder Hearth is a wood fired bakery of pastry, bread and pizza.  The owners, Tim and Lydia Semmler, started the bakery in 2007 and they added making pizza a few years after that.  They currently make pizza on Tuesday \u2013 Friday nights (this will change when the weather gets colder) for take out and outdoor dining but reservations are required for either option." - Dining with Frankie',
    //             share_link: "https://share.gothruhike.com/pvlkWKb55yb",
    //             slug: "tinder-hearth",
    //             favorite: 0,
    //             profile_image: "https://ik.imagekit.io/h56kvej7o/profile_images/profile_pic.png?tr=w-90,h-90,c-force",
    //             tagstats: [
    //               {
    //                 tag_name: "#local",
    //                 owned: 0,
    //                 count: 0,
    //                 user_vote: 0,
    //               },
    //               {
    //                 tag_name: "#pizza",
    //                 owned: 0,
    //                 count: 0,
    //                 user_vote: 0,
    //               },
    //               {
    //                 tag_name: "#countryside",
    //                 owned: 0,
    //                 count: 0,
    //                 user_vote: 0,
    //               },
    //               {
    //                 tag_name: "#outofahouse",
    //                 owned: 0,
    //                 count: 0,
    //                 user_vote: 0,
    //               },
    //               {
    //                 tag_name: "#woodfiredpizza",
    //                 owned: 0,
    //                 count: 0,
    //                 user_vote: 0,
    //               },
    //               {
    //                 tag_name: "#takeout",
    //                 owned: 0,
    //                 count: 0,
    //                 user_vote: 0,
    //               },
    //               {
    //                 tag_name: "#outdoorseating",
    //                 owned: 0,
    //                 count: 0,
    //                 user_vote: 0,
    //               },
    //             ],
    //             hasVideo: 0,
    //             pivot: {
    //               trail_id: 8,
    //               post_id: 725,
    //             },
    //             category: {
    //               id: 2,
    //               category_name: "Cafe",
    //             },
    //             tags: [
    //               {
    //                 id: 8212,
    //                 user_id: 1,
    //                 tag_name: "#local",
    //               },
    //               {
    //                 id: 8213,
    //                 user_id: 1,
    //                 tag_name: "#pizza",
    //               },
    //               {
    //                 id: 8214,
    //                 user_id: 1,
    //                 tag_name: "#countryside",
    //               },
    //               {
    //                 id: 8215,
    //                 user_id: 1,
    //                 tag_name: "#outofahouse",
    //               },
    //               {
    //                 id: 8216,
    //                 user_id: 1,
    //                 tag_name: "#woodfiredpizza",
    //               },
    //               {
    //                 id: 8217,
    //                 user_id: 1,
    //                 tag_name: "#takeout",
    //               },
    //               {
    //                 id: 8218,
    //                 user_id: 1,
    //                 tag_name: "#outdoorseating",
    //               },
    //             ],
    //             media: [
    //               {
    //                 id: 5778,
    //                 air_table_media_id: "",
    //                 media_name: "https://ik.imagekit.io/h56kvej7o/media_image/media_image_6_1683046926.jpg?tr=w-900,h-600,c-force",
    //                 type: 1,
    //               },
    //               {
    //                 id: 5779,
    //                 air_table_media_id: "",
    //                 media_name: "https://ik.imagekit.io/h56kvej7o/media_image/media_image_9_1683046927.jpg?tr=w-900,h-600,c-force",
    //                 type: 1,
    //               },
    //               {
    //                 id: 5780,
    //                 air_table_media_id: "",
    //                 media_name: "https://ik.imagekit.io/h56kvej7o/media_image/media_image_8_1683046927.jpg?tr=w-900,h-600,c-force",
    //                 type: 1,
    //               },
    //             ],
    //             favorites: "",
    //           },
    //           {
    //             id: 738,
    //             user_id: 1,
    //             post_name: "Acadia National Park",
    //             category_id: 8,
    //             address: "2 Cottage St, Bar Harbor, ME 04609, USA",
    //             city: "Bar Harbor",
    //             state: "ME",
    //             country: "United States",
    //             zipcode: "04609",
    //             updates: "",
    //             description: '"Acadia National Park is a 47,000-acre Atlantic coast recreation area primarily on Maine\'s Mount Desert Island. Its landscape is marked by woodland, rocky beaches and glacier-scoured granite peaks such as Cadillac Mountain, the highest point on the United States\u2019 East Coast. Among the wildlife are moose, bear, whales and seabirds. The bayside town of Bar Harbor, with restaurants and shops, is a popular gateway." - Google website',
    //             share_link: "https://share.gothruhike.com/KioXEXe55yb",
    //             slug: "acadia-national-park",
    //             favorite: 0,
    //             profile_image: "https://ik.imagekit.io/h56kvej7o/profile_images/profile_pic.png?tr=w-90,h-90,c-force",
    //             tagstats: [
    //               {
    //                 tag_name: "#free",
    //                 owned: 0,
    //                 count: 0,
    //                 user_vote: 0,
    //               },
    //               {
    //                 tag_name: "#destination",
    //                 owned: 0,
    //                 count: 0,
    //                 user_vote: 0,
    //               },
    //               {
    //                 tag_name: "#atlantic",
    //                 owned: 0,
    //                 count: 0,
    //                 user_vote: 0,
    //               },
    //               {
    //                 tag_name: "#national park",
    //                 owned: 0,
    //                 count: 0,
    //                 user_vote: 0,
    //               },
    //               {
    //                 tag_name: "#free things to do",
    //                 owned: 0,
    //                 count: 0,
    //                 user_vote: 0,
    //               },
    //             ],
    //             hasVideo: 0,
    //             pivot: {
    //               trail_id: 8,
    //               post_id: 738,
    //             },
    //             category: {
    //               id: 8,
    //               category_name: "Outing",
    //             },
    //             tags: [
    //               {
    //                 id: 12446,
    //                 user_id: 1,
    //                 tag_name: "#free",
    //               },
    //               {
    //                 id: 12447,
    //                 user_id: 1,
    //                 tag_name: "#destination",
    //               },
    //               {
    //                 id: 12448,
    //                 user_id: 1,
    //                 tag_name: "#atlantic",
    //               },
    //               {
    //                 id: 12449,
    //                 user_id: 1,
    //                 tag_name: "#national park",
    //               },
    //               {
    //                 id: 12450,
    //                 user_id: 1,
    //                 tag_name: "#free things to do",
    //               },
    //             ],
    //             media: [
    //               {
    //                 id: 7815,
    //                 air_table_media_id: "",
    //                 media_name: "https://ik.imagekit.io/h56kvej7o/media_image/media_image_8_1686965523.jpg?tr=w-900,h-600,c-force",
    //                 type: 1,
    //               },
    //               {
    //                 id: 7816,
    //                 air_table_media_id: "",
    //                 media_name: "https://ik.imagekit.io/h56kvej7o/media_image/media_image_9_1686965524.jpg?tr=w-900,h-600,c-force",
    //                 type: 1,
    //               },
    //               {
    //                 id: 7817,
    //                 air_table_media_id: "",
    //                 media_name: "https://ik.imagekit.io/h56kvej7o/media_image/media_image_4_1686965525.jpg?tr=w-900,h-600,c-force",
    //                 type: 1,
    //               },
    //             ],
    //             favorites: "",
    //           },
    //         ],
    //       },
    //       {
    //         id: 2,
    //         header_name: "New york’s Best Resturant",
    //         trail_posts: [
    //           {
    //             id: 706,
    //             user_id: 1,
    //             post_name: "Aragosta at Goose Cove",
    //             category_id: 1,
    //             address: "Goose Cove Rd, Deer Isle, ME 04627, USA",
    //             city: "Deer Isle",
    //             state: "ME",
    //             country: "United States",
    //             zipcode: "04627",
    //             updates: "",
    //             description:
    //               '"Aragosta at Goose Cove sits among one of Maine\u2019s most spectacular and pristine oceanfront locations. Our fine dining menu reflects the seasonal flavors of the coastal region and the creative culinary palate of Chef Finigan.\r\n\r\nUtilizing the highest quality ingredients, our menu changes seasonally and adjusts to the availability of local produce. By working directly with local fishermen and farmers, Aragosta is able to offer an ever-changing, fresh menu." - Aragosta website',
    //             share_link: "https://share.gothruhike.com/qYSl2f6y4yb",
    //             slug: "aragosta-at-goose-cove",
    //             favorite: 1,
    //             profile_image: "https://ik.imagekit.io/h56kvej7o/profile_images/profile_pic.png?tr=w-90,h-90,c-force",
    //             tagstats: [
    //               {
    //                 tag_name: "#woman-owned",
    //                 owned: 0,
    //                 count: 2,
    //                 user_vote: 1,
    //               },
    //               {
    //                 tag_name: "#destination",
    //                 owned: 0,
    //                 count: 0,
    //                 user_vote: 0,
    //               },
    //               {
    //                 tag_name: "#local",
    //                 owned: 0,
    //                 count: 0,
    //                 user_vote: 0,
    //               },
    //               {
    //                 tag_name: "#waterfront",
    //                 owned: 0,
    //                 count: 0,
    //                 user_vote: 0,
    //               },
    //               {
    //                 tag_name: "#cottagecore",
    //                 owned: 0,
    //                 count: 0,
    //                 user_vote: 0,
    //               },
    //               {
    //                 tag_name: "#oceanside dining",
    //                 owned: 0,
    //                 count: 0,
    //                 user_vote: 0,
    //               },
    //             ],
    //             hasVideo: 0,
    //             pivot: {
    //               trail_id: 8,
    //               post_id: 706,
    //             },
    //             category: {
    //               id: 1,
    //               category_name: "Restaurant",
    //             },
    //             tags: [
    //               {
    //                 id: 16263,
    //                 user_id: 1,
    //                 tag_name: "#destination",
    //               },
    //               {
    //                 id: 16264,
    //                 user_id: 1,
    //                 tag_name: "#local",
    //               },
    //               {
    //                 id: 16265,
    //                 user_id: 1,
    //                 tag_name: "#woman-owned",
    //               },
    //               {
    //                 id: 16266,
    //                 user_id: 1,
    //                 tag_name: "#waterfront",
    //               },
    //               {
    //                 id: 16267,
    //                 user_id: 1,
    //                 tag_name: "#cottagecore",
    //               },
    //               {
    //                 id: 16268,
    //                 user_id: 1,
    //                 tag_name: "#oceanside dining",
    //               },
    //               {
    //                 id: 16269,
    //                 user_id: 45,
    //                 tag_name: "#cottage",
    //               },
    //               {
    //                 id: 16270,
    //                 user_id: 45,
    //                 tag_name: "#dine-in",
    //               },
    //             ],
    //             media: [
    //               {
    //                 id: 9266,
    //                 air_table_media_id: "",
    //                 media_name: "https://ik.imagekit.io/h56kvej7o/media_image/media_image_6_1689823082.jpg?tr=w-900,h-600,c-force",
    //                 type: 1,
    //               },
    //               {
    //                 id: 9267,
    //                 air_table_media_id: "",
    //                 media_name: "https://ik.imagekit.io/h56kvej7o/media_image/media_image_9_1689823083.jpg?tr=w-900,h-600,c-force",
    //                 type: 1,
    //               },
    //               {
    //                 id: 9268,
    //                 air_table_media_id: "",
    //                 media_name: "https://ik.imagekit.io/h56kvej7o/media_image/media_image_5_1689823085.jpg?tr=w-900,h-600,c-force",
    //                 type: 1,
    //               },
    //             ],
    //             favorites: {
    //               id: 330,
    //               user_id: 2,
    //               post_id: 706,
    //             },
    //           },
    //         ],
    //       },
    //     ],
    //     tags: [
    //       {
    //         id: 9,
    //         trail_id: 8,
    //         user_id: 45,
    //         tag_name: "#jolly",
    //         user_tag_vote: [],
    //       },
    //       {
    //         id: 10,
    //         trail_id: 8,
    //         user_id: 45,
    //         tag_name: "#malai",
    //         user_tag_vote: [],
    //       },
    //     ],
    //   },
    // };
    const trailsData = viewTrailPosts?.header || [];
    return (
      <>
        <div className="main_content">
          <div className="container">
            <div className="trail_list_view">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="trail_top_header d-flex align-items-start">
                  <img src={viewTrailPosts?.data?.profile_image} alt="thruhike-trail-image" title={viewTrailPosts?.data?.trail_name} className="left_img" />
                  <div className="trail_list_inner">
                    <div className="d-flex align-center justify-content-between">
                      <h4 className="heading_title d-flex align-center justify-content-between w-100">
                        <div>
                          {viewTrailPosts?.data?.trail_name} <span> By {viewTrailPosts?.data?.user_name}</span>
                        </div>
                        <div className="post_detail d-flex">
                          <Link
                            to="#"
                            onClick={() =>
                              token && token !== undefined && token !== ""
                                ? dispatch(
                                  addTagModal({
                                    modal: true,
                                    postId: viewTrailPosts?.data?.id,
                                    type: "trail",
                                  })
                                )
                                : dispatch(logInModal(true))
                            }
                            style={{ marginLeft: "4px" }}
                          >
                            <span className="badge">Add your #</span>
                          </Link>
                          {viewTrailPosts?.data?.user_id === user?.id && id != "" ? (
                            <>
                              <Link
                                to="#"
                                className="backarrow rename"
                                onClick={() => {
                                  localStorage.setItem("editTrail", JSON.stringify(viewTrailPosts?.data));
                                  dispatch(addTrailModal(true));
                                }}
                                style={{ verticalAlign: "middle", marginRight: "4px" }}
                              >
                                <span style={{ verticalAlign: "text-bottom", marginRight: "4px" }}>
                                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.71667 5.5L10.5 6.28333L2.93333 13.8333H2.16667V13.0667L9.71667 5.5ZM12.7167 0.5C12.5083 0.5 12.2917 0.583333 12.1333 0.741667L10.6083 2.26667L13.7333 5.39167L15.2583 3.86667C15.5833 3.54167 15.5833 3 15.2583 2.69167L13.3083 0.741667C13.1417 0.575 12.9333 0.5 12.7167 0.5ZM9.71667 3.15833L0.5 12.375V15.5H3.625L12.8417 6.28333L9.71667 3.15833Z" fill="#008B2A"></path>
                                  </svg>
                                </span>
                                {/* Rename */}
                                <span>Edit</span>
                              </Link>
                              <Link
                                to="#"
                                className="backarrow delete "
                                onClick={() => {
                                  if (window.confirm("Are you sure you want to delete this place?")) {
                                    deleteTrail(viewTrailPosts?.data?.id);
                                  } else {
                                    // do not delete the item
                                  }
                                }}
                                style={{ verticalAlign: "middle", marginRight: "4px" }}
                              >
                                <span style={{ verticalAlign: "text-bottom", marginRight: "4px" }}>
                                  <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.49967 0.5V1.33333H0.333008V3H1.16634V13.8333C1.16634 14.2754 1.34194 14.6993 1.6545 15.0118C1.96706 15.3244 2.39098 15.5 2.83301 15.5H11.1663C11.6084 15.5 12.0323 15.3244 12.3449 15.0118C12.6574 14.6993 12.833 14.2754 12.833 13.8333V3H13.6663V1.33333H9.49967V0.5H4.49967ZM2.83301 3H11.1663V13.8333H2.83301V3ZM4.49967 4.66667V12.1667H6.16634V4.66667H4.49967ZM7.83301 4.66667V12.1667H9.49967V4.66667H7.83301Z" fill="#DD1010"></path>
                                  </svg>
                                </span>
                                <span>Delete</span>
                              </Link>
                            </>
                          ) : (
                            <div className="trail_tag_like">
                              <Link
                                className="social_icon"
                                onClick={() => {
                                  if (favourite) {
                                    unFavouriteTrail({
                                      trail_id: viewTrailPosts?.data?.id,
                                    });
                                    setFavourite(false);
                                  } else {
                                    favouriteTrail({
                                      trail_id: viewTrailPosts?.data?.id,
                                    });
                                    setFavourite(true);
                                  }
                                }}
                              >
                                {favourite ? (
                                  <svg width="22" height="22" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.00008 15.7917L7.79175 14.6917C3.50008 10.8 0.666748 8.225 0.666748 5.08333C0.666748 2.50833 2.68341 0.5 5.25008 0.5C6.70008 0.5 8.09175 1.175 9.00008 2.23333C9.90841 1.175 11.3001 0.5 12.7501 0.5C15.3167 0.5 17.3334 2.50833 17.3334 5.08333C17.3334 8.225 14.5001 10.8 10.2084 14.6917L9.00008 15.7917Z" fill="#FE4954" />
                                  </svg>
                                ) : (
                                  <svg width="22" height="22" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      d="M10.0834 15.4583L10.0001 15.5417L9.90841 15.4583C5.95008 11.8667 3.33341 9.49167 3.33341 7.08333C3.33341 5.41667 4.58341 4.16667 6.25008 4.16667C7.53341 4.16667 8.78341 5 9.22508 6.13333H10.7751C11.2167 5 12.4667 4.16667 13.7501 4.16667C15.4167 4.16667 16.6667 5.41667 16.6667 7.08333C16.6667 9.49167 14.0501 11.8667 10.0834 15.4583ZM13.7501 2.5C12.3001 2.5 10.9084 3.175 10.0001 4.23333C9.09175 3.175 7.70008 2.5 6.25008 2.5C3.68341 2.5 1.66675 4.50833 1.66675 7.08333C1.66675 10.225 4.50008 12.8 8.79175 16.6917L10.0001 17.7917L11.2084 16.6917C15.5001 12.8 18.3334 10.225 18.3334 7.08333C18.3334 4.50833 16.3167 2.5 13.7501 2.5Z"
                                      fill="#66737F"
                                    />
                                  </svg>
                                )}
                                <span className="favriout_hover">
                                  Added in your Favorite Trail <i className="fas fa-heart"></i>
                                </span>
                              </Link>
                            </div>
                          )}
                        </div>
                      </h4>
                    </div>
                    <p>{viewTrailPosts?.data?.trail_description && viewTrailPosts?.data?.trail_description.length > 400 && !readMore ? viewTrailPosts?.data?.trail_description.substring(0, 300) : viewTrailPosts?.data?.trail_description}</p>
                    {viewTrailPosts?.data?.trail_description && viewTrailPosts?.data?.trail_description.length > 400 ? (
                      <div className="d-flex justify-content-end heading_title">
                        <Link to="#" onClick={() => setReadMore(!readMore)} style={{ color: "#008b2a" }}>
                          {readMore ? "Read less" : "Read More"}
                        </Link>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="d-flex align-center justify-content-between book_list_div">
                      <div className="tag_list">
                        {tagArray && tagArray.length ? (
                          tagArray.map((tag, index) => (
                            <Link
                              to="#"
                              className="active"
                              key={"post-details-tag" + tag.id + index}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                // handleModalOpens(5, { postId: post?.id, tag: tag.tag_id, user_vote: tag.user_vote });
                                handleTagVote({ postId: viewTrailPosts?.data?.id, tag_name: tag.tag_name, tag: tag.tag_id, user_vote: tag?.user_vote });
                              }}
                            >
                              <span className="mx-1">{tag.tag_name}</span>
                              <>
                                <span className="me-1">
                                  {tag.user_vote ? (
                                    <svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ verticalAlign: "sub" }}>
                                      <g clipPath="url(#clip0_1463_816)">
                                        <g clipPath="url(#clip1_1463_816)">
                                          <path d="M5.21875 17.2287L1 17.2287L0.999999 6.09398L5.21875 6.09398L5.21875 17.2287Z" fill="#008B2A" />
                                          <path
                                            d="M18.2587 9.67634C18.4832 9.9751 18.6165 10.3462 18.6165 10.7478C18.6165 11.3429 18.3238 11.8707 17.8751 12.1956C18.0997 12.4944 18.233 12.8654 18.233 13.2671C18.233 13.8622 17.9407 14.3902 17.492 14.7152C17.7164 15.0139 17.8495 15.3849 17.8495 15.7864C17.8495 16.7717 17.0478 17.5733 16.0625 17.5733L10.4369 17.5733C9.51024 17.5733 8.60054 17.4084 7.73305 17.0831L6.27344 16.5357L6.27344 7.30755C7.98393 5.46701 9.54951 3.71026 9.83146 3.25899L9.83146 1.1848C9.83146 0.766761 10.1715 0.42666 10.5896 0.42666C12.3136 0.42666 13.7162 1.82929 13.7162 3.55332L13.7162 4.48893L13.1509 6.44161L17.213 6.44161C18.1983 6.44161 19 7.24325 19 8.22857C19 8.82366 18.7073 9.35139 18.2587 9.67634Z"
                                            fill="#008B2A"
                                          />
                                        </g>
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_1463_816">
                                          <rect width="20" height="20" fill="white" />
                                        </clipPath>
                                        <clipPath id="clip1_1463_816">
                                          <rect width="18" height="18" fill="white" transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 1 18)" />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  ) : (
                                    <svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ verticalAlign: "sub" }}>
                                      <g clipPath="url(#clip0_1463_824)">
                                        <g clipPath="url(#clip1_1463_824)">
                                          <path
                                            d="M18.2587 9.67634C18.4832 9.9751 18.6165 10.3462 18.6165 10.7478C18.6165 11.3429 18.3238 11.8707 17.8751 12.1956C18.0997 12.4944 18.233 12.8654 18.233 13.2671C18.233 13.8622 17.9406 14.3902 17.492 14.7152C17.7164 15.0139 17.8494 15.3849 17.8494 15.7864C17.8494 16.7717 17.0478 17.5733 16.0624 17.5733L15.2709 17.5733L10.4369 17.5733C9.51031 17.5733 8.60057 17.4084 7.73306 17.0831L5.97394 16.4234L5.97394 17.2286L1 17.2286L0.999999 6.09395L5.97398 6.09395L5.97398 7.62902C7.79764 5.67559 9.53312 3.73645 9.83146 3.25899L9.83146 1.18477C9.83146 0.766727 10.1715 0.42666 10.5896 0.42666C12.3136 0.42666 13.7162 1.82925 13.7162 3.55332L13.7162 4.48893L13.1509 6.44161L17.213 6.44161C18.1983 6.44161 19 7.24325 19 8.22857C19 8.82362 18.7073 9.35136 18.2587 9.67634ZM4.91929 7.14868L2.05469 7.14868L2.05469 16.174L4.91929 16.174L4.91929 7.14868ZM17.213 7.4963L11.7475 7.4963L12.6615 4.3393L12.6615 3.55332C12.6615 2.51153 11.8886 1.64686 10.8861 1.50251L10.8861 3.36586C10.8861 3.62127 10.8861 3.823 8.29253 6.66981C7.31835 7.73909 6.33468 8.78668 5.97398 9.16953L5.97398 15.297L8.10339 16.0955C8.85211 16.3763 9.63719 16.5187 10.4369 16.5187L15.2709 16.5187L16.0625 16.5187C16.4663 16.5187 16.7948 16.1901 16.7948 15.7864C16.7948 15.3826 16.4663 15.0541 16.0625 15.0541L16.0625 13.9994L16.446 13.9994C16.8498 13.9994 17.1783 13.6709 17.1783 13.2671C17.1783 12.8633 16.8498 12.5348 16.446 12.5348L16.446 11.4801C16.4694 11.4801 16.4925 11.481 16.5156 11.4819L16.5156 11.4801L16.8295 11.4801C17.2333 11.4801 17.5618 11.1516 17.5618 10.7478C17.5618 10.3441 17.2333 10.0156 16.8295 10.0156L16.7638 10.0156L16.7638 8.96088L17.213 8.96088C17.6168 8.96088 17.9453 8.63234 17.9453 8.22857C17.9453 7.82477 17.6168 7.4963 17.213 7.4963Z"
                                            fill="#374149"
                                          />
                                        </g>
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_1463_824">
                                          <rect width="20" height="20" fill="white" />
                                        </clipPath>
                                        <clipPath id="clip1_1463_824">
                                          <rect width="18" height="18" fill="white" transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 1 18)" />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  )}
                                </span>
                                <span>{tag.count > 0 ? `(${tag.count})` : ""}</span>
                              </>
                            </Link>
                          ))
                        ) : (
                          <NoFoundComponent message="" />
                        )}
                      </div>
                      {/* <a href="#" className="btn_save">
                        Book this Trail List
                      </a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {viewTrailPosts?.data?.user_id === user?.id && id != "" ? (
              <div className="button_create">
                <Link to="#" onClick={(e) => dispatch(trailHeaderModal({ trailHeaderModal: true, trailId: id }))} className="btn_save btn_blue">
                  <i className="fas fa-plus"></i> Create Heading
                </Link>
                {/* <Link to="/addplace" className="btn_save btn_outline">
                  <i className="fas fa-plus"></i> Add Place/Product
                </Link> */}
              </div>
            ) : (
              ""
            )}
            {/* <!-- start trail post --> */}
            <div className="row your_favriout inner_trail_list">{viewTrailPosts?.data?.header && viewTrailPosts?.data?.header?.length ? <DraggableItem postHeaders={viewTrailPosts?.data?.header} trailSlug={viewTrailPosts?.data?.slug} trailId={viewTrailPosts?.data?.id} userHasAccess={viewTrailPosts?.data?.user_id === user?.id} /> : ""}</div>
            {/* <!-- end trail post -->		 */}
          </div>

          {/* <!-- add all Modal here --> */}
          <AddHeader />
          <DeleteHeader />
        </div>
      </>
    );
  }
};
