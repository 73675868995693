import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  useGetMyTrailsQuery,
  useGetViewTrailGroupQuery,
} from "../API/trailAPI";
import { Loader } from "./Loader";
import { TrailListCard } from "./TrailListCard";
export const TrailRow = ({ location }) => {
  const searchText = useSelector((state) => state.modal.searchText);
  const [trails, setTrails] = useState([]);
  const {
    data: viewTrails,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetMyTrailsQuery({ searchKey: searchText, location: location });

  useEffect(() => {
    if (
      isSuccess &&
      viewTrails &&
      viewTrails.data &&
      viewTrails.data.length >= 4
    ) {
      setTrails(viewTrails.data.slice(0, 4));
    }
  }, [isSuccess]);

  if (isLoading) {
    return <Loader />;
  } else if (trails.length > 0) {
    return (
      <div className="row">
        <TrailListCard
          data={trails}
          classList="col-lg-3 col-md-3 col-sm-6 col-6"
          displayHeart={true}
          link={true}
        />
      </div>

      // <div className="row">
      //   <div className="col-lg-3 col-md-3 col-sm-6 col-6">
      //     <Link to="#">
      //       <div className="hz_traillist">
      //         <div className="trail_mainimg">
      //           <img
      //             src={require("../assets/images/trail_small.png")}
      //             alt=""
      //             title=""
      //           />
      //         </div>
      //         <h5>Top of the World</h5>
      //       </div>
      //     </Link>
      //   </div>
      //   <div className="col-lg-3 col-md-3 col-sm-6 col-6">
      //     <Link to="#">
      //       <div className="hz_traillist">
      //         <div className="trail_mainimg">
      //           <div className="multiple_img">
      //             <img
      //               src={require("../assets/images/img1.png")}
      //               alt=""
      //               title=""
      //             />
      //           </div>
      //           <div className="multiple_img">
      //             <img
      //               src={require("../assets/images/img2.png")}
      //               alt=""
      //               title=""
      //             />
      //           </div>
      //           <div className="multiple_img">
      //             <img
      //               src={require("../assets/images/img3.png")}
      //               alt=""
      //               title=""
      //             />
      //           </div>
      //           <div className="multiple_img">
      //             <img
      //               src={require("../assets/images/img4.png")}
      //               alt=""
      //               title=""
      //             />
      //           </div>
      //         </div>
      //         <h5>Alaska Best</h5>
      //       </div>
      //     </Link>
      //   </div>
      //   <div className="col-lg-3 col-md-3 col-sm-6 col-6">
      //     <Link to="#">
      //       <div className="hz_traillist">
      //         <div className="trail_mainimg">
      //           <div className="multiple_img">
      //             <img
      //               src={require("../assets/images/img5.png")}
      //               alt=""
      //               title=""
      //             />
      //           </div>
      //           <div className="multiple_img">
      //             <img
      //               src={require("../assets/images/img6.png")}
      //               alt=""
      //               title=""
      //             />
      //           </div>
      //           <div className="multiple_img">
      //             <img
      //               src={require("../assets/images/img7.png")}
      //               alt=""
      //               title=""
      //             />
      //           </div>
      //           <div className="multiple_img">
      //             <img
      //               src={require("../assets/images/img8.png")}
      //               alt=""
      //               title=""
      //             />
      //           </div>
      //         </div>
      //         <h5>India Best Location</h5>
      //       </div>
      //     </Link>
      //   </div>
      //   <div className="col-lg-3 col-md-3 col-sm-6 col-6">
      //     <Link to="#">
      //       <div className="hz_traillist">
      //         <div className="trail_mainimg">
      //           <div className="multiple_img">
      //             <img
      //               src={require("../assets/images/img1.png")}
      //               alt=""
      //               title=""
      //             />
      //           </div>
      //           <div className="multiple_img">
      //             <img
      //               src={require("../assets/images/img2.png")}
      //               alt=""
      //               title=""
      //             />
      //           </div>
      //           <div className="multiple_img">
      //             <img
      //               src={require("../assets/images/img3.png")}
      //               alt=""
      //               title=""
      //             />
      //           </div>
      //           <div className="multiple_img">
      //             <img
      //               src={require("../assets/images/img4.png")}
      //               alt=""
      //               title=""
      //             />
      //           </div>
      //         </div>
      //         <h5>California</h5>
      //       </div>
      //     </Link>
      //   </div>
      //   <hr className="horizontal_line" />
      // </div>
    );
  } else {
    return;
  }
};
