import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Modal } from "reactstrap";
import { shareModal } from "../../redux/modalSlice";
import NoFoundComponent from "../NoFoundComponent";

export const Share = ({ postLink }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [inputText, setInputText] = useState("");
  const [copySuccess, setCopySuccess] = useState("");

  const shareLink = useSelector((state) => state.modal.shareLink);
  const copyToClipboard = async (e, url) => {
    navigator.permissions
      .query({ name: "clipboard-write" })
      .then((result) => {
        if (result.state === "granted" || result.state === "prompt") {
          // Clipboard access allowed or prompt, proceed with copying text
          navigator.clipboard
            .writeText(shareLink)
            .then(() => {
              setCopySuccess("Copied to clipboard!");
              if (url !== "") {
                window.open(url, "_blank", "noopener,noreferrer");
              }
            })
            .catch((error) => {
              setCopySuccess("Failed to copy to clipboard");
              console.error("Error copying text to clipboard:", error);
            });
        } else {
          console.error("Clipboard write permission denied.");
        }
      })
      .catch((error) => {
        console.error("Error checking clipboard permissions:", error);
      });
  };

  const shareModalState = useSelector((state) => state.modal.shareModal);
  const postIdState = useSelector((state) => state.modal.postId);

  return (
    <Modal isOpen={shareModalState} centered={true} contentClassName="login_form">
      <div className="modal-header">
        <h1 className="modal-title" id="exampleModalLabel">
          Share
        </h1>
        <button
          type="button"
          className="btn-close"
          //data-bs-dismiss="modal"
          aria-label="Close"
          onClick={() => {
            setCopySuccess([]);
            dispatch(shareModal({ modal: false, postId: undefined, shareLink: "" }));
          }}
        >
          <i className="fas fa-times"></i>
        </button>
      </div>
      <div className="modal-body">
        <div className="d-flex justify-content-between">
          {/* target="_blank" to="//www.facebook.com" */}
          <div className="rounded-circle" onClick={(e) => copyToClipboard(e, "//www.facebook.com")}>
            <img src={require("../../assets/images/facebook.png")} alt="" title="" />
          </div>
          {/* target="_blank" to="//www.instagram.com" */}
          <div className="rounded-circle" onClick={(e) => copyToClipboard(e, "//www.instagram.com")}>
            <img src={require("../../assets/images/instagram.png")} alt="" title="" />
          </div>
          {/* target="_blank" to="//twitter.com/" */}
          <div className="rounded-circle" onClick={(e) => copyToClipboard(e, "//twitter.com/")}>
            <img src={require("../../assets/images/tweeter.png")} alt="" title="" />
          </div>
          {/* target="_blank" to="//web.whatsapp.com/" */}
          <div className="rounded-circle" onClick={(e) => copyToClipboard(e, "//web.whatsapp.com/")}>
            <img src={require("../../assets/images/whatsapp.png")} alt="" title="" />
          </div>
          <div
            to="#"
            className="rounded-circle"
            onClick={(e) => {
              copyToClipboard(e, "mailto:");
            }}
          >
            <img src={require("../../assets/images/gmail.png")} alt="" title="" />
          </div>
          <div className="rounded-circle" onClick={(e) => copyToClipboard(e, "https://linkedin.com/")}>
            <img src={require("../../assets/images/linkedin.png")} alt="" title="" />
          </div>
        </div>
        <form className="form_field">
          <div className="input-group mt-3">
            <input type="text" className="form-control" value={shareLink} aria-describedby="sharelink" readOnly />
            <button className="btn btn_copy" type="button" id="sharelink" onClick={(e) => copyToClipboard(e, "")}>
              Copy
            </button>
          </div>
          <div>{copySuccess && <p>{copySuccess}</p>}</div>
        </form>
      </div>
    </Modal>
  );
};
