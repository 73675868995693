import React from "react";
import { Helmet } from "react-helmet";

export const MetaData = ({ pageTitle, metaTitle, metaDescription, metaKeywords }) => {
  return (
    <div>
      <Helmet>
        {console.log("metaTitle :: ", metaTitle)}
        <title>{metaTitle !== "" ? metaTitle : pageTitle}</title>
        <meta name="title" content={metaTitle} />
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={metaKeywords} />
        {/* <!-- Open Graph / Facebook --> */}
        {/* <meta property="og:type" content="website" /> */}
        <meta property="og:url" content="https://www.gothruhike.com/" />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        {/* <meta property="og:image" content="https://metatags.io/images/meta-tags.png" /> */}

        {/* <!-- Twitter --> */}
        {/* <meta property="twitter:card" content="summary_large_image" /> */}
        <meta property="twitter:url" content="https://www.gothruhike.com/" />
        <meta property="twitter:title" content={metaTitle} />
        <meta property="twitter:description" content={metaDescription} />
        {/* <meta property="twitter:image" content="https://metatags.io/images/meta-tags.png" /> */}

      </Helmet>

      {/* Your component content */}
    </div>
  );
};
