import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Modal } from "reactstrap";
import { Link } from "react-router-dom";
import { useAddCustomTrailMutation } from "../../API/trailAPI";
import { addCustomTrailModal, addCustomTrailThanksModal } from "../../redux/modalSlice";
import * as Yup from "yup";
import { Formik } from "formik";
import { config } from "../../config";
import { toast } from "react-toastify";

export const AddCustomTrail = () => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();

  // const [custom_email, setCustom_email] = useState("");

  const user = JSON.parse(localStorage.getItem("user"));

  const [addCustomTrail, { data, isLoading, error, isError, isSuccess }] = useAddCustomTrailMutation();

  const addCustomTrailModalState = useSelector((state) => state.modal.addCustomTrailModal);

  const closeModal = () => {
    dispatch(addCustomTrailModal(false));
    // setCustom_email("");
  };

  const onSubmit = (values) => {
    addCustomTrail(values);
  };

  // useEffect(() => {
  //   setCustom_email(user?.email);
  // }, [addCustomTrailModalState]);

  useEffect(() => {
    if (isSuccess && data.status === 200) {
      closeModal();
      // toast(data.message, {
      //   ...config.toast_config,
      //   type: "success",
      // });
      dispatch(addCustomTrailThanksModal(true));
    } else if (isSuccess && data.status === 422) {
      toast(data.message, {
        ...config.toast_config,
        type: "error",
      });
    }
  }, [isSuccess]);

  const AddCustomTrailSchema = Yup.object().shape({
    custom_email: Yup.string().email("Please enter valid email").required("Email is required."),
    custom_about: Yup.string().required("About is required."),
    custom_inspiration: Yup.string(),
  });

  return (
    <Modal isOpen={addCustomTrailModalState} centered={true} contentClassName="login_form" toggle={closeModal}>
      <Formik
        initialValues={{
          custom_email: JSON.parse(localStorage.getItem("user"))?.email,
          custom_about: "",
          custom_inspiration: "",
        }}
        validationSchema={AddCustomTrailSchema}
        onSubmit={(values) => {
          onSubmit(values);
        }}
      >
        {({ errors, touched, handleSubmit, values, handleChange, isInitialValid, isValid, initialValues }) => (
          <div>
            <div className="modal-header">
              <h1 className="modal-title" id="exampleModalLabel">
                Custom Trail List
              </h1>
              <button type="button" className="btn-close" aria-label="Close" onClick={() => closeModal()}>
                <i className="fas fa-times"></i>
              </button>
            </div>
            <div className="modal-body">
              <p>
                Going on a trip? We can create a custom trail list for you for $50-$100/list dependent on request. Also, check out our
                <Link className="link-popup" to="/subscriptions" onClick={() => dispatch(addCustomTrailModal(false))}>
                  consumer subscriptions.
                </Link>
              </p>
            </div>

            <div className="modal-body">
              <form className="form_field">
                <div>
                  <label className="mb-1">Your Email Address</label>
                  <input type="text" className="form-control" placeholder="Email Address" id="custom_email" onChange={handleChange} value={values.custom_email} />
                  {errors.custom_email && touched.custom_email && <p className="text-danger">{errors.custom_email}</p>}
                </div>
                <div className="mt-3">
                  <label className="mb-1">Tell us more about the custom trail list you'd like</label>
                  <textarea className="form-control" rows="4"
                    placeholder="i.e. 'I'm traveling to Spain and would like a full trip itinerary with restaurants and activities' or 'I am looking for new sneakers for trail running and would like a list of options that match my profile'"
                    name="custom_about" onChange={handleChange} value={values.custom_about}></textarea>
                  {errors.custom_about && touched.custom_about && <p className="text-danger">{errors.custom_about}</p>}
                </div>
                <div className="mt-3">
                  <label className="mb-1">Is there anything else we should know?</label>
                  <textarea className="form-control" id="custom_inspiration" rows="4" placeholder="i.e. a specific vibe you'd like for the trail list or any initial places/products you want to provide as inspiration" name="custom_inspiration" onChange={handleChange}></textarea>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary save" onClick={() => handleSubmit(values)} disabled={!isValid}>
                Submit
              </button>
            </div>
          </div>
        )}
      </Formik>
    </Modal>
  );
};
// export const AddCustomTrail = () => {
//   const dispatch = useDispatch();
//   // const navigate = useNavigate();

//   const [formData, setFormData] = useState({ custom_email: "", custom_about: "", custom_inspiration: "" });
//   const [emailError, setEmailError] = useState("");
//   const [customAboutError, setCustomAboutError] = useState("");
//   const [customInspirationError, setCustomInspirationError] = useState("");

//   const user = JSON.parse(localStorage.getItem("user"));

//   const [addCustomTrail, { data, isLoading, error, isError, isSuccess }] = useAddCustomTrailMutation();

//   const addCustomTrailModalState = useSelector((state) => state.modal.addCustomTrailModal);

//   const closeModal = () => {
//     dispatch(addCustomTrailModal(false));
//     setEmailError("");
//     setCustomAboutError("");
//     setCustomInspirationError("");
//   };

//   const ValidateForm = (formData) => {
//     if (formData && formData.custom_email === "") {
//       setEmailError("email is required.");
//     } else if (formData && formData.custom_email === "" && !formData.custom_email.match(config.emailRegex)) {
//       setEmailError("enter valid email");
//     } else {
//       setEmailError("");
//     }

//     //custom_about validation
//     if (formData && formData.custom_about === "") setCustomAboutError("about details is required");
//     else setCustomAboutError("");

//     //custom_inspiration validation
//     if (formData && formData.custom_inspiration === "") setCustomInspirationError("inspiration details is required.");
//     else setCustomInspirationError("");
//   };

//   const inputHandler = (event, fieldName) => {
//     formData[fieldName] = event.target.value;
//     setFormData(formData);

//     ValidateForm(formData);
//   };

//   const onSubmit = () => {
//     ValidateForm(formData);
//     if (emailError === "" && customAboutError === "" && customInspirationError === "") {
//       addCustomTrail(formData);
//     }
//   };

//   useEffect(() => {
//     setFormData({ ...formData, custom_email: user?.email });
//   }, [addCustomTrailModalState]);

//   useEffect(() => {
//     if (isSuccess && data.status === 200) {
//       closeModal();
//       // toast(data.message, {
//       //   ...config.toast_config,
//       //   type: "success",
//       // });
//       dispatch(addCustomTrailThanksModal(true));
//     } else if (isSuccess && data.status === 422) {
//       toast(data.message, {
//         ...config.toast_config,
//         type: "error",
//       });
//     }
//   }, [isSuccess]);

//   // console.log("email ::", formData && formData.custom_email);

//   return (
//     <Modal isOpen={addCustomTrailModalState} centered={true} contentClassName="login_form" toggle={closeModal}>
//       <div className="modal-header">
//         <h1 className="modal-title" id="exampleModalLabel">
//           Custom Trail List
//         </h1>
//         <button type="button" className="btn-close" aria-label="Close" onClick={() => closeModal()}>
//           <i className="fas fa-times"></i>
//         </button>
//       </div>
//       <div className="modal-body">
//         <p>
//           Going on a trip? We can create a custom trail list for you for $50-$100/list dependent on request. Also, check out our
//           <Link className="link-popup" to="/subscriptions" onClick={() => dispatch(addCustomTrailModal(false))}>
//             consumer subscriptions.
//           </Link>
//         </p>
//       </div>
//       <div className="modal-body">
//         <form className="form_field">
//           <div>
//             <label className="mb-1">Your Email Address</label>
//             <input type="text" className="form-control" placeholder="Email Address" name="custom_email" defaultValue={formData?.custom_email} onChange={(e) => inputHandler(e, "custom_email")} />
//             {emailError !== "" ? <p className="text-danger">{emailError}</p> : ""}
//           </div>
//           <div className="mt-3">
//             <label className="mb-1">Tell us more about the custom trail list you'd like</label>
//             <textarea className="form-control" id="" rows="4" placeholder="i.e. 'I'm traveling to Spain and would like a full trip itinerary with restaurants and activities' or 'I am looking for new sneakers for trail running and would like a list of options that match my profile'" name="custom_about" onChange={(e) => inputHandler(e, "custom_about")}></textarea>
//             {customAboutError !== "" ? <p className="text-danger">{customAboutError}</p> : ""}
//           </div>
//           <div className="mt-3">
//             <label className="mb-1">Is there anything else we should know?</label>
//             <textarea className="form-control" id="" rows="4" placeholder="i.e. a specific vibe you'd like for the trail list or any initial places/products you want to provide as inspiration" name="custom_inspiration" onChange={(e) => inputHandler(e, "custom_inspiration")}></textarea>
//             {/* {customInspirationError !== "" ? <p className="text-danger">{customInspirationError}</p> : ""} */}
//           </div>
//         </form>
//       </div>
//       <div className="modal-footer">
//         <button type="button" className="btn btn-primary save" onClick={() => onSubmit()} disabled={!(emailError === "" && customAboutError === "" && customInspirationError === "")}>
//           Submit
//         </button>
//       </div>
//     </Modal>
//   );
// };
