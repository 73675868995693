import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Modal } from "reactstrap";
import { useAddHeaderMutation, useUpdateHeaderMutation } from "../../API/trailHeaderAPI";
import { trailHeaderModal, yourHeadersModal, yourTrailsModal } from "../../redux/modalSlice";
import { WithContext as ReactTags } from "react-tag-input";
import * as Yup from "yup";
import { Formik } from "formik";
import { config } from "../../config";
import { toast } from "react-toastify";

export const AddHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const initialValues = { heading_name: "" };
  const trailIdState = useSelector((state) => state.modal.trailId);
  // const initialValues = { heading_name: "", trail_type: 1, trail_description: "", tags: [] };
  const [editFormData, setEditFormData] = useState(false);

  const [formError, setFormError] = useState("");
  const trailHeaderModalState = useSelector((state) => state.modal.trailHeaderModal);

  const closeModal = () => {
    dispatch(trailHeaderModal({ trailHeaderModal: false, trailId: null }));
    localStorage.removeItem("editHeader");
  };
  const handleTagClick = (index) => {
    // console.log("The tag at index " + index + " was clicked");
  };

  useEffect(() => {
    const trail = localStorage.getItem("editHeader") ? JSON.parse(localStorage.getItem("editHeader")) : undefined;

    if (trail) {
      setEditFormData(true);
    }
  }, [trailHeaderModalState]);

  const [addHeader, { data: trailData, isLoading, error, isError, isSuccess }] = useAddHeaderMutation();
  const [updateHeader, { data: updateHeaderPostData, isLoading: updateHeaderPostDataIsLoading, isSuccess: updateHeaderPostDataIsSuccess, isError: updateHeaderPostDataIsError, error: updateHeaderPostDataError }] = useUpdateHeaderMutation();

  const onSubmit = (values) => {
    console.log("submit called", values);
    let formData = trailIdState ? { ...values, slug: trailIdState } : { ...values };
    console.log("formData :: ", formData);
    if (editFormData) {
      formData = { ...formData, heading_id: localStorage.getItem("editHeader") ? JSON.parse(localStorage.getItem("editHeader"))?.heading_id : 0 };
      updateHeader(formData);
    } else {
      addHeader(formData);
    }
  };

  useEffect(() => {
    if ((trailData?.status === 200 && isSuccess) || (updateHeaderPostData?.status === 200 && updateHeaderPostDataIsSuccess)) {
      if (localStorage.getItem("editHeader")) localStorage.removeItem("editHeader");
      if (isSuccess && trailData.status === 200) {
        // dispatch(yourTrailsModal({ modal: false, post_id: undefined }));
        toast(trailData.message, {
          ...config.toast_config,
          type: "success",
        });
      } else if (isSuccess && trailData.status === 422) {
        toast(trailData.message, {
          ...config.toast_config,
          type: "error",
        });
      }
      closeModal();
      navigate(0);
    } else {
      if (editFormData) {
        setFormError(updateHeaderPostData?.message);
      } else {
        setFormError(trailData?.message);
      }
    }
  }, [isSuccess, updateHeaderPostDataIsSuccess]);

  const AddHeaderSchema = Yup.object().shape({
    heading_name: Yup.string().required("Header Name is required."),
  });

  return (
    <Modal isOpen={trailHeaderModalState} centered={true} contentClassName="login_form" toggle={closeModal}>
      <div className="modal-header">
        <h1 className="modal-title" id="exampleModalLabel">
          Heading Name
        </h1>
        <button type="button" className="btn-close" aria-label="Close" onClick={() => closeModal()}>
          <i className="fas fa-times"></i>
        </button>
      </div>

      <Formik
        initialValues={{
          heading_name: localStorage.getItem("editHeader") ? JSON.parse(localStorage.getItem("editHeader"))?.heading_name : initialValues.heading_name,
        }}
        validationSchema={AddHeaderSchema}
        onSubmit={(values) => {
          onSubmit(values);
        }}
      >
        {({ errors, touched, values, isValid, handleChange, handleSubmit, setFieldValue }) => (
          <div>
            {console.log({ errors, touched })}
            <div className="modal-body">
              <form className="form_field" onSubmit={handleSubmit}>
                <div>
                  <label className="mb-2">Header name</label>
                  <input type="text" className="form-control" placeholder="Header list name" name="heading_name" value={values.heading_name} onChange={handleChange} />
                  {errors.heading_name && <p className="text-danger">{errors.heading_name}</p>}
                  {formError !== "" ? <div className="my-2 text-danger">{formError}</div> : ""}
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-primary save"
                disabled={!isValid || values.heading_name === ""}
                onClick={(e) => {
                  console.log("Clicked called ", values);
                  handleSubmit();
                }}
              >
                {editFormData ? "Update" : "Create"}
              </button>
            </div>
          </div>
        )}
      </Formik>
    </Modal>
  );
};
