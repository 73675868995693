import React, { useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import { useSignupUserMutation } from "../../API/profileAPI";
import ImageCropper from "../../components/ImageCropper";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "reactstrap";
import { signUpModal, verifyEmailModal, logInModal } from "../../redux/modalSlice";
import { setSignUpUser } from "../../redux/authSlice";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { config } from "../../config";
import { Countries } from "../../assets/data/countries";

import * as Yup from "yup";
import { Formik } from "formik";
import { toast } from "react-toastify";

const SignUpPage = () => {
  const dispatch = useDispatch();

  const signUpState = useSelector((state) => state.modal.signUpModal);
  const [signupUser, { data: user, isLoading, error, isError, isSuccess }] = useSignupUserMutation();
  const [passwordType, setpasswordType] = useState("password");
  const [selected, setSelected] = useState("US");

  //for Image Cropping :: start
  const [imageToCrop, setImageToCrop] = useState(undefined);
  const [croppedImage, setCroppedImage] = useState(undefined);
  const [imageFile, setImageFile] = useState("");

  const onUploadFile = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setImageToCrop(reader.result));
      reader.readAsDataURL(event.target.files[0]);
      setImageFile(event.target.files);
    }
  };
  //for Image Cropping ::end

  const changePasswordType = () => {
    if (passwordType === "password") setpasswordType("text");
    else setpasswordType("password");
  };

  const onSubmit = (values) => {
    signupUser({
      ...values,
      country: Countries[selected],
      profile_image: imageFile,
    });
  };

  useEffect(() => {
    if (isSuccess && user.status === 200) {
      toast(user.message, {
        ...config.toast_config,
        type: "success",
      });
      dispatch(setSignUpUser(user));
      dispatch(signUpModal(false));
      dispatch(verifyEmailModal({ modal: true, email: user.data.email, isForgotPassword: false }));
    } else if (isSuccess && user.status === 422) {
      toast(user.message, {
        ...config.toast_config,
        type: "error",
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    setImageToCrop(undefined);
  }, [signUpState]);

  const SignupSchema = Yup.object().shape({
    full_name: Yup.string().required("Full Name is required."),
    email: Yup.string().email("Please enter valid email").required("Email is required."),
    // password: Yup.string().matches(config.passwordRegex, "Password should be 8 characters long and contain at least 1 capital letter, 1 lowercase letter, 1 number and 1 special character.").required("Password is required."),
    password: Yup.string().matches(config.passwordRegex, "Password should be minimum 8 characters and contain at least 1 capital letter,  1 number.").required("Password is required."),
  });

  return (
    <Modal isOpen={signUpState} centered={true} contentClassName="login_form">
      <div className="modal-header">
        <h1 className="modal-title text-center m-auto" id="exampleModalLabel">
          <img src={require("../../assets/images/favicon.png")} alt="" title="" />
          <p className="model_title_header mb-2">Create Account</p>
        </h1>
        <button
          type="button"
          className="btn-close"
          // data-bs-dismiss="modal"
          // aria-label="Close"
          onClick={() => {
            dispatch(signUpModal(false));
            // setFormError("");
          }}
        >
          <i className="fas fa-times"></i>
        </button>
      </div>
      <div className="modal-body">
        <Formik
          initialValues={{
            full_name: "",
            password: "",
            email: "",
          }}
          validationSchema={SignupSchema}
          onSubmit={(values) => {
            onSubmit(values);
          }}
        >
          {({ errors, touched, handleSubmit, values, handleChange, isValid }) => (
            <form className="form_field" encType="multipart/form-data">
              <div className="profile_img">
                {/* <img
                  src={require("../../assets/images/profile_dummy.png")}
                  alt=""
                  title=""
                />
                <input
                  type="file"
                  className="form-control"
                  id="inputGroupFile01"
                /> */}

                <input type="file" accept="image/*" onChange={onUploadFile} />
                {/* <div>
              <ImageCropper
                imageToCrop={imageToCrop}
                onImageCropped={(croppedImage) => setCroppedImage(croppedImage)}
              />
            </div> */}

                <img alt="Cropped Image" src={imageToCrop ? imageToCrop : require("../../assets/images/profile_dummy.png")} />
              </div>
              {errors && (
                <>
                  {errors.full_name && touched.full_name && <div className="my-2 text-danger">{errors.full_name}</div>}
                  {errors.email && touched.email && <div className="my-2 text-danger">{errors.email}</div>}
                  {errors.password && touched.password && <div className="my-2 text-danger">{errors.password}</div>}
                </>
              )}
              <div className="mb-3">
                <label className="mb-1">Your Full Name</label>
                <input type="text" className="form-control" placeholder="Your Name" id="full_name" onChange={handleChange} />
              </div>
              <div className="mb-3">
                <label className="mb-1">Your Email Address</label>
                <input type="text" className="form-control" placeholder="Email Address" id="email" onChange={handleChange} />
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="mb-1">Country</label>
                    <div className="position-relative">
                      <div className="input-group mb-3">
                        <ReactFlagsSelect
                          selected={selected}
                          onSelect={(code) => {
                            setSelected(code);
                            document.getElementById("rfs-btn").click();
                          }}
                          searchable
                          searchPlaceholder="search country"
                          className="country_selectinput"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="mb-1">Password</label>
                    <div className="position-relative">
                      <input id="password" type={passwordType} className="form-control pe-5" name="password" placeholder="*********" onChange={handleChange} />
                      <span toggle="#password-field1" className={passwordType === "password" ? "toggle_password " : "toggle_password slash_icon"} onClick={() => changePasswordType()}>
                        <img src={require("../../assets/images/eye.png")} alt="" title="" />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 my-3">
                  <button type="button" className="btn btn-primary save" onClick={() => handleSubmit(values)}>
                    Sign Up
                  </button>
                </div>
                <p className="m-0 text-center link_text">
                  Already have an account?&nbsp;
                  <Link
                    to="#"
                    onClick={() => {
                      dispatch(signUpModal(false));
                      dispatch(logInModal(true));
                    }}
                  >
                    Sign In
                  </Link>
                </p>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default SignUpPage;
