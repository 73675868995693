import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useAddIntrestedMutation, useGetSubscriptionQuery } from "../../API/subscriptionAPI";
import { logInModal, paymentModal, thruhikeConciergeThanksModal } from "../../redux/modalSlice";
import { PaymentList } from "../payment";
import { AddPayment } from "../payment/addPayment";

export const Subscription = () => {
  const dispatch = useDispatch();
  const [plan, setPlan] = useState("");
  const [type, setType] = useState(2);
  const { data, isLoading, isSuccess, isError, error } = useGetSubscriptionQuery(type);

  const [addIntrested, { data: interestedData, isLoading: interestedIsLoading, error: interestedError, isError: interestedIsError, isSuccess: interestedIsSuccess }] = useAddIntrestedMutation();
  const token = localStorage.getItem("token");

  if (token) {
    return (
      <>
        {/* <!-- top merchant text --> */}
        <div className="merchant_subscribe">
          <div className="container">
            <div className="inside_merchant about_thruhike">
              <div className="row align-items-center">
                <div className="col-md-6 col-lg-6 col-sm-12 col-12">
                  <h3>
                    Merchant <span className="green_color">Subscriptions</span>
                  </h3>
                  <p>Thruhike is a one-stop platform that provides the support your business is missing so you can grow, promote your offerings, and connect with your customers.</p>
                </div>
                <div className="col-md-6 col-lg-6 col-sm-12 col-12 merchant_img">
                  <img src={require("../../assets/images/subscribe_use.png")} alt="" title="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- top merchant text --> */}

        {/* <!-- middle marketing boxes--> */}
        <div className="middle_box_section">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-4 col-sm-12 col-12">
                <div className="inside_box box_1">
                  <img className="icon_img" src={require("../../assets/images/marketing.png")} alt="" title="" />
                  <h4>Marketing</h4>
                  <div className="description_content">
                    <p className="strong">Thruhike aims to give you more for less by:</p>
                    <ul>
                      <li>
                        <img src={require("../../assets/images/ellipse.png")} alt="" title="" />
                        Creating pro video content to aid business promotion
                      </li>
                      <li>
                        <img src={require("../../assets/images/ellipse.png")} alt="" title="" />
                        Helping you develop and refine your brand identity
                      </li>
                      <li>
                        <img src={require("../../assets/images/ellipse.png")} alt="" title="" />
                        Reaching an audience built for you
                      </li>
                    </ul>
                    <p>Our subscriptions cost roughly 50% less than typical content services and give you ongoing support as your business grows</p>
                  </div>
                </div>
              </div>

              <div className="col-md-12 col-lg-4 col-sm-12 col-12">
                <div className="inside_box box_2">
                  <img className="icon_img" src={require("../../assets/images/industry.png")} alt="" title="" />
                  <h4>Industry Insight</h4>
                  <div className="description_content">
                    <p>Thruhike brings industry knowledge currently spread across thousands of publications together in one place, tailored for you. Our proprietary consumer business matching system gives us insights no one else has and gives you insight into what individual consumers think of your business. We’ll take all that and compare against industry standards to help you earn additional revenue through upscaling your brand.</p>
                  </div>
                </div>
              </div>

              <div className="col-md-12 col-lg-4 col-sm-12 col-12">
                <div className="inside_box box_3">
                  <img className="icon_img" src={require("../../assets/images/experience.png")} alt="" title="" />
                  <h4>Customer Experience</h4>
                  <div className="description_content">
                    <p>Thruhike subscription plans are designed to provide the right amount of support for you to grow your business and deliver a premium customer experience. Our platform coupled with access to our Dashboard and associated analytics will help you:</p>
                    <ul>
                      <li>
                        <img src={require("../../assets/images/ellipse.png")} alt="" title="" />
                        Gain new customers
                      </li>
                      <li>
                        <img src={require("../../assets/images/ellipse.png")} alt="" title="" />
                        Amplify customers
                      </li>
                      <li>
                        <img src={require("../../assets/images/ellipse.png")} alt="" title="" />
                        Amplify customers
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- middle marketing boxes--> */}

        {/* <!-- subscribe plan --> */}
        <div className="vendor_subscription">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-12 col-sm-12 col-12">
                <h3>Vendor Subscription Plan</h3>
              </div>

              {isSuccess && data.data.length
                ? data.data.map((sub, index) => (
                  <div className="col-md-12 col-lg-4 col-sm-12 col-12">
                    <div className="inside_vsubscribe">
                      <div className="d-flex justify-content-between align-items-center">
                        <img src={require("../../assets/images/sub_dummy.png")} alt="" title="" />
                        {index === 1 ? (
                          <a href="javascript:void(0)" className="most_popular_btn">
                            Most Popular
                          </a>
                        ) : (
                          ""
                        )}
                      </div>
                      <h4>{sub.plan_name}</h4>
                      <h5>{sub.plan_description}</h5>
                      <h2>
                        $ {sub.plan_price}
                        <span>/{sub.plan_duration}</span>
                      </h2>
                      <Link
                        to="#"
                        className="btn_save btn_outline"
                        onClick={() => {
                          if (token !== "" && token !== undefined) {
                            addIntrested(sub?.id);
                            dispatch(thruhikeConciergeThanksModal(true));
                            // dispatch(paymentModal(true));
                          }
                        }}
                      >
                        Get Started
                      </Link>
                      <hr className="my-5" />
                      <div className="description_content">
                        <h6>What’s Include</h6>
                        <ul>
                          {sub.benefits.length
                            ? sub.benefits.map((benefit, index) => (
                              <li key={"sub_benifit_" + index}>
                                <img src={require("../../assets/images/check_img.png")} alt="" title="" />
                                {benefit}
                              </li>
                            ))
                            : ""}
                        </ul>
                      </div>
                    </div>
                  </div>
                ))
                : ""}

              {/* <div className="col-md-12 col-lg-4 col-sm-12 col-12">
                <div className="inside_vsubscribe">
                  <div className="d-flex justify-content-between align-items-center">
                    <img src={require("../../assets/images/sub_dummy.png")} alt="" title="" />
                  </div>
                  <h4>Community ESSENTIAL</h4>
                  <h5>Great for small businesses looking for a boost.</h5>
                  <h2>
                    $2995<span>/ year</span>
                  </h2>
                  <a href="javascript:void(0)" className="btn_save btn_outline">
                    Get Started
                  </a>
                  <hr className="my-5" />
                  <div className="description_content">
                    <h6>What’s Include</h6>
                    <ul>
                      <li>
                        <img src={require("../../assets/images/check_img.png")} alt="" title="" />
                        Two minutes of edited pro video content (single shoot)
                      </li>
                      <li>
                        <img src={require("../../assets/images/check_img.png")} alt="" title="" />
                        All the ESSENTIAL plan features include
                      </li>
                      <li>
                        <img src={require("../../assets/images/check_img.png")} alt="" title="" />
                        Personalized Industry Insight Report (Quarterly)
                      </li>
                      <li>
                        <img src={require("../../assets/images/check_img.png")} alt="" title="" />
                        Basic Thruhike Dashboard Access for high-level brand analytics (if applicable)
                      </li>
                      <li>
                        <img src={require("../../assets/images/check_img.png")} alt="" title="" />
                        Basic Thruhike Dashboard Access for high-level brand analytics (if applicable)
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-md-12 col-lg-4 col-sm-12 col-12">
                <div className="inside_vsubscribe">
                  <div className="d-flex justify-content-between align-items-center">
                    <img src={require("../../assets/images/sub_dummy.png")} alt="" title="" />
                    <a href="javascript:void(0)" className="most_popular_btn">
                      Most Popular
                    </a>
                  </div>
                  <h4>Community BUILDER</h4>
                  <h5>Great for small businesses focused on growth.</h5>
                  <h2>
                    $3995<span>/ year</span>
                  </h2>
                  <a href="javascript:void(0)" className="btn_save btn_outline">
                    Get Started
                  </a>
                  <hr className="my-5" />
                  <div className="description_content">
                    <h6>What’s Include</h6>
                    <ul>
                      <li>
                        <img src={require("../../assets/images/check_img.png")} alt="" title="" />
                        Everything in ESSENTIAL plan include
                      </li>
                      <li>
                        <img src={require("../../assets/images/check_img.png")} alt="" title="" />
                        Three 15 second social media videos quarterly (single shoot)
                      </li>
                      <li>
                        <img src={require("../../assets/images/check_img.png")} alt="" title="" />
                        Annual Hospitality Consult
                      </li>
                      <li>
                        <img src={require("../../assets/images/check_img.png")} alt="" title="" />
                        Pro Thruhike Dashboard Access with Customer Profiles (if applicable)
                      </li>
                      <li>
                        <img src={require("../../assets/images/check_img.png")} alt="" title="" />
                        Membership Program Hosting (bonus)
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-md-12 col-lg-4 col-sm-12 col-12">
                <div className="inside_vsubscribe">
                  <div className="d-flex justify-content-between align-items-center">
                    <img src={require("../../assets/images/sub_dummy.png")} alt="" title="" />
                  </div>
                  <h4>Community MAKER</h4>
                  <h5>Great for businesses or brands with multiple locations and/or products to feature.</h5>
                  <h2>
                    $7995<span>/ year</span>
                  </h2>
                  <a href="javascript:void(0)" className="btn_save btn_outline">
                    Get Started
                  </a>
                  <hr className="my-5" />
                  <div className="description_content">
                    <h6>What’s Include</h6>
                    <ul>
                      <li>
                        <img src={require("../../assets/images/check_img.png")} alt="" title="" />
                        Everything in BUILDER plan include
                      </li>
                      <li>
                        <img src={require("../../assets/images/check_img.png")} alt="" title="" />
                        Two Social Media Strategy Consults (one every 6 months)
                      </li>
                      <li>
                        <img src={require("../../assets/images/check_img.png")} alt="" title="" />
                        Two Hospitality Consults (one every 6 months)
                      </li>
                      <li>
                        <img src={require("../../assets/images/check_img.png")} alt="" title="" />
                        Membership Program Hosting with Development Consult (bonus)
                      </li>
                    </ul>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        {/* <!-- subscribe plan --> */}
      </>
    );
  } else {
    dispatch(logInModal(true));
    return <h1>You need to login first.</h1>;
  }
};
