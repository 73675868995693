import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "reactstrap";
import { addTagModal, tagArrayUpdated } from "../../redux/modalSlice";
import { WithContext as ReactTags } from "react-tag-input";
import { useState } from "react";
import { useAddTagForTrailMutation, useAddTagMutation } from "../../API/tagAPI";
import { toast } from "react-toastify";
import { config } from "../../config";

//for taginput suggestion :: start
const suggestions = [];
// const suggestions = COUNTRIES.map(country => {
//   return {
//     id: country,
//     text: country
//   };
// });

const KeyCodes = {
  tab: 9,
  space: 32,
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.tab, KeyCodes.space, KeyCodes.comma, KeyCodes.enter];
//for taginput suggestion :: end

export const AddTag = () => {
  const dispatch = useDispatch();
  const [addTag, { data: addedTags, isLoading, isSuccess, isError, error }] = useAddTagMutation();
  const [addTagForTrail, { data: addedTagsForTrail, isSuccess: isSuccessTrailTag }] = useAddTagForTrailMutation();
  const [formError, setFormError] = useState("");

  const addTagModalState = useSelector((state) => state.modal.addTagModal);
  const postIdState = useSelector((state) => state.modal.postId);
  const tagTypeState = useSelector((state) => state.modal.type);
  //tags input code and methods ::start
  const [tags, setTags] = React.useState([]);

  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag) => {
    setTags([...tags, tag]);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const handleTagClick = (index) => {
    // console.log("The tag at index " + index + " was clicked");
  };

  //tags input code and methods ::end

  const submitTagDetails = () => {
    console.log("submittedc ::", tags.length > 0 && postIdState !== "" && postIdState !== undefined);
    if (tags.length > 0 && postIdState !== "" && postIdState !== undefined) {
      let backEndFormatTagArray = [];

      if (tagTypeState === "trail") {
        tags.map((tag) => backEndFormatTagArray.push({ trail_tag: tag.text }));
        localStorage.setItem("tagList", JSON.stringify(backEndFormatTagArray));
        localStorage.setItem("trailId", JSON.stringify(postIdState));
        addTagForTrail({
          trail_id: postIdState,
          tag: JSON.stringify(backEndFormatTagArray),
        })
      } else {
        tags.map((tag) => backEndFormatTagArray.push({ post_tag: tag.text }));
        localStorage.setItem("tagList", JSON.stringify(backEndFormatTagArray));
        localStorage.setItem("postId", JSON.stringify(postIdState));
        addTag({
          post_id: postIdState,
          tag: JSON.stringify(backEndFormatTagArray),
        });
      }
      dispatch(tagArrayUpdated(true));
    } else {
      setFormError("Enter at least one tag");
    }
  };

  useEffect(() => {
    if (isSuccess && addedTags?.status === 200) {
      dispatch(addTagModal({ modal: false, postId: undefined }));
    } else {
      toast(addedTags?.message, {
        ...config.toast_config,
        type: "error",
      });
    }

    return () => {
      // console.log("cleaned up");
      setTags([]);
    };
  }, [isSuccess]);

  useEffect(() => {
    if (isSuccessTrailTag && addedTagsForTrail?.status === 200) {
      dispatch(addTagModal({ modal: false, postId: undefined, type: undefined }));
    } else {
      toast(addedTagsForTrail?.message, {
        ...config.toast_config,
        type: "error",
      });
    }

    return () => {
      // console.log("cleaned up");
      setTags([]);
    };
  }, [isSuccessTrailTag]);

  return (
    // <div
    //   className="modal fade"
    //   id="tagadd"
    //   tabIndex="-1"
    //   aria-labelledby="exampleModalLabel"
    //   aria-hidden="true"
    // >
    //   <div className="modal-dialog modal-dialog-centered">
    //     <div className="modal-content">
    <Modal
      isOpen={addTagModalState}
      centered={true}
    // contentClassName="login_form"
    >
      <div className="modal-header">
        <h1 className="modal-title" id="exampleModalLabel">
          Add your # tag
        </h1>
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={() => {
            setTags([]);
            dispatch(addTagModal({ modal: false, postId: undefined }));
          }}
        >
          <i className="fas fa-times"></i>
        </button>
      </div>
      <div className="modal-body">
        {formError !== "" ? <h5>{formError}</h5> : ""}
        <form className="form_field">
          {/* <textarea
            className="form-control"
            id=""
            rows="3"
            placeholder="Write you #tag"
          ></textarea> */}
          <ReactTags
            classNames={{
              tags: "tagsClass",
              tagInput: "tagInputClass",
              tagInputField: "form-control",
              selected: "selectedClass",
              tag: "tagClass",
              remove: "removeClass",
              suggestions: "suggestionsClass",
              activeSuggestion: "activeSuggestionClass",
              editTagInput: "editTagInputClass",
              editTagInputField: "editTagInputField",
              clearAll: "clearAllClass",
            }}
            tags={tags}
            suggestions={suggestions}
            delimiters={delimiters}
            handleDelete={handleDelete}
            handleAddition={handleAddition}
            handleDrag={handleDrag}
            handleTagClick={handleTagClick}
            inputFieldPosition="bottom"
            autocomplete
          />
        </form>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-primary save" onClick={() => submitTagDetails()} disabled={tags.length <= 0}>
          Save
        </button>
      </div>
    </Modal>
    //     </div>
    //   </div>
    // </div>
  );
};
