import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Modal } from "reactstrap";
import { useForgotPasswordMutation } from "../../API/authAPI";
import { forgotPasswordModal, logInModal, verifyEmailModal } from "../../redux/modalSlice";
import { toast } from "react-toastify";

import * as Yup from "yup";
import { Formik } from "formik";
import { config } from "../../config";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const [generateOtp, { data, isLoading, error, isError, isSuccess }] = useForgotPasswordMutation();
  const forgotPasswordModalState = useSelector((state) => state.modal.forgotPasswordModal);

  const [email, setEmail] = useState("");

  const onSubmit = (values) => {
    generateOtp({ email: values.email });
    setEmail(values.email);
  };

  useEffect(() => {
    if (isSuccess && data.status === 200) {
      dispatch(forgotPasswordModal({ modal: false }));
      toast(data.message, {
        ...config.toast_config,
        type: "success",
      });
      // console.log("Data: ", data);
      dispatch(verifyEmailModal({ modal: true, email, isForgotPassword: true }));
    } else if (isSuccess && data.status === 422) {
      toast(data.message, {
        ...config.toast_config,
        type: "error",
      });
    }
  }, [isSuccess]);

  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email("Please enter valid email").required("Email is required."),
  });

  return (
    <Modal isOpen={forgotPasswordModalState} centered={true} contentClassName="login_form">
      <div className="modal-header">
        <h1 className="modal-title text-center w-100" id="exampleModalLabel">
          <Link
            to="#"
            className="d-flex mt-3"
            onClick={() => {
              dispatch(forgotPasswordModal({ modal: false }));
              dispatch(logInModal(true));
            }}
          >
            <i className="fas fa-chevron-left me-3" style={{ color: "#66737F", fontSize: "15px" }}></i>
          </Link>
          <img src={require("../../assets/images/favicon.png")} alt="" title="" />
          <p className="model_title_header">Forgot Password</p>
          <span className="text_popup">Please provide your registration email so we can send you a password reset code!</span>
        </h1>
        <button
          type="button"
          className="btn-close"
          onClick={() => {
            dispatch(forgotPasswordModal({ modal: false }));
          }}
        >
          <i className="fas fa-times"></i>
        </button>
      </div>

      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={ForgotPasswordSchema}
        onSubmit={(values) => {
          onSubmit(values);
        }}
      >
        {({ errors, touched, handleSubmit, values, handleChange, isValid }) => (
          <div className="modal-body">
            {errors && <>{errors.email && touched.email && <div className="d-flex text-danger">{errors.email}</div>}</>}

            <form className="form_field">
              <div className="mb-3">
                <label className="mb-1">Your Email Address</label>
                <input type="text" name="email" className="form-control" placeholder="Email Address" onChange={handleChange} />
              </div>
              <div className="row">
                <div className="col-md-12 my-3">
                  <button type="button" className="btn btn-primary save" onClick={() => handleSubmit(values)} disabled={!isValid}>
                    Send Code
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
      </Formik>
    </Modal>
  );
};

export default ForgotPassword;
