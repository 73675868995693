import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      {/* // <!-- strat footer area --> */}
      <div className="footer">
        <div className="container d-flex">
          <p className="m-auto ms-0">© {new Date().getFullYear()} Thruhike All rights reserved</p>
          <ul className="footer_menu">
            <li>
              <Link to="/about-us">About</Link>
            </li>
            <li>
              <Link to="/">.</Link>
            </li>
            <li>
              <Link to="/faq">FAQ</Link>
            </li>
            <li>
              <Link to="/">.</Link>
            </li>
            <li>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/">.</Link>
            </li>
            <li>
              <Link to="/terms-conditions">Terms & Conditions</Link>
            </li>
          </ul>
          {/* <ul className="footer_menu m-auto me-0">
            <li>
              <Link to="#">
                <i className="fab fa-facebook-f"></i>
              </Link>
            </li>
            <li>
              <Link to="#">
                <i className="fab fa-twitter"></i>
              </Link>
            </li>
            <li>
              <Link to="#">
                <i className="fab fa-linkedin-in"></i>
              </Link>
            </li>
            <li>
              <Link to="#">
                <i className="fab fa-instagram"></i>
              </Link>
            </li>
          </ul> */}
        </div>
      </div>
      {/* // <!-- end footer area --> */}

      {/* <script src="../assets/js/owl.carousel.js"></script> */}
    </>
  );
}

export default Footer;
