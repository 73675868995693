import React from "react";
import { useEffect } from "react";
import { useState } from "react";

export const DynamicFeatureFields = ({ featureList, setFeatureList, action, hideMultiple = false }) => {
  const [featureFields, setFeatureFields] = useState([...featureList]);
  // const [featureFields, setFeatureFields] = useState([
  //   { award_description: "", artical_website: "" },
  // ]);

  //Dynamic Feature input methods ::start
  const handleChange = (i, e) => {
    let newFormValues = [...featureFields];
    newFormValues[i][e.target.name] = e.target.value;
    setFeatureFields(newFormValues);
    setFeatureList(newFormValues);
  };

  const addFormFields = () => {
    setFeatureFields([...featureFields, action ? { button_name: "", website: "" } : { award_description: "", artical_website: "" }]);
  };

  const removeFormFields = (i) => {
    let newFormValues = [...featureFields];
    newFormValues.splice(i, 1);
    setFeatureFields(newFormValues);
    featureList(newFormValues);
  };
  //Dynamic Feature input methods ::end

  useEffect(() => {
    setFeatureFields([...featureList]);
  }, [featureList]);

  return (
    <>
      {featureFields.map((element, index) => (
        <div className="row location_icon add_more" key={"featureRow" + index}>
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 mb-3 ">
            <input type="text" value={action ? element.button_name || "" : element.award_description || ""} className="form-control" placeholder={action ? "Button Name" : "Artical Name"} name={action ? "button_name" : "award_description"} onChange={(e) => handleChange(index, e)} />
          </div>
          <div className="col-lg-5 col-md-5 col-sm-5 col-5 mb-3">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text form-control" id="basic-addon1">
                  https://
                </span>
              </div>
              <input type="text" value={action ? element.website || "" : element.artical_website || ""} className="form-control" placeholder={action ? "Website" : "Article Website"} aria-label={action ? "Website" : "Article Website"} aria-describedby="basic-addon1" name={action ? "website" : "artical_website"} onChange={(e) => handleChange(index, e)} />
            </div>
            {featureFields.length > 1 && <img src={require("../assets/images/minus_icon.png")} alt="" title="" style={{ top: " 16px" }} onClick={() => removeFormFields(index)} />}
          </div>
        </div>
      ))}
      {hideMultiple ? (
        ""
      ) : (
        <div className="ms-auto" id="add" onClick={() => addFormFields()}>
          <img src={require("../assets/images/plus_icon.png")} alt="" title="" />
        </div>
      )}
    </>
  );
};
