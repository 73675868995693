import { subscriptionApi } from "./subscriptionAPI";
export const paymentApi = subscriptionApi.injectEndpoints({
  endpoints: (build) => ({
    getCards: build.query({
      query: (id) => `card-list`,
    }),
    addPaymentCard: build.mutation({
      query(body) {
        return {
          url: `card-create`,
          method: "POST",
          body,
        };
      },
    }),
    // updatePost: build.mutation({
    //   query(data) {
    //     const { id, ...body } = data;
    //     return {
    //       url: `posts/${id}`,
    //       method: "PUT",
    //       body,
    //     };
    //   },
    //   invalidatesTags: (post) => [{ type: "Posts", id: post?.id }],
    // }),
    // deletePost: build.mutation({
    //   query(id) {
    //     return {
    //       url: `posts/${id}`,
    //       method: "DELETE",
    //     };
    //   },
    //   invalidatesTags: (post) => [{ type: "Posts", id: post?.id }],
    // }),
  }),
});

export const { useGetCardsQuery, useAddPaymentCardMutation } = paymentApi;
