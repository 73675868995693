import React, { memo, useCallback, useRef } from "react";
import { GoogleMap, InfoWindowF, Marker } from "@react-google-maps/api";
import { useEffect } from "react";
import { useNearByPostsQuery } from "../../API/postAPI";
import Card from "../Card";
import debounce from "lodash/debounce";
import { Link, useLocation } from "react-router-dom";
import CustomIcon from "../../assets/images/location-pin.svg";
import Geocode from "react-geocode";
import { useSelector } from "react-redux";
import { config } from "../../config";

const CustomGoogleMap = ({ setAPIResponse }) => {
  const currentPath = useLocation();

  const divStyle = {
    background: `white`,
    padding: 15,
  };
  const markers = [
    {
      name: "ahmedabad",
      position: {
        lat: 24.5854,
        lng: 73.7125,
      },
    },
    {
      name: "udaypur",
      position: {
        lat: 24.5854,
        lng: 73.7125,
      },
    },
    {
      name: "mumbai",
      position: {
        lat: 19.076,
        lng: 72.8777,
      },
    },
    {
      name: "junagadh",
      position: {
        lat: 21.5222,
        lng: 70.4579,
      },
    },
  ];
  const mapRef = useRef(null);
  const [center, setCenter] = React.useState({ lat: 44.825034, lng: -69.292383 });
  const [markerData, setMarkerData] = React.useState([]);
  const [infoWindowData, setInfoWindowData] = React.useState(undefined);

  const { data: posts, isLoading, isSuccess, isError, error, isFetching, refetch } = useNearByPostsQuery(center);

  const onCenterChanged = (centerValue) => {
    console.log(centerValue);
    setCenter(centerValue);
  };

  const location = useSelector((state) => state.modal.location);

  const debouncedOnChange = debounce(onCenterChanged, 100);

  const handleCenterChanged = useCallback(() => {
    if (mapRef.current !== undefined && mapRef.current !== null) {
      const newCenter = mapRef.current.getCenter();
      if (newCenter !== undefined && +newCenter.lat() !== center.lat && +newCenter.lng() !== center.lng) {
        debouncedOnChange({ lat: +newCenter.lat(), lng: +newCenter.lng() });
      }
    }
  }, [mapRef]);

  const handleOnLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const handleOnUnmount = useCallback(() => {
    mapRef.current = null;
  }, []);

  const onLoad = () => {
    console.log("infowindow load");
  };

  useEffect(() => {
    if (posts && posts.status === 200 && posts.data.length) {
      setAPIResponse([...posts.data]);
      setMarkerData([...markerData, ...posts.data.filter((e) => markerData.findIndex((j) => j.id === e.id) === -1)]);
    }
    // setMarkerData(markers);
    return () => {};
  }, [mapRef, isSuccess]);

  useEffect(() => {
    if (location !== "") {
      // Get latitude & longitude from address.
      Geocode.fromAddress(location, config?.google_api_key).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          console.log(lat, lng);
          setCenter({ lat, lng });
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }, [location]);

  return (
    <>
      <GoogleMap
        center={center}
        zoom={8}
        onLoad={(map) => handleOnLoad(map)}
        onDragEnd={() => {
          if (mapRef && mapRef.current !== null) {
            handleCenterChanged();
          }
        }}
        onUnmount={handleOnUnmount}
        mapContainerStyle={{ height: "79vh", width: "100%" }}
        onZoomChanged={() => {
          if (mapRef && mapRef.current !== null) {
            refetch();
          }
        }}
      >
        {markerData &&
          markerData.length &&
          markerData.map((mark, index) => (
            <Marker
              position={{ lat: mark.latitude, lng: mark.longitude }}
              onClick={() => {
                setInfoWindowData({ ...mark, selectedMarker: index });
                // setMarkerData([...markerData, { lat: mark.latitude, lng: mark.longitude }]);
              }}
              key={index}
              // show={infoWindowData?.latitude !== mark.latitude && infoWindowData?.longitude !== mark.longitude}
              icon={{
                url: CustomIcon, // Use the imported SVG as the marker icon
                scaledSize: new window.google.maps.Size(40, 30), // Adjust the size as needed
              }}
            >
              {infoWindowData && infoWindowData.selectedMarker === index && (
                <InfoWindowF
                  onLoad={() => onLoad()}
                  // position={{ lat: infoWindowData.latitude, lng: infoWindowData.longitude }}
                  onCloseClick={() => {
                    setInfoWindowData(undefined);
                  }}
                  // marker={{ lat: infoWindowData.latitude, lng: infoWindowData.longitude }}
                  zIndex={99999}
                >
                  <div style={divStyle}>
                    <Card post={infoWindowData} />
                  </div>
                </InfoWindowF>
              )}
            </Marker>
          ))}
      </GoogleMap>
      {/* <Link to={currentPath.pathname.includes("map") ? "/" : "map"} type="button" className="btn btn-primary position-fixed" style={{ top: "90%", left: "50%", backgroundColor: "#008b2a", borderColor: "#008b2a" }}>
        {currentPath.pathname.includes("map") ? "Show List" : "Show Map"}
      </Link> */}
    </>
  );
};

export default memo(CustomGoogleMap);
