import { cliamBusinessApi } from "./businessAPI";
export const analyticsApi = cliamBusinessApi.injectEndpoints({
  endpoints: (build) => ({
    getAnalytics: build.query({
      query: (post_id) => `analytics?slug=${post_id}`,
    }),
    getViewAllAnalytics: build.query({
      query: (data) => `average-time-period?duration=${data?.duration}&slug=${data?.post_id}`,
    }),
    getUserTagsAnalytics: build.query({
      query: (post_id) => `usertags?slug=${post_id}`,
    }),
  }),
});

export const { useGetAnalyticsQuery, useGetViewAllAnalyticsQuery, useGetUserTagsAnalyticsQuery } = analyticsApi;
