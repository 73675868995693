import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "reactstrap";
import { useAddCliamBusinessMutation } from "../../API/businessAPI";
import { config } from "../../config";
import {
  cliamBusinessModal,
  thruhikeConciergeThanksModal,
} from "../../redux/modalSlice";

export const ClaimBusiness = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [formError, setFormError] = useState("");
  const [btnDisable, setBtnDisable] = useState(true);

  const [addCliamBusiness, { data, isLoading, error, isError, isSuccess }] =
    useAddCliamBusinessMutation();

  const cliamBusinessModalState = useSelector(
    (state) => state.modal.cliamBusinessModal
  );
  const post_id = useSelector((state) => state.modal.postId);

  const inputHandler = (event, fieldName) => {
    // let formData = formData;
    if (event.target.value !== "") {
      formData[fieldName] = event.target.value;
      setFormData(formData);
    } else {
      setFormError(fieldName + " is required.");
    }
    // console.log("formData :: ", formData);
    if (
      formData &&
      formData?.business_name !== "" &&
      formData?.claimer_name !== "" &&
      formData?.claim_title !== "" &&
      formData?.claim_email !== ""
    ) {
      setBtnDisable(false);
    }
  };

  const onSubmit = () => {
    formData["post_id"] = post_id;
    // console.log("formData :: ", formData);
    if (formData && config.emailRegex.test(formData.claim_email)) {
      addCliamBusiness(formData);
    } else {
      setFormError("Enter valid email.");
    }
    // if (
    //   formData &&
    //   formData?.business_name !== "" &&
    //   formData?.claimer_name !== "" &&
    //   formData?.claim_title !== "" &&
    //   formData?.claim_email !== ""
    // ) {
    //   addCliamBusiness(formData);
    // }
  };

  useEffect(() => {
    if (isSuccess && data.status == 200) {
      setFormError("");
      dispatch(cliamBusinessModal({ modal: false }));
      dispatch(thruhikeConciergeThanksModal(true));
    } else {
      setFormError(data?.message);
    }
  }, [isSuccess]);

  return (
    // <div
    //   className="modal fade"
    //   id="cliambusiness"
    //   tabindex="-1"
    //   aria-labelledby="exampleModalLabel"
    //   aria-hidden="true"
    // >
    //   <div className="modal-dialog modal-dialog-centered">
    //     <div className="modal-content">
    <Modal
      isOpen={cliamBusinessModalState}
      centered={true}
      contentClassName="login_form"
    >
      <div className="modal-header">
        <h1 className="modal-title" id="exampleModalLabel">
          Claim the Business
        </h1>
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={() => dispatch(cliamBusinessModal({ modal: false }))}
        >
          <i className="fas fa-times"></i>
        </button>
      </div>
      <div className="modal-body">
        {formError !== "" ? (
          <div className="my-2 text-danger">{formError}</div>
        ) : (
          ""
        )}
        <form className="form_field">
          <div>
            <label className="mb-1">Confirm Name of Business*</label>
            <input
              type="text"
              className="form-control"
              placeholder="Business Name"
              onChange={(e) => inputHandler(e, "business_name")}
              required
            />
          </div>
          <div className="mt-3">
            <label className="mb-1">Your Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Name"
              onChange={(e) => inputHandler(e, "claimer_name")}
              required
            />
          </div>
          <div className="mt-3">
            <label className="mb-1">Your Title</label>
            <input
              type="text"
              className="form-control"
              placeholder="Title"
              onChange={(e) => inputHandler(e, "claim_title")}
              required
            />
          </div>
          <div className="mt-3">
            <label className="mb-1">Your Business Email</label>
            <input
              type="text"
              className="form-control"
              placeholder="Business Email"
              onChange={(e) => inputHandler(e, "claim_email")}
              required
            />
          </div>
          <p className="text_popup text-center mt-3 mb-0">
            We will use this information to confirm your relationship to the
            business and ability to claim this page. Please allow 1-2 business
            days for an email confirmation from our team allowing you to access
            your business profile. Thank you.
          </p>
        </form>
      </div>
      <div className="modal-footer">
        <button
          type="submit"
          onClick={(e) => onSubmit(e)}
          className="btn btn-primary save"
          disabled={btnDisable}
        >
          Submit
        </button>
      </div>
    </Modal>
    //     {/* </div>
    //   </div>
    // </div> */}
  );
};
