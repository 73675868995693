import React from "react";
import { Link } from "react-router-dom";
import NoFoundComponent from "../pages/NoFoundComponent";
import { addTrailModal } from "../redux/modalSlice";
import { useDispatch } from "react-redux";
import { AES } from "crypto-js";
import { config } from "../config";

export const TrailGroupCard = ({ data, isId }) => {
  const dispatch = useDispatch();
  return (
    <>
      {data && data.length ? (
        data.map((trail, index) => {
          let redirectLink = "#";
          if (isId && trail?.slug) {
            redirectLink = `/trail-details/${trail?.slug}`;
          } else if (!isId && trail?.slug) {
            redirectLink = `/trail-details/${trail?.slug}`;
          } else {
            redirectLink = "#";
          }
          return (
            <div className="col-lg-2" key={"TrailGroupCardWithMultiImage" + index}>
              <Link to={redirectLink}>
                <div className="hz_traillist">
                  <div className="trail_mainimg">
                    {trail?.images && trail?.images.length ? (
                      trail.images.map((image, imgIndex) => (
                        <div className={trail?.images && trail?.images.length === 1 ? "single_img" : "multiple_img"} key={"trail_images" + imgIndex}>
                          <img src={image} alt="" title="" />
                        </div>
                      ))
                    ) : (
                      <div className="multiple_img">
                        <img src={require("../assets/images/img9.png")} alt="" title="" />
                      </div>
                    )}
                  </div>
                  <h5>
                    <span className="trailtitle">{trail?.trail_name}</span>
                  </h5>
                  <i className="by_user">By {trail?.user_name}</i>
                </div>
              </Link>
            </div>
          );
        })
      ) : (
        <>
          <NoFoundComponent message={"No Trail Lists Found. Create a Trail List."} />
          <div className="trail_btn">
            <Link className="add_trail" onClick={() => dispatch(addTrailModal(true))}>
              <i className="fas fa-plus"></i> Add a trail
            </Link>
          </div>
        </>
      )}
    </>
  );
};
