import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useSigninUserMutation } from "../../API/authAPI";
import { setSignUpUser, setUser } from "../../redux/authSlice";
import $ from "jquery";
import { Modal } from "reactstrap";
import { forgotPasswordModal, logInModal, signUpModal, verifyEmailModal } from "../../redux/modalSlice";
import { useEffect } from "react";
import { Link } from "react-router-dom";

import * as Yup from "yup";
import { Formik } from "formik";
import { config } from "../../config";
import { toast } from "react-toastify";

const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [passwordType, setpasswordType] = useState("password");
  const logInModalState = useSelector((state) => state.modal.logInModal);
  const [signinUser, { data, isLoading, error, isError, isSuccess }] = useSigninUserMutation();

  const changePasswordType = () => {
    if (passwordType === "password") setpasswordType("text");
    else setpasswordType("password");
  };

  const onSubmit = (values) => {
    signinUser(values);
  };

  useEffect(() => {
    if (isSuccess && data.status === 200) {
      if (data?.data?.email_verified_at !== "") {
        localStorage.setItem("showFirstTimeBanner", true);
        dispatch(setUser(data));
        navigate(0);
        navigate("/posts");
      } else {
        dispatch(setSignUpUser(data));
        dispatch(verifyEmailModal({ modal: true, email: data?.data?.email }));
      }
      dispatch(logInModal(false));
      // toast(data.message, {
      //   ...config.toast_config,
      //   type: "success",
      // });
    } else if (isSuccess && data.status === 422) {
      toast(data.message, {
        ...config.toast_config,
        type: "error",
      });
    }
  }, [isSuccess]);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email("Please enter valid email").required("Email is required."),
    // password: Yup.string().matches(config.passwordRegex, "Password should be 8 characters long and contain at least 1 capital letter, 1 lowercase letter, 1 number and 1 special character.").required("Password is required."),
    password: Yup.string().required("Password is required."),
  });

  return (
    <Modal isOpen={logInModalState} centered={true} contentClassName="login_form">
      <div className="modal-header">
        <h1 className="modal-title text-center m-auto" id="exampleModalLabel">
          <img src={require("../../assets/images/favicon.png")} alt="" title="" />
          <p className="model_title_header">Sign In</p>
        </h1>
        <button
          type="button"
          className="btn-close"
          // data-bs-dismiss="modal"
          // aria-label="Close"
          onClick={() => dispatch(logInModal(false))}
        >
          <i className="fas fa-times"></i>
        </button>
      </div>

      <Formik
        initialValues={{
          password: "",
          email: "",
        }}
        validationSchema={LoginSchema}
        onSubmit={(values) => {
          onSubmit(values);
        }}
      >
        {({ errors, touched, handleSubmit, values, handleChange, isValid }) => (
          <div className="modal-body">
            {errors && (
              <>
                {errors.email && touched.email && <div className="d-flex text-danger">{errors.email}</div>}
                {/* {errors.password && touched.password && <div className="d-flex text-danger">{errors.password}</div>} */}
              </>
            )}
            <form className="form_field">
              <div className="mb-3">
                <label className="mb-1">Your Email address</label>
                <input type="text" name="email" className="form-control" placeholder="Email Address" onChange={handleChange} />
              </div>
              <div className="mb-3">
                <label className="mb-1">Password</label>
                <div className="position-relative">
                  <input name="password" type={passwordType} className="form-control pe-5" placeholder="*********" onChange={handleChange} />
                  <span toggle="#password-field" className="toggle_password" onClick={() => changePasswordType()}>
                    <img src={require("../../assets/images/eye.png")} alt="" title="" />
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-6 text-start">
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <input className="custom-control-input me-2" type="checkbox" name="remember" id="customCheckLogin" />
                    <label className="custom-control-label" htmlFor="customCheckLogin">
                      <span className="text-muted">Remember me</span>
                    </label>
                  </div>
                </div>
                <div className="col-6 text-end">
                  <Link
                    className=""
                    to="#"
                    onClick={() => {
                      dispatch(forgotPasswordModal({ modal: true }));
                      dispatch(logInModal(false));
                    }}
                    style={{ color: "#2A1483" }}
                  >
                    Forgot password?
                  </Link>
                </div>
                <div className="col-md-12 my-4">
                  {/* disabled={!isValid} */}
                  <button type="button" className="btn btn-primary save" onClick={() => handleSubmit(values)}>
                    Sign In
                  </button>
                </div>
                <p className="m-0 text-center link_text">
                  Don’t have an account?&nbsp;
                  <Link
                    to="#"
                    onClick={() => {
                      dispatch(signUpModal(true));
                      dispatch(logInModal(false));
                    }}
                  >
                    Sign Up
                  </Link>
                </p>
              </div>
            </form>
          </div>
        )}
      </Formik>
      {/* </div>
      </div> */}
      {/* // </div> */}
    </Modal>
  );
};

export default LoginPage;
