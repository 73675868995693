import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  logInModal: false,
  forgotPasswordModal: false,
  signUpModal: false,
  verifyEmailModal: false,
  profileModal: false,
  addTrailModal: false,
  addCustomTrailModal: false,
  addCustomTrailThanksModal: false,
  thruhikeConciergeModal: false,
  thruhikeConciergeThanksModal: false,
  addTagModal: false,
  yourTrailsModal: false,
  shareModal: false,
  searchText: localStorage.getItem("searchText"),
  email: "",
  changePasswordModal: false,
  resetPasswordModal: false,
  location: localStorage.getItem("location") ? JSON.parse(localStorage.getItem("location")) : "",
  cliamBusinessModal: false,
  addPaymentModal: false,
  paymentModal: false,
  tagArrayUpdated: false,
  updatesModal: false,
  updatesModalData: undefined,
  isForgotPassword: false,
  shareLink: "",
  introSliderModal: localStorage.getItem("introSlider") ? JSON.parse(localStorage.getItem("introSlider")) : false,
  trailHeaderModal: false,
  trailId: null,
  deleteHeaderModal: false,
  deleteHeaderId: null
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    logInModal: (state, action) => {
      // state.logInModal = action.payload;
      return { ...state, logInModal: action.payload };
    },
    forgotPasswordModal: (state, action) => {
      return { ...state, forgotPasswordModal: action.payload.modal };
    },
    signUpModal: (state, action) => {
      // state.signUpModal = action.payload;
      return { ...state, signUpModal: action.payload };
    },
    verifyEmailModal: (state, action) => {
      // state.verifyEmailModal = action.payload.modal;
      // state.email = action.payload.email;
      return {
        ...state,
        verifyEmailModal: action.payload.modal,
        email: action.payload.email,
        isForgotPassword: action.payload.isForgotPassword,
      };
    },
    profileModal: (state, action) => {
      // state.profileModal = action.payload;
      return { ...state, profileModal: action.payload };
    },
    addTrailModal: (state, action) => {
      // state.addTrailModal = action.payload;
      return { ...state, addTrailModal: action.payload };
    },
    addCustomTrailModal: (state, action) => {
      // state.addCustomTrailModal = action.payload;
      return { ...state, addCustomTrailModal: action.payload };
    },
    addCustomTrailThanksModal: (state, action) => {
      // state.addCustomTrailThanksModal = action.payload;
      return { ...state, addCustomTrailThanksModal: action.payload };
    },
    thruhikeConciergeModal: (state, action) => {
      // state.thruhikeConciergeModal = action.payload;
      return { ...state, thruhikeConciergeModal: action.payload };
    },
    thruhikeConciergeThanksModal: (state, action) => {
      // state.addCustomTrailThanksModal = action.payload;
      return { ...state, addCustomTrailThanksModal: action.payload };
    },
    addTagModal: (state, action) => {
      // state.addTagModal = action.payload.modal;
      // state.postId = action.payload.postId;
      return {
        ...state,
        addTagModal: action.payload.modal,
        postId: action.payload.postId,
        type: action.payload.type,
      };
    },
    yourTrailsModal: (state, action) => {
      return {
        ...state,
        yourTrailsModal: action.payload.modal,
        postId: action.payload.postId,
      };
    },
    shareModal: (state, action) => {
      return {
        ...state,
        shareModal: action.payload.modal,
        postId: action.payload.postId,
        shareLink: action.payload.shareLink,
      };
    },
    handleChangeSearchText: (state, action) => {
      if (action.payload !== undefined && action.payload !== "") localStorage.setItem("searchText", action.payload);
      return { ...state, searchText: action.payload };
    },
    setLocation: (state, action) => {
      localStorage.setItem("location", JSON.stringify(action.payload));
      return { ...state, location: action.payload };
    },
    cliamBusinessModal: (state, action) => {
      return {
        ...state,
        cliamBusinessModal: action.payload.modal,
        postId: action.payload.postId,
      };
    },
    changePasswordModal: (state, action) => {
      return { ...state, changePasswordModal: action.payload.modal };
    },
    resetPasswordModal: (state, action) => {
      return { ...state, resetPasswordModal: action.payload.modal, email: action.payload.email };
    },
    addPaymentModal: (state, action) => {
      return { ...state, addPaymentModal: action.payload };
    },
    paymentModal: (state, action) => {
      return { ...state, paymentModal: action.payload };
    },
    tagArrayUpdated: (state, action) => {
      return { ...state, tagArrayUpdated: action.payload };
    },
    updatesModal: (state, action) => {
      return { ...state, updatesModal: action.payload.modal, updatesModalData: action.payload.postId };
    },
    introSliderModal: (state, action) => {
      localStorage.removeItem("introSlider");
      return { ...state, introSliderModal: action.payload };
    },
    trailHeaderModal: (state, action) => {
      return { ...state, trailHeaderModal: action.payload.trailHeaderModal, trailId: action.payload.trailId };
    },
    deleteHeaderModal: (state, action) => {
      console.log("action :: ", action);
      return { ...state, deleteHeaderModal: action.payload.modal, deleteHeaderId: action.payload.id }
    }
  },
});

// Action creators are generated for each case reducer function
export const { logInModal, forgotPasswordModal, signUpModal, verifyEmailModal, profileModal, addTrailModal, addCustomTrailModal, addCustomTrailThanksModal, thruhikeConciergeModal, thruhikeConciergeThanksModal, addTagModal, yourTrailsModal, shareModal, handleChangeSearchText, setLocation, cliamBusinessModal, changePasswordModal, resetPasswordModal, addPaymentModal, paymentModal, tagArrayUpdated, updatesModal, introSliderModal, trailHeaderModal, deleteHeaderModal } = modalSlice.actions;

export default modalSlice.reducer;
