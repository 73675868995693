import React, { useEffect, useState } from "react";
import videojs from "video.js";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useViewCateogriesQuery } from "../../API/categoryAPI";
import { useAddProductMutation } from "../../API/productAPI";
import { WithContext as ReactTags } from "react-tag-input";
import * as Yup from "yup";
import { Formik } from "formik";
import { config } from "../../config";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import $ from "jquery";
import { DynamicFeatureFields } from "../../components/DynamicFeatureFields";
import { toast } from "react-toastify";
import { Loader } from "../../components/Loader";
import { useDeletePostMediaMutation, useUpdatePostMutation } from "../../API/postAPI";
import Video from "../../components/Video";

//for taginput suggestion :: start
let suggestions = [];
// const suggestions = COUNTRIES.map(country => {
//   return {
//     id: country,
//     text: country
//   };
// });

const KeyCodes = {
  tab: 9,
  space: 32,
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.tab, KeyCodes.space, KeyCodes.comma, KeyCodes.enter];
//for taginput suggestion :: end

//convert url to file object ::start
const urlToFileConverter = async (mediaUrl, type, id) => {
  let fileName = type === 1 ? mediaUrl.split("/").pop().split("?")[0] : mediaUrl.split("/")[mediaUrl.split("/").length - 2];
  // Fetch the media data
  return fetch(mediaUrl)
    .then((response) => response.blob())
    .then((blob) => {
      // Create a new File object from the blob
      const file = new File([blob], fileName, { type: type === 1 ? "image/jpg" : "video/mp4" });
      file["id"] = id;
      // Do something with the file object
      // console.log(file);
      return file;
    })
    .catch((error) => {
      // Handle any errors
      console.error(error);
    });
};
//convert url to file object ::end

export const Add = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const playerRef = React.useRef(null);

  const [addProduct, { data: products, isLoading, isSuccess, isError, error }] = useAddProductMutation();
  const { data: categories, isLoading: categoryLoading, isSuccess: isCategorySuccess, isError: isCategoryError, error: categoryError } = useViewCateogriesQuery();

  const [deletePostMedia, { data: deletePostMediaData, isLoading: deletePostMediaIsLoading, isSuccess: deletePostMediaIsSuccess, isError: deletePostMediaIsError, error: deletePostMediaError }] = useDeletePostMediaMutation();
  const [updatePost, { data: updateProductData, isLoading: updateProductIsLoading, isSuccess: updateProductIsSuccess, isError: updateProductIsError, error: updateProductError }] = useUpdatePostMutation();

  const [isEditForm, setIsEditForm] = useState(false);
  const [featureList, setFeatureList] = useState([{ award_description: "", artical_website: "" }]);
  const [actionList, setActionList] = useState([{ button_name: "", website: "" }]);

  const [imageArray, setImageArray] = useState([]);
  const [mediaArray, setMediaArray] = useState([]);
  const [mediaError, setMediaError] = useState("");

  const [isDetailsLoading, setIsDetailsLoading] = useState(true);

  useEffect(() => {
    //   // For Edit Prodcut Details ::start
    const setFunctionData = async () => {
      const productDetails = localStorage.getItem("editPost") ? JSON.parse(localStorage.getItem("editPost")) : undefined;
      if (productDetails) {
        console.log("productDetails: ", productDetails);
        setIsEditForm(true);
        productDetails && productDetails.awards && setFeatureList([...productDetails.awards]);
        productDetails && productDetails.post_call_to_action && setActionList([...productDetails.post_call_to_action]);
        setImageArray([...productDetails.media]);

        Promise.all(
          productDetails.media.map((media, index) => {
            return urlToFileConverter(media.media_name, media.type, media.id).then((res) => {
              return res;
            });
          })
        )
          .then((res) => setMediaArray([...res]))
          .finally(() => setIsDetailsLoading(false));

        // console.warn("updateImagesObjects :: ", updateImagesObjects);

        // setIsDetailsLoading(false);
      } else {
        setIsDetailsLoading(false);
      }
    };
    setFunctionData();
  }, []);

  useEffect(() => {
    console.warn("mediaArray ::", mediaArray);
    console.warn("type :: mediaArray ::", typeof mediaArray);

    if (!isDetailsLoading) {
      if (isEditForm) {
        // console.log("IN USE EFFECT", mediaArray);
        const hasAnyImage = imageArray.filter((e) => e?.type === 1);
        const hasAnyVideos = imageArray.filter((e) => e?.type === 2);
        const images = mediaArray.filter((file) => file.type.startsWith("image/"));
        const videos = mediaArray.filter((file) => file.type.startsWith("video/"));

        if ([...images, ...hasAnyImage].length === 0 || [...videos, ...hasAnyVideos].length === 0) {
          // setMediaError("At least 1 image 1 video required.");
        } else {
          setMediaError("");
        }
      } else {
        const images = mediaArray.filter((file) => file.type.startsWith("image/"));
        const videos = mediaArray.filter((file) => file.type.startsWith("video/"));

        if (images.length === 0 || videos.length === 0) {
          // setMediaError("At least 1 image 1 video required.");
        } else {
          setMediaError("");
        }
      }
    }
  }, [mediaArray, imageArray, isEditForm, isDetailsLoading]);

  const handleTagClick = (index) => {
    // console.log("The tag at index " + index + " was clicked");
  };

  const onSubmit = (values) => {
    const formValues = { ...values };

    const initialValues = {
      post_name: JSON.parse(localStorage.getItem("editPost"))?.post_name || "",
      category_id: JSON.parse(localStorage.getItem("editPost"))?.category_id || null,
      address: JSON.parse(localStorage.getItem("editPost"))?.address || "",
      city: JSON.parse(localStorage.getItem("editPost"))?.city || "",
      state: JSON.parse(localStorage.getItem("editPost"))?.state || "",
      country: JSON.parse(localStorage.getItem("editPost"))?.country || "",
      latitude: JSON.parse(localStorage.getItem("editPost"))?.latitude || "",
      longitude: JSON.parse(localStorage.getItem("editPost"))?.longitude || "",
      updates: JSON.parse(localStorage.getItem("editPost"))?.updates || "",
      call_to_action: JSON.parse(localStorage.getItem("editPost"))?.call_to_action || 1,
      tags:
        JSON.parse(localStorage.getItem("editPost"))?.tags?.map((tag) => {
          return {
            id: tag.tag_name,
            text: tag.tag_name,
          };
        }) || [],
      vendor_contact: JSON.parse(localStorage.getItem("editPost"))?.vendor_contact || "",
      vendor_website: JSON.parse(localStorage.getItem("editPost"))?.vendor_website || "",
      description: JSON.parse(localStorage.getItem("editPost"))?.description || "",
      awards: JSON.parse(localStorage.getItem("editPost"))?.awards || "",
      post_call_to_action: JSON.parse(localStorage.getItem("editPost"))?.post_call_to_action || "",
      // button_name: JSON.parse(localStorage.getItem("editPost"))?.button_name || "",
      // website: JSON.parse(localStorage.getItem("editPost"))?.website || "",
    };

    const actualFormValuesToBeSet = {};

    // console.log("formValues: ", formValues);
    // console.log(initialValues);
    Object.keys(formValues)
      .filter((e) => e !== "tags")
      .forEach((fieldKey) => {
        if (formValues[fieldKey] !== initialValues[fieldKey]) {
          actualFormValuesToBeSet[fieldKey] = formValues[fieldKey];
        }
      });

    // MEDIA remaining
    const initialValueOfMedia = JSON.parse(localStorage.getItem("editPost"))?.media || [];
    if (mediaArray.length != initialValueOfMedia.length || mediaArray.filter((e) => !e.id).length > 0) {
      actualFormValuesToBeSet["media_name"] = [...mediaArray];
    }

    if (JSON.stringify(initialValues.tags) !== JSON.stringify(values.tags)) {
      let tmpTag = [];
      values.tags.map((tag) => tmpTag.push({ post_tag: tag.text }));
      actualFormValuesToBeSet["post_tag"] = JSON.stringify(tmpTag);
    }

    if (JSON.stringify(initialValues["awards"]) !== JSON.stringify(featureList)) {
      const filteredFeatureList = featureList.filter((item) => {
        // Filter out objects that are empty
        if (Object.keys(item).length === 0) {
          return false;
        }

        // Filter out objects with empty string values
        for (const key in item) {
          if (item[key] === "") {
            return false;
          }
        }

        return true;
      });
      actualFormValuesToBeSet["post_award"] = JSON.stringify(filteredFeatureList);
    }

    if (JSON.stringify(initialValues["post_call_to_action"]) !== JSON.stringify(actionList)) {
      const filteredActionList = actionList.filter((item) => {
        // Filter out objects that are empty
        if (Object.keys(item).length === 0) {
          return false;
        }

        // Filter out objects with empty string values
        for (const key in item) {
          if (item[key] === "") {
            return false;
          }
        }

        return true;
      });
      actualFormValuesToBeSet["post_call_to_action"] = JSON.stringify(filteredActionList);
    }

    if (isEditForm) {
      // console.log("In Update: ", actualFormValuesToBeSet);
      Object.keys(actualFormValuesToBeSet).length > 0 && updatePost({ ...actualFormValuesToBeSet, id: JSON.parse(localStorage.getItem("editPost"))?.id || "" });
    } else {
      console.log({ featureList, actionList });
      console.log(actualFormValuesToBeSet);
      addProduct(actualFormValuesToBeSet);
    }
  };

  const handleLocationChange = (location, setFieldValue) => {
    let { address_components, formatted_address } = location;
    console.warn(location);
    if (formatted_address) {
      setFieldValue("address", formatted_address);
    }
    if (address_components && address_components.length) {
      address_components.map((address, index) => {
        if (address_components[index].types.includes("sublocality")) {
          setFieldValue("city", location && location.address_components[index].long_name);
        }
        if (address_components[index].types.includes("locality")) {
          setFieldValue("city", location && location.address_components[index].long_name);
        }
        if (address_components[index].types.includes("administrative_area_level_1")) {
          setFieldValue("state", location && location.address_components[index].short_name);
        }
        if (address_components[index].types.includes("country")) {
          setFieldValue("country", location && location.address_components[index].long_name);
        }
        if (address_components[index].types.includes("postal_code")) {
          setFieldValue("zipcode", location && location.address_components[index].long_name);
        }
      });
    }

    setFieldValue("latitude", location?.geometry?.location?.lat());
    setFieldValue("longitude", location?.geometry?.location?.lng());
  };

  const handleMediaChange = (event) => {
    const files = Array.from(event.target.files);

    const filesToBeUploaded = [];

    files.forEach((element) => {
      if (element.type.startsWith("image/") && Math.round(element.size / 1000000) > 10) {
        toast(element.name + "Image size should not be greater than 10MB", {
          ...config.toast_config,
          type: "error",
        });
      } else if (element.type.startsWith("video/") && Math.round(element.size / 1000000) > 100) {
        toast(element.name + "Video size should not be greater than 100MB", {
          ...config.toast_config,
          type: "error",
        });
      } else {
        filesToBeUploaded.push(element);
      }
    });

    // console.log(filesToBeUploaded);
    setMediaArray([...mediaArray, ...filesToBeUploaded]);

    const promises = filesToBeUploaded.map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener("load", (ev) => {
          resolve(ev.target.result);
        });
        reader.addEventListener("error", reject);
        reader.readAsDataURL(file);
      });
    });

    Promise.all(promises).then(
      (images) => {
        let updateImagesObjects = [];
        images.forEach((img, index) => {
          updateImagesObjects.push({ media_name: img, type: img.includes("data:image") ? 1 : 2 });
        });

        setImageArray([...imageArray, ...updateImagesObjects]);
      },
      (error) => {
        console.error(error);
      }
    );
  };

  useEffect(() => {
    if (isSuccess && products.status === 200) {
      toast(products.message, {
        ...config.toast_config,
        type: "success",
      });
      navigate("/place");
    }
    if (isSuccess && products.status === 422) {
      toast(products.message, {
        ...config.toast_config,
        type: "error",
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (deletePostMediaIsSuccess) {
      if (deletePostMediaData.status === 200) {
        toast(deletePostMediaData.message, {
          ...config.toast_config,
          type: "success",
        });
      } else {
        toast(deletePostMediaData.message, {
          ...config.toast_config,
          type: "error",
        });
      }
    }
  }, [deletePostMediaIsSuccess]);

  useEffect(() => {
    if (updateProductIsSuccess) {
      if (updateProductData.status === 200) {
        setIsEditForm(false);
        localStorage.removeItem("editPost");
        navigate("/place");
        toast(updateProductData.message, {
          ...config.toast_config,
          type: "success",
        });
      } else {
        toast(updateProductData.message, {
          ...config.toast_config,
          type: "error",
        });
      }
    }
  }, [updateProductIsSuccess]);

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on("waiting", () => {
      videojs.log("player is waiting");
    });
    player.on("play", (play) => {
      player.pauseOtherPlayers = true;
      // console.log(":: play :: ", play);
      var medias = Array.prototype.slice.apply(document.querySelectorAll("audio,video"));
      medias.forEach(function (media) {
        media.addEventListener("play", function (event) {
          medias.forEach(function (media) {
            if (event.target != media) media.pause();
          });
        });
      });
    });
    // player.on("mouseenter", () => {
    //   videojs.log("player is waiting enter", player);
    //   player.bigPlayButton.handleClick();
    // });
    // player.on("mouseleave", function () {
    //   videojs.log("player is waiting leave", player);
    //   player.pause();
    //   player.bigPlayButton.show();
    // });
    player.on("dispose", () => {
      videojs.log("player will dispose");
    });
  };

  const ThruHikeConciergeSchema = Yup.object().shape(
    {
      post_name: Yup.string().required("Post name is required."),
      category_id: Yup.string().required("Category is required."),
      address: Yup.string(),
      city: Yup.string(),
      state: Yup.string(),
      country: Yup.string().required("Location is required."),
      zipcode: Yup.string(),
      latitude: Yup.string(),
      longitude: Yup.string(),
      updates: Yup.string(),
      description: Yup.string().required("Description is required."),
      tags: Yup.array()
        .min(1, "Min one tag is required.")
        .of(Yup.object().shape({ id: Yup.string().required(), text: Yup.string().required() })),
      vendor_website: Yup.string().required("Vendor Website is required."),
      vendor_contact: Yup.string().required("Vendor Contact is required."),
      call_to_action: Yup.number().required("Call to action selection is required"),
      button_name: Yup.string().when(["call_to_action"], {
        is: (call_to_action) => {
          return call_to_action === 2;
        },
        then: (schema) => schema.required("Button name is required."),
      }),

      website: Yup.string().when(["call_to_action"], {
        is: (call_to_action) => {
          return call_to_action === 2;
        },
        then: (schema) => schema.required("Website is required."),
      }),
    }["call_to_action"]
  );

  if (isLoading || updateProductIsLoading || isDetailsLoading) return <Loader />;
  return (
    <Formik
      initialValues={{
        post_name: JSON.parse(localStorage.getItem("editPost"))?.post_name || "",
        category_id: JSON.parse(localStorage.getItem("editPost"))?.category_id || null,
        address: JSON.parse(localStorage.getItem("editPost"))?.address || "",
        city: JSON.parse(localStorage.getItem("editPost"))?.city || "",
        state: JSON.parse(localStorage.getItem("editPost"))?.state || "",
        country: JSON.parse(localStorage.getItem("editPost"))?.country || "",
        latitude: JSON.parse(localStorage.getItem("editPost"))?.latitude || "",
        longitude: JSON.parse(localStorage.getItem("editPost"))?.longitude || "",
        updates: JSON.parse(localStorage.getItem("editPost"))?.updates || "",
        call_to_action: JSON.parse(localStorage.getItem("editPost"))?.call_to_action || 1,
        tags:
          JSON.parse(localStorage.getItem("editPost"))?.tags?.map((tag) => {
            return {
              id: tag.tag_name,
              text: tag.tag_name,
            };
          }) || [],
        vendor_contact: JSON.parse(localStorage.getItem("editPost"))?.vendor_contact || "",
        vendor_website: JSON.parse(localStorage.getItem("editPost"))?.vendor_website || "",
        description: JSON.parse(localStorage.getItem("editPost"))?.description || "",
        // button_name: JSON.parse(localStorage.getItem("editPost"))?.button_name || "",
        // website: JSON.parse(localStorage.getItem("editPost"))?.website || "",
      }}
      // validationSchema={ThruHikeConciergeSchema}
      onSubmit={(values) => {
        if (mediaError === "") {
          onSubmit(values);
        }
      }}
    >
      {({ errors, touched, handleSubmit, values, handleChange, isInitialValid, isValid, setFieldValue, submitCount }) => (
        <>
          {/* // <!-- start trail post --> */}
          <h4 className="heading_title big_title">Add Product/Place</h4>

          {/* {// console.log("values: ", values)}
          {// console.log("touched: ", touched, errors)}
          {Object.keys(errors)
            .filter((e) => touched[e])
            .map((errKey) => (
              <div key={errKey} className="col-12 text-danger">
                {errors[errKey]}
              </div>
            ))}
          {errors["country"] && touched.address && <div className="col-12 text-danger">{errors["country"]}</div>} */}

          <form className="form_field" encType="multipart/form-data">
            <div className="mb-4">
              <h4 className="heading_title">Details</h4>
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6 col-12 mb-3">
                  <label className="mb-1">Product/Place Name</label>
                  <input type="text" className="form-control" placeholder="Name" name="post_name" onChange={handleChange} value={values.post_name} />
                  {errors["post_name"] && touched.post_name && <div className="col-12 text-danger">{errors["post_name"]}</div>}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6 col-12 mb-3">
                  <label className="mb-1">Select Category</label>
                  <select className="form-control" name="category_id" onChange={handleChange} value={values.category_id}>
                    <option value={null}>Select Category</option>
                    {categories?.data && categories?.data?.length ? (
                      categories.data.map((category) => (
                        <option value={category.id} key={"category_" + category.id}>
                          {category.category_name}
                        </option>
                      ))
                    ) : (
                      <option value={null}>No Category Found.</option>
                    )}
                  </select>
                  {errors["category_id"] && touched.category_id && <div className="col-12 text-danger">{errors["category_id"]}</div>}
                </div>

                <div className="col-lg-12 col-md-12 col-sm-12 col-12 mb-3 location_icon">
                  <label className="mb-1">Location</label>
                  <ReactGoogleAutocomplete
                    apiKey={config.google_api_key}
                    options={{
                      types: ["establishment"],
                    }}
                    onPlaceSelected={(place) => handleLocationChange(place, setFieldValue)}
                    className="form-control"
                    defaultValue={isEditForm ? values?.address + " " + values?.city + " " + values?.state + " " + values?.country : ""}
                  />
                  <img src="images/location.png" alt="" title="" />
                  {errors["country"] && touched.state && touched.city && touched.address && <div className="col-12 text-danger">{errors["country"]}</div>}
                </div>
              </div>
            </div>

            <div className="mb-4">
              <h4 className="heading_title">Updates</h4>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                  <label className="mb-1">Update</label>
                  <textarea className="form-control" rows="2" placeholder="write" name="updates" value={values.updates} onChange={handleChange}></textarea>
                </div>
              </div>
            </div>

            <div className="mb-4">
              <h4 className="heading_title">Description</h4>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                  <label className="mb-1">Your #tag</label>
                  <ReactTags
                    classNames={{
                      tags: "tagsClass",
                      tagInput: "tagInputClass",
                      tagInputField: "form-control",
                      selected: "selectedClass",
                      tag: "tagClass",
                      remove: "removeClass",
                      suggestions: "suggestionsClass",
                      activeSuggestion: "activeSuggestionClass",
                      editTagInput: "editTagInputClass",
                      editTagInputField: "editTagInputField",
                      clearAll: "clearAllClass",
                    }}
                    tags={values.tags}
                    suggestions={suggestions}
                    delimiters={delimiters}
                    handleDelete={(i) => {
                      setFieldValue(
                        "tags",
                        values.tags.filter((tag, index) => index !== i)
                      );
                    }}
                    handleAddition={(tag) => {
                      // console.log(tag);
                      setFieldValue("tags", [...values.tags, tag]);
                    }}
                    handleDrag={(tag, currPos, newPos) => {
                      const newTags = values.tags.slice();

                      newTags.splice(currPos, 1);
                      newTags.splice(newPos, 0, tag);

                      // re-render
                      setFieldValue("tags", newTags);
                    }}
                    handleTagClick={handleTagClick}
                    inputFieldPosition="bottom"
                    autocomplete
                    allowUnique
                  />
                  {errors["tags"] && touched.tags && <div className="col-12 text-danger">{errors["tags"]}</div>}
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                  <label className="mb-1">Description</label>
                  <textarea className="form-control" rows="2" placeholder="write" name="description" value={values.description} onChange={handleChange}></textarea>
                  {errors["description"] && touched.description && <div className="col-12 text-danger">{errors["description"]}</div>}
                </div>
              </div>
            </div>

            <div className="mb-4">
              <h4 className="heading_title">Featured on</h4>
              <div className="row">
                {/* { console.log("featureList: ", featureList)} */}
                <DynamicFeatureFields featureList={featureList} setFeatureList={setFeatureList} />
              </div>
            </div>

            <div className="mb-4">
              <h4 className="heading_title">Vendor</h4>
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6 col-6 mb-3">
                  <label className="mb-1">Vendor Website</label>
                  <input className="form-control" placeholder="Vendor Website" name="vendor_website" onChange={handleChange} value={values.vendor_website} />
                  {errors["vendor_website"] && touched.vendor_website && <div className="col-12 text-danger">{errors["vendor_website"]}</div>}
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-6 mb-3">
                  <label className="mb-1">Vendor Contact</label>
                  <input className="form-control" placeholder="Vendor Contact" name="vendor_contact" onChange={handleChange} value={values.vendor_contact} />
                  {errors["vendor_contact"] && touched.vendor_contact && <div className="col-12 text-danger">{errors["vendor_contact"]}</div>}
                </div>
              </div>
            </div>
            <div className="mb-4">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                  <label className="mb-1">Call to action</label>
                  <div className="d-flex justify-between">
                    <div className="form-check" style={{ marginRight: "20px" }}>
                      <input className="form-check-input" type="radio" name="call_to_action" value={1} onChange={() => setFieldValue("call_to_action", 1)} checked={values.call_to_action === 1} />
                      <label className="form-check-label" htmlFor="flexRadioDefault1">
                        No
                      </label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="call_to_action" value={2} onChange={() => setFieldValue("call_to_action", 2)} checked={values.call_to_action === 2} />
                      <label className="form-check-label" htmlFor="flexRadioDefault2">
                        Yes
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {values.call_to_action === 2 ? (
                // <div className="row">
                //   <div className="col-lg-6 col-md-6 col-sm-6 col-6 mb-3">
                //     <label className="mb-1">Button Name</label>
                //     <div className="d-flex justify-between">
                //       <input className="form-control" type="text" name="button_name" onChange={handleChange} value={values.button_name} />
                //     </div>
                //     {errors["button_name"] && touched.button_name && <div className="col-12 text-danger">{errors["button_name"]}</div>}
                //   </div>
                //   <div className="col-lg-6 col-md-6 col-sm-6 col-6 mb-3">
                //     <label className="mb-1">Website</label>
                //     <div className="d-flex justify-between">
                //       <input className="form-control" type="text" name="website" onChange={handleChange} value={values.website} />
                //     </div>
                //     {errors["website"] && touched.website && <div className="col-12 text-danger">{errors["website"]}</div>}
                //   </div>
                // </div>
                <DynamicFeatureFields featureList={actionList} setFeatureList={setActionList} action={true} hideMultiple={false} />
              ) : (
                ""
              )}
            </div>

            <div className="mb-4">
              <h4 className="heading_title">Add Videos and Photos</h4>
              <div className="row">
                <p>Max 10 MB for one photo and 100 MB for one video. Please upload media in landscape mode.</p>
                <div className="col-12">
                  <input type="file" className="form-control" name="media_name[]" onChange={(e) => handleMediaChange(e)} multiple accept="image/*,video/*" minLength={2} required />
                </div>
                {submitCount > 0 && mediaError !== "" ? <div className="col-12 text-danger">{mediaError}</div> : ""}
              </div>
              <div className="row my-4">
                <div className="col-12">
                  {imageArray &&
                    imageArray.length > 0 &&
                    imageArray.map((item, index) =>
                      item.type === 1 ? (
                        <div className="addvideo_photo position-relative" key={index}>
                          <img src={item.type ? item.media_name : item} alt="" title="" />
                          <Link
                            to="#"
                            onClick={() => {
                              if (window.confirm("Are you sure you want to delete this place media?")) {
                                if (item.id) {
                                  deletePostMedia(item.media_name);
                                  mediaArray.splice(index, 1);
                                  setMediaArray([...mediaArray]);
                                }
                                mediaArray.splice(index, 1);
                                setMediaArray([...mediaArray]);

                                imageArray.splice(index, 1);
                                setImageArray([...imageArray]);
                              } else {
                                // do not delete the item
                              }
                            }}
                          >
                            <img src={require("../../assets/images/delete.png")} alt="" title="" className="delete_videoicon" />
                          </Link>
                        </div>
                      ) : (
                        <div className="addvideo_photo position-relative h-auto w-auto" style={{ marginBottom: "-10px" }} key={index}>
                          <Video
                            key={"media_post_" + index}
                            options={{
                              preload: "auto",
                              autoplay: false,
                              controls: true,
                              responsive: true,
                              fluid: true,
                              sources: [
                                {
                                  src: `${item?.media_name}`,
                                  type: item?.id ? "application/x-mpegURL" : "video/mp4",
                                },
                              ],
                              // poster: `${media.thumbnail_img}`,
                            }}
                            onReady={handlePlayerReady}
                            classNameProp="custom-video-tag"
                          />
                          {/* <img src={item.type ? item.media_name : item} alt="" title="" /> */}
                          <Link
                            to="#"
                            onClick={() => {
                              if (window.confirm("Are you sure you want to delete this place media?")) {
                                if (item.id) {
                                  deletePostMedia(item.media_name);
                                } else {
                                  mediaArray.splice(index, 1);
                                  setMediaArray([...mediaArray]);
                                }
                                imageArray.splice(index, 1);
                                setImageArray([...imageArray]);
                              } else {
                                // do not delete the item
                              }
                            }}
                          >
                            <img src={require("../../assets/images/delete.png")} alt="" title="" className="delete_videoicon" />
                          </Link>
                        </div>
                      )
                    )}
                </div>
              </div>
            </div>
            <hr />

            <div className="text-end">
              <button
                className="btn_cancel"
                onClick={() => {
                  navigate("/place");
                  localStorage.removeItem("editPost");
                }}
              >
                Cancel
              </button>
              <button type="button" className="btn_save" onClick={(e) => handleSubmit(values)}>
                Save
              </button>
            </div>
          </form>
          {/* <!-- end trail post -->	 */}
        </>
      )}
    </Formik>
  );
};
