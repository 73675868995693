import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "reactstrap";
import { logInModal, resetPasswordModal } from "../../redux/modalSlice";
import * as Yup from "yup";
import { Formik } from "formik";
import { config } from "../../config";
import { useResetPasswordMutation } from "../../API/authAPI";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { Loader } from "../../components/Loader";

const ResetPassword = () => {
  const dispatch = useDispatch();

  const [newPasswordType, setNewPasswordType] = useState(true);
  const [newConfirmPasswordType, setNewConfirmPasswordType] = useState(true);

  const resetPasswordState = useSelector((state) => state.modal.resetPasswordModal);
  const userEmail = useSelector((state) => state.modal.email);

  const [resetPassword, { data: user, isLoading, error, isError, isSuccess }] = useResetPasswordMutation();

  const initialVlues = {
    email: "",
    password: "",
    confirm_password: "",
  };

  const ResetPasswordSchema = Yup.object().shape(
    {
      password: Yup.string().matches(config.passwordRegex, "Password should be 8 characters long and contain at least 1 capital letter, 1 lowercase letter, 1 number and 1 special character.").required("Password is required."),
      confirm_password: Yup.string()
        .matches(config.passwordRegex, "Password should be 8 characters long and contain at least 1 capital letter, 1 lowercase letter, 1 number and 1 special character.")
        .required("Confirm Password is required.")
        .oneOf([Yup.ref("password")], "Password and confirm password must match"),
    },
    [["password"]]
  );

  const onSubmit = (values) => {
    console.log("userEmail ::", { userEmail, values });
    values["email"] = userEmail;
    console.log("values ::", values);
    resetPassword(values);
  };

  useEffect(() => {
    if (isSuccess && user.status === 200) {
      localStorage.clear();
      dispatch(resetPasswordModal({ modal: false }));
      toast(user.message, {
        ...config.toast_config,
        type: "success",
      });
      dispatch(logInModal(true));
    } else if (isSuccess && user.status === 422) {
      toast(user.message, {
        ...config.toast_config,
        type: "error",
      });
    }
  }, [isSuccess]);

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <Modal isOpen={resetPasswordState} centered={true} contentClassName="login_form">
        <div className="modal-header">
          <h1 className="modal-title text-center m-auto" id="exampleModalLabel">
            <img src={require("../../assets/images/favicon.png")} alt="" title="" />
            <p className="model_title_header">Reset Password</p>
          </h1>
          <button type="button" className="btn-close" onClick={() => dispatch(resetPasswordModal({ modal: false }))} aria-label="Close">
            <i className="fas fa-times"></i>
          </button>
        </div>
        <div className="modal-body">
          <Formik
            initialValues={initialVlues}
            validationSchema={ResetPasswordSchema}
            onSubmit={(values) => {
              onSubmit(values);
            }}
          >
            {({ errors, touched, handleSubmit, values, handleChange, isInitialValid, isValid }) => (
              <div className="form_field">
                <div className="mb-3">
                  <label className="mb-1">New Password</label>
                  <div className="position-relative">
                    <input id="newpassword" type={newPasswordType ? "password" : "text"} className="form-control pe-5" name="password" placeholder="*********" onChange={handleChange} />
                    <span toggle="#newpassword" className={newPasswordType ? "toggle_password " : "toggle_password slash_icon"} onClick={() => setNewPasswordType(!newPasswordType)}>
                      <img src={require("../../assets/images/eye.png")} alt="" title="" />
                    </span>
                    {errors.password && touched.password && <div className="mb-3 text-danger w-100">{errors.password}</div>}
                  </div>
                </div>
                <div className="mb-3">
                  <label className="mb-1">Confirm Password</label>
                  <div className="position-relative">
                    <input id="confirmpassword" type={newConfirmPasswordType ? "password" : "text"} className="form-control pe-5" name="confirm_password" placeholder="*********" onChange={handleChange} />
                    <span toggle="#confirmpassword" className={newConfirmPasswordType ? "toggle_password " : "toggle_password slash_icon"} onClick={() => setNewConfirmPasswordType(!newConfirmPasswordType)}>
                      <img src={require("../../assets/images/eye.png")} alt="" title="" />
                    </span>
                    {errors.confirm_password && touched.confirm_password && <div className="mb-3 text-danger w-100">{errors.confirm_password}</div>}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 my-4">
                    <button type="submit" className="btn btn-primary save" onClick={(e) => handleSubmit(values)} disabled={!isValid}>
                      Save
                    </button>
                  </div>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </Modal>
    );
  }
};

export default ResetPassword;
