import React from "react";
import { Link } from "react-router-dom";
import { AES } from "crypto-js";
import { config } from "../config";

export const TrailListCard = ({ data, classList, displayHeart, link, clickFunction }) => {
  return (
    <>
      {data && data.length
        ? data.map((data, index) => (
            <div className={classList} key={"TrailListCard" + data?.id}>
              <Link to={link ? `/trail-details/${data?.slug}` : "#"} onClick={!link ? () => clickFunction(data?.id) : () => {}}>
                <div className="hz_traillist">
                  <div className="trail_mainimg">
                    {data && data.images && data.images.length
                      ? data.images.map((image, i) => (
                          <div className={data?.images?.length === 1 ? "single_img" : "multiple_img"} key={"trail-image" + i}>
                            <img src={image} alt="trail-image" title={data?.trail_name} />
                          </div>
                        ))
                      : ""}
                  </div>
                  <h5>
                    {data?.trail_name}
                    {displayHeart && data.id === 0 ? <i className="fas fa-heart"></i> : ""}
                  </h5>
                </div>
              </Link>
            </div>
          ))
        : ""}
    </>
  );
};
