import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useGetMyTrailGroupQuery } from "../../API/trailAPI";
import { Loader } from "../../components/Loader";
import { TrailGroupCard } from "../../components/TrailGroupCard";
import { logInModal } from "../../redux/modalSlice";
import { AES } from "crypto-js";
import { config } from "../../config";

export const TrailView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const locationData = useSelector((state) => state.modal.location);

  const token = localStorage.getItem("token") ? localStorage.getItem("token") : undefined;
  // const searchText = useSelector((state) => state.modal.searchText);
  const [searchParams, setSearchParams] = useSearchParams();
  const searchText = searchParams.get("query") || "";
  const { data: trialGroups, isLoading, isSuccess, isError, error } = useGetMyTrailGroupQuery({ searchKey: searchText !== "" ? searchText : null, location: locationData });

  // if (token === undefined) {
  //   dispatch(logInModal(true));
  // }
  useEffect(() => {
    setLoading(true);
  }, [searchText]);

  useEffect(() => {
    console.log("trialGroups?.data :: ", trialGroups);
    setLoading(false);
  }, [trialGroups]);

  // useEffect(() => {
  //   const searchInputElement = document.getElementById("searchInput");
  //   // console.log(document.getElementById("searchInput"));
  //   searchInputElement.addEventListener("keyup", (e) => {
  //     if (e.key === "Enter" || e.keyCode === 13) {
  //       // Do something
  //       navigate("/trail");
  //     }
  //   });
  // }, []);
  // console.log("AES.encrypt(trail?.id, config.passphrase).toString() :: ", AES.encrypt(trialGroups.data[0].id, config.passphrase).toString());
  if (isLoading || loading) {
    return <Loader />;
  } else {
    return (
      <>
        {searchText === "" && isSuccess && trialGroups?.data.length ? (
          trialGroups.data.map((trail) => (
            /* <!-- start Your Trails & Favorites --> */
            <div key={"trail_group_" + trail?.id}>
              <h4 className="heading_title">
                {trail?.group_name}
                <Link to={trail?.id || trail?.id === 0 ? `/trailandfavourite/${encodeURIComponent(AES.encrypt(trail?.id.toString(), config.passphrase).toString())}` : "#"}>View All</Link>
              </h4>
              <div className="trailslider trail_detail_list">
                <div className="row">
                  <TrailGroupCard data={trail?.trail_bunch} isId={true} />
                </div>
              </div>
            </div>
            /* <!-- start Your Trails & Favorites --> */
          ))
        ) : searchText !== "" && trialGroups?.data && trialGroups?.data.length ? (
          <>
            <div className="trailslider trail_detail_list">
              <div className="row">
                <TrailGroupCard data={trialGroups?.data} isId={false} />
              </div>
            </div>
          </>
        ) : (
          <div className="d-flex justify-content-between items-center ">
            <h3>{token === undefined ? "Please sign up or login." : "No Trail Lists Found. Create a Trail List."}</h3>
          </div>
        )}
      </>
    );
  }
};
