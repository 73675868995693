import React from "react";

export const AboutUs = () => {
  return (
    <div className="container about_us_content">
      <div className="row">
        <div className="col-12 col-lg-6 col-md-12 col-sm-12">
          <div className="d-flex flex-column h-100">
            <div>
              <h1>Our Mission</h1>
              <p><strong>Thruhike is the ultimate platform for discovering and sharing your favorite places and products. Our mission is to celebrate local business and inspire new experiences.</strong></p>
            </div>
            <div>
              <p style={{ fontSize: "18px", lineHeight: "24px", fontWeight: "500" }}>We work with vendors across global communities to build their companies while ensuring our team curates the right selection of places and products for our users. Thruhike brings the concierge experience to everyone.</p>
            </div>
          </div>
        </div>
        <div className="col-lg-2"></div>
        {/* <div className="col-12 col-lg-4 col-md-12 col-sm-12 position-relative">
          <div className="text-lg-start text-md-center text-sm-center text-center">
            <img src={require("../../assets/images/about-2.png")} className="img-fluid img_position1" alt="thruhike-about" title="thruhike" />
          </div>
          <div className="text-lg-end text-md-center text-sm-center text-center"> 
            <img src={require("../../assets/images/about-1.png")} className="img-fluid img_position2" alt="thruhike-about" title="thruhike" />
          </div>
        </div> */}
      </div>
      {/* <div className="row">
        <div className="col-12">
          <img src={require("../../assets/images/about-2.png")} className="img-fluid my-4" alt="thruhike-about" title="thruhike" />
        </div>
      </div> */}
    </div>
  );
};
