import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logInModal } from "../redux/modalSlice";
import { AES } from "crypto-js";
import { config } from "../config";

const Slider = (props) => {
  const nav = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");

  const handleClick = (id) => {
    if (token !== null && token !== "" && token !== undefined) {
      if (id !== undefined) {
        // console.log("id ::", id);
        nav(`/trail-details/${id}`);
        // console.log("id ::", id);
      }
    } else {
      dispatch(logInModal(true));
    }
  };
  return (
    <OwlCarousel
      className="owl-theme trailslider"
      loop={false}
      nav={false}
      dots={true}
      responsive={{
        0: {
          items: 1,
        },
        480: {
          items: 2,
        },
        768: {
          items: 1,
        },
        1152: {
          items: 2,
        },
      }}
      navText={['<span className="arrow prev">‹</span>', '<span className="arrow next">›</span>']}
    >
      {props.items && props.items.length
        ? props.items.map((item, index) =>
            index % 2 === 0 ? (
              <div className="item" key={"slider_" + index + item?.id}>
                <a onClick={() => handleClick(item?.slug)}>
                  <div className="hz_traillist">
                    <div className="trail_mainimg">
                      {item && item?.images && item?.images.length
                        ? item?.images.map((img, i) => (
                            <div className={item?.images?.length === 1 ? "single_img" : "multiple_img"} key={"suggessted-image" + index + i}>
                              <img src={img} alt="trail-slide-image" title={img} />
                            </div>
                          ))
                        : ""}
                    </div>
                    <h5>{item?.trail_name}</h5>
                  </div>
                </a>
                <a onClick={() => handleClick(props.items[index + 1]?.slug)}>
                  <div className="hz_traillist">
                    <div className="trail_mainimg">
                      {props.items && props.items[index + 1]?.images && props.items[index + 1]?.images.length
                        ? props.items[index + 1]?.images.map((img, i) => (
                            <div className={props.items[index + 1]?.images?.length === 1 ? "single_img" : "multiple_img"} key={"suggessted-image" + index + i}>
                              <img src={img} alt="trail-slide-image" title={img} />
                            </div>
                          ))
                        : ""}
                    </div>
                    <h5>{props.items[index + 1]?.trail_name}</h5>
                  </div>
                </a>
              </div>
            ) : (
              ""
            )
          )
        : ""}
    </OwlCarousel>
  );
};

export default Slider;
