import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { postApi, useGetPostsQuery } from "../API/postAPI";
import Card from "../components/Card";
import { Loader } from "../components/Loader";
import { TrailRow } from "../components/TrailRow";
import Slider from "../components/slider";
import { useGetSuggestedTrailsQuery } from "../API/trailAPI";
import { introSliderModal, logInModal, verifyEmailModal } from "../redux/modalSlice";
import { useGetBannerQuery } from "../API/authAPI";
import CustomGoogleMap from "../components/map/CustomGoogleMap";

export const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { key } = useLocation();
  const currentPath = useLocation();

  const [isMap, setIsMap] = useState(false);

  const [showFirstTimeBanner, setShowFirstTimeBanner] = useState(localStorage.getItem("showFirstTimeBanner"));
  const [cursor, setCursor] = useState("/all-posts?");
  // const searchText = useSelector((state) => state.modal.searchText);
  const [searchParams, setSearchParams] = useSearchParams();
  const searchText = searchParams.get("query");
  const location = useSelector((state) => state.modal.location);
  const [allPosts, setAllPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [bannerDescription, setBannerDescription] = useState("");

  const { data: posts, isLoading, isSuccess, isError, error, isFetching, refetch } = useGetPostsQuery({ cursor, searchText, location, key });
  const { data: banner, isLoading: bannerIsLoading, isSuccess: bannerIsSuccess, isError: bannerIsError, error: bannerError } = useGetBannerQuery();
  const { data: suggestedTrails, isLoading: suggestedTrailsLoading, isSuccess: suggestedTrailsIsSuccess, isError: suggestedTrailsIsError, error: suggestedTrailsError } = useGetSuggestedTrailsQuery({ searchKey: "", location: location });
  const token = localStorage.getItem("token");
  const [isFromMap, setIsFromMap] = React.useState(false);

  useEffect(() => {
    const onScroll = () => {
      const scrolledToBottom = window.innerHeight + window.scrollY >= document.body.offsetHeight;
      // // console.log("scroll to bottom condition ::", scrolledToBottom);
      if (scrolledToBottom && !isFetching) {
        // console.log("Fetching more data...");
        if (posts?.data.next_page_url !== "") {
          // console.log("cursor :: ", posts?.data.next_page_url);
          setCursor(posts?.data.next_page_url + "&");
        }
      }
    };

    if (isFromMap) {
      document.removeEventListener("scroll", onScroll);
    } else {
      document.addEventListener("scroll", onScroll);
    }

    return function () {
      document.removeEventListener("scroll", onScroll);
    };
  }, [cursor, isFetching, isFromMap]);

  useEffect(() => {
    return () => {
      setIsFromMap(false);
    };
  }, []);

  const [isKeyChanged, setIsKeyChanged] = useState(false);

  useEffect(() => {
    setAPIResponse([]);
    setCursor("/all-posts?");
    setIsKeyChanged(true);
    setIsMap(false);
    setIsFromMap(false);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [key]);

  useEffect(() => {
    // console.log("all post cleared called :: start");
    setAllPosts([]);
    // console.log("all post cleared called :: end", allPosts);
    setLoading(true);
    setCursor("/all-posts?");
  }, [location, searchText]);

  useEffect(() => {
    let tmpPost = [...allPosts];
    // console.log("tmpPost :: ", tmpPost);
    console.log("posts :: ", posts);

    if (posts && posts.data.data && posts.data.data.length > 0) {
      // console.log("cursor :: ", cursor);
      if (isKeyChanged) {
        tmpPost = [...posts.data.data];
        setIsKeyChanged(false);
      } else {
        tmpPost.push(...posts.data.data);
      }
      // console.log("tmpPost :: ", tmpPost);
    }
    setAllPosts(tmpPost);
    setLoading(false);
  }, [posts]);
  // console.log("isLoding ::", isLoading);

  useEffect(() => {
    if (banner && banner.data) {
      setBannerDescription(banner.data[0]);
      // dispatch(introSliderModal(true));
    }
  }, [bannerIsSuccess]);

  const [apiResponse, setAPIResponse] = React.useState([]);

  const onMapListConvert = () => {
    if (isMap) {
      setIsFromMap(true);
      setIsMap(false);
      setAllPosts([...apiResponse]);
    } else {
      setAPIResponse([]);
      setIsMap(true);
      setIsFromMap(false);
    }
  };

  if (isLoading || loading) {
    return <Loader />;
  } else {
    return (
      <>
        {/* ============= start below first time banner ===================== */}
        <div className={showFirstTimeBanner ? "trail_post cursor-pointer h-auto" : "d-none"}>
          <div className="row">
            <div className="col-12 trail_heading_middle">
              <h4 className="heading_title d-flex justify-content-between mb-0">
                <span style={{ color: "#008b2a" }}>Welcome To Thruhike</span>
                <div
                  className="d-flex"
                  onClick={() => {
                    localStorage.removeItem("showFirstTimeBanner");
                    setShowFirstTimeBanner(false);
                  }}
                >
                  <i className="fas fa-times"></i>
                </div>
              </h4>
              <p className="traildesc mt-2">{bannerDescription}</p>
            </div>
          </div>
        </div>
        {/* ============= end below first time banner ===================== */}

        {/* <!-- start below header section --> */}
        {token !== null && !isMap && <TrailRow location={location} />}
        {/* <!-- end below header section --> */}
        {/* <!-- start trail post --> */}
        {!isMap && (
          <div className="row">
            {/* <!-- start left side post area --> */}
            <div className="col-lg-8 col-md-7 col-sm-12">
              <div className="row">
                {!isLoading && allPosts.length > 0
                  ? allPosts.map((post, index) => (
                      // ) : posts?.data?.data.length > 0 ? (
                      //   posts?.data?.data.map((post) => (
                      <div className="col-lg-6 col-md-12 col-sm-6 trail_width_responsive" key={`post-${post.id}-${index}`}>
                        <Card post={post} showTags={false} />
                      </div>
                    ))
                  : ""}
              </div>
            </div>
            {/* <!-- end left side post area --> */}
            {/* <!-- start right side Suggested Trail List --> */}
            {(!isLoading || !isFetching) && allPosts.length === 0 ? (
              <>
                <div className="d-flex justify-content-between items-center ">
                  {/* <h3>Don’t see one of your favorite places or products? Add it to our platform here.</h3> */}
                  <h3>No results found. Don’t see one of your favorite places or products? Add it to our platform via the button below.</h3>
                </div>
                <div className="trail_btn position_fixed">
                  <div className="container">
                    {/* to="/addplace" */}
                    <Link to={token !== null ? "/addplace" : "/"} className="add_trail" onClick={() => (token !== null ? navigate("/addplace") : dispatch(logInModal(true)))}>
                      <i className="fas fa-plus"></i> Add Product/Place
                    </Link>
                  </div>
                </div>
              </>
            ) : (
              <div className="col-lg-4 col-md-5 col-sm-12 rightside_trail position-relative">
                <div className="inside_righttrail">
                  <h4 className="heading_title">Suggested Trail Lists</h4>
                  <Slider items={suggestedTrails?.data?.data} />
                </div>
              </div>
            )}
            {/* <!-- end right side Suggested Trail List --> */}
          </div>
        )}

        {isMap && <CustomGoogleMap setAPIResponse={setAPIResponse} />}

        <div onClick={onMapListConvert} type="button" className="btn btn-primary position-fixed" style={{ top: "90%", left: "50%", backgroundColor: "#008b2a", borderColor: "#008b2a" }}>
          {isMap ? "Show List" : "Show Map"}
        </div>
      </>
    );
  }
};
