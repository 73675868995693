export const config = {
  // base_url: "http://3.18.24.146/api/v1/",
  base_url: "https://api.gothruhike.com/api/v1/",
  // base_url: "https://26fb-2409-4041-e8b-1495-10bb-b441-7879-986.in.ngrok.io/api/v1",
  google_api_key: "AIzaSyBqixzwzZ0QdI8svKd6pvvCiLrZu9p12K4",
  toast_config: {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  },
  emailRegex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  // passwordRegex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
  passwordRegex: /^(?=.*\d)(?=.*[A-Z]).{8,}$/,
  passphrase: "thruHikeReactApp1303",
};
