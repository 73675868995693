import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Modal } from "reactstrap";
import { thruhikeConciergeThanksModal } from "../../redux/modalSlice";

export const ThruhikeConciergeThanks = () => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const thruhikeConciergeThanksModalState = useSelector(
    (state) => state.modal.thruhikeConciergeThanksModal
  );
  return (
    // <div
    //   className="modal fade"
    //   id="ThruhikeConciergethankyou"
    //   tabindex="-1"
    //   aria-labelledby="exampleModalLabel"
    //   aria-hidden="true"
    // >
    //   <div className="modal-dialog modal-dialog-centered">
    //     <div className="modal-content login_form">
    <Modal
      isOpen={thruhikeConciergeThanksModalState}
      centered={true}
      contentClassName="login_form"
    >
      <div className="modal-header">
        <h1
          className="modal-title text-center w-100 mb-3"
          id="exampleModalLabel"
        >
          <img src="images/check.png" alt="" title="" />
          <p className="mt-3">Thank you for your request</p>
          <span className="text_popup">
            A Thruhike team member will reach out within 24-48 hours. Let's
            adventure!"
          </span>
        </h1>
        <button
          type="button"
          className="btn-close"
          //   data-bs-dismiss="modal"
          aria-label="Close"
          onClick={() => dispatch(thruhikeConciergeThanksModal(false))}
        >
          <i className="fas fa-times"></i>
        </button>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          // data-bs-dismiss="modal"
          aria-label="Close"
          className="btn btn-primary save"
          onClick={() => dispatch(thruhikeConciergeThanksModal(false))}
        >
          Ok
        </button>
      </div>
    </Modal>
    //     </div>
    //   </div>
    // </div>
  );
};
