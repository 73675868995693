import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { deleteHeaderModal, trailHeaderModal } from "../redux/modalSlice";

export const TrailPostHeader = ({ header, trailSlug, userHasAccess }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const openHeaderModal = (header) => {
    localStorage.setItem("editHeader", JSON.stringify(header));
    dispatch(trailHeaderModal({ trailHeaderModal: true, trailId: trailSlug }));
  };

  return (
    <>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="trail_title_header">
          <h4>{header?.heading_name}</h4>
          {userHasAccess ? (
            <div className="edit_delete_title">
              <Link onClick={() => openHeaderModal(header)}>
                <i className="fas fa-pen"></i>
              </Link>
              <Link onClick={() => dispatch(deleteHeaderModal({ modal: true, id: header?.heading_id }))}>
                <i className="fas fa-trash-alt"></i>
              </Link>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>

      {/* <div className="col-lg-12 col-md-12 col-sm-12 trail_width_responsive">{trail_header && trail_header?.heading_post && trail_header?.heading_post?.length ? trail_header?.heading_post.map((trail_post, index) => <TrailPostCard trail_post={trail_post} key={trail_post.slug + "_" + index} />) : ""}</div> */}
      {/* <div className="col-lg-12 col-md-12 col-sm-12 trail_width_responsive">{trail_header && trail_header?.heading_post && trail_header?.heading_post?.length ? <DraggableItem postHeaders={trail_header?.heading_post} /> : ""}</div> */}
    </>
  );
};
