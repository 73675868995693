import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Modal } from "reactstrap";
import { addCustomTrailThanksModal } from "../../redux/modalSlice";

export const CustomTrailThanks = () => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const addCustomTrailThanksModalState = useSelector(
    (state) => state.modal.addCustomTrailThanksModal
  );
  return (
    // <div
    //   className="modal fade"
    //   id="thankyou"
    //   tabindex="-1"
    //   aria-labelledby="exampleModalLabel"
    //   aria-hidden="true"
    // >
    //   <div className="modal-dialog modal-dialog-centered">
    //     <div className="modal-content login_form">
    <Modal
      isOpen={addCustomTrailThanksModalState}
      centered={true}
      contentClassName="login_form"
    >
      <div className="modal-header">
        <h1
          className="modal-title text-center w-100 mb-3"
          id="exampleModalLabel"
        >
          <img src="images/check.png" alt="" title="" />
          <p className="mt-3">Thank you for submitting. </p>
          <span className="text_popup">
            You will get a confirmation from a Thruhike team member within 24-48
            hours with an estimated completion time and any questions we may
            have. Let the adventure begin!
          </span>
        </h1>
        <button
          type="button"
          className="btn-close"
          //   data-bs-dismiss="modal"
          aria-label="Close"
          onClick={() => dispatch(addCustomTrailThanksModal(false))}
        >
          <i className="fas fa-times"></i>
        </button>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          //   data-bs-dismiss="modal"
          aria-label="Close"
          className="btn btn-primary save"
          onClick={() => dispatch(addCustomTrailThanksModal(false))}
        >
          Ok
        </button>
      </div>
    </Modal>
    //     </div>
    //   </div>
    // </div>
  );
};
