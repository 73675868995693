// // A mock function to mimic making an async request for data
// export function fetchCount(amount = 1) {
//     return new Promise((resolve) =>
//       setTimeout(() => resolve({ data: amount }), 500)
//     );
//   }

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../config";
export const postApi = createApi({
  reducerPath: "postApi",
  baseQuery: fetchBaseQuery({
    baseUrl: config.base_url,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      headers.set("Access-Control-Allow-Origin", "*");
      headers.set("Authorization", "Bearer " + token);
      return headers;
    },
  }),
  tagTypes: ["Post"],
  // global configuration for the api
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    getPosts: builder.query({
      query: (data) => {
        const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : "";

        // let query = "/all-posts?location=" + data.location.query;
        let query = data.cursor + "location=" + data.location;
        if (user) query += "&user_id=" + user.id;
        if (data.searchText && data.searchText !== "" && data.searchText !== undefined) {
          if (data.searchText.includes("#")) {
            return query + "&tag=" + data.searchText.substring(1, data.searchText.length);
          } else {
            return query + "&serach_key=" + data.searchText;
          }
        } else {
          return query;
        }
      },
    }),
    viewPost: builder.query({
      query: (id) => {
        const token = localStorage.getItem("token");
        return {
          url: token && token !== "" && token !== null ? "view-post?slug=" + id : "view-details?slug=" + id,
        };
      },
    }),
    updatePost: builder.mutation({
      query: (userData) => {
        // console.log("updatePost :: userData ::", userData);
        let formData = new FormData();
        if (userData.id) {
          formData.append("post_id", userData.id);
        }
        userData.post_name && formData.append("post_name", userData.post_name ? userData.post_name : "");
        userData.category_id && formData.append("category_id", userData.category_id ? userData.category_id : "");
        userData.address && formData.append("address", userData.address ? userData.address : "");
        userData.city && formData.append("city", userData.city ? userData.city : "");
        userData.state && formData.append("state", userData.state ? userData.state : "");
        userData.country && formData.append("country", userData.country ? userData.country : "");
        userData.zipcode && formData.append("zipcode", userData.zipcode ? userData.zipcode : "");
        userData.updates && formData.append("updates", userData.updates ? userData.updates : "");
        userData.description && formData.append("description", userData.description ? userData.description : "");
        userData.post_award && formData.append("post_award", userData.post_award ? userData.post_award : []);
        userData.post_tag && formData.append("post_tag", userData.post_tag ? userData.post_tag : []);

        if (userData.media_name) {
          for (let i = 0; i < userData.media_name.length; i++) {
            formData.append("media_name[]", userData.media_name[i]);
          }
        }

        userData.vendor_website && formData.append("vendor_website", userData.vendor_website ? userData.vendor_website : "");
        userData.vendor_contact && formData.append("vendor_contact", userData.vendor_contact ? userData.vendor_contact : "");
        userData.call_to_action && formData.append("call_to_action", userData.call_to_action ? userData.call_to_action : 1);
        userData.post_call_to_action && formData.append("action_list", userData.post_call_to_action ? userData.post_call_to_action : []);
        // userData.button_name && formData.append("button_name", userData.button_name ? userData.button_name : "");
        // userData.website && formData.append("website", userData.website ? userData.website : "");
        userData.latitude && formData.append("latitude", userData.latitude ? userData.latitude : "");
        userData.longitude && formData.append("longitude", userData.longitude ? userData.longitude : "");
        // console.log("formData ::", formData);
        return {
          url: "/update-post",
          method: "post",
          body: formData,
        };
      },
    }),
    deletePost: builder.mutation({
      query: (id) => {
        let formData = new FormData();
        formData.append("post_id", id);
        return {
          url: "/delete-post",
          method: "post",
          body: formData,
        };
      },
    }),
    deletePostMedia: builder.mutation({
      query: (media_url) => {
        return {
          url: config.base_url + "delete-media?media_url=" + media_url,
          method: "get",
        };
      },
    }),
    upVotePost: builder.mutation({
      query: (data) => {
        return {
          url: config.base_url + "up-vote",
          method: "post",
          body: data,
        };
      },
    }),
    downVotePost: builder.mutation({
      query: (data) => {
        return {
          url: config.base_url + "down-vote",
          method: "post",
          body: data,
        };
      },
    }),
    updatesOfPost: builder.mutation({
      query: (data) => {
        return {
          url: config.base_url + "new-updates",
          method: "post",
          body: data,
        };
      },
    }),
    myPosts: builder.query({
      query: (data) => {
        const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : "";

        // let query = "/all-posts?location=" + data.location.query;
        let query = data.cursor + "location=" + data.location;
        if (user) query += "&user_id=" + user.id;
        if (data.searchText && data.searchText !== "" && data.searchText !== undefined) {
          if (data.searchText.includes("#")) {
            return query + "&tag=" + data.searchText.substring(1, data.searchText.length);
          } else {
            return query + "&serach_key=" + data.searchText;
          }
        } else {
          return query;
        }
      },
    }),
    sharePost: builder.mutation({
      query: (data) => {
        return {
          url: config.base_url + "share-post",
          method: "post",
          body: data,
        };
      },
    }),
    buttonVisited: builder.mutation({
      query: (data) => {
        return {
          url: config.base_url + "button-visited",
          method: "post",
          body: data,
        };
      },
    }),
    nearByPosts: builder.query({
      query: (data) => "nearby-posts?lat=" + data.lat + "&long=" + data.lng,
    }),
  }),
});
export const { useGetPostsQuery, useViewPostQuery, useDeletePostMutation, useDeletePostMediaMutation, useUpdatePostMutation, useUpVotePostMutation, useDownVotePostMutation, useUpdatesOfPostMutation, useMyPostsQuery, useSharePostMutation, useButtonVisitedMutation, useNearByPostsQuery } = postApi;
