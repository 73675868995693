import { createSlice, PayloadAction } from "@reduxjs/toolkit";
const initialState = {
  user: {},
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action) => {
      localStorage.setItem("token", action.payload.data.token);
      localStorage.setItem("user", JSON.stringify(action.payload.data));
      return {
        ...state,
        user: action.payload.data,
        token: action.payload.data.token,
      };
    },
    setSignUpUser: (state, action) => {
      return {
        ...state,
        signUpUser: action.payload.data,
      };
    },
    setUpdatedUser: (state, action) => {
      localStorage.setItem("user", JSON.stringify(action.payload.data));
      return {
        ...state,
        user: action.payload.data,
      };
    },
    defaultState: (state) => {
      state = initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUser, setSignUpUser, setUpdatedUser, defaultState } =
  authSlice.actions;

export default authSlice.reducer;
