import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const ErrorComponent = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/");
  }, []);
  return (
    <div className="w-100 h-100 m-auto text-center">
      <h1 className="text-danger">404 Page not found</h1>
    </div>
  );
};
