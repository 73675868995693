import React from "react";
import "./privacy-policy.css";

export const PrivacyPolicy = () => {
  return (
    <div>
      <h1
        style={{
          paddingTop: "3pt",
          // paddingLeft: "196pt",
          textIndent: "0pt",
          textAlign: "center",
        }}
      >
        Privacy Policy
      </h1>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p
        style={{
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "106%",
          textAlign: "left",
        }}
      >
        <a href="http://www.gothruhike.com/" className="a" target="_blank"  rel="noreferrer">
          Protecting your private information is our priority. This Statement of
          Privacy applies to Thruhike, and Thruhike LLC and governs data
          collection and usage. For the purposes of this Privacy Policy, unless
          otherwise noted, all references to Thruhike LLC include{" "}
        </a>
        www.gothruhike.com and Thruhike. The Thruhike application is a social
        media platform and ecommerce application. By using the Thruhike
        application, you consent to the data practices described in this
        statement.
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <h2 style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
        Collection of your Personal Information
      </h2>
      <p
        style={{
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "106%",
          textAlign: "left",
        }}
      >
        In order to better provide you with products and services offered,
        Thruhike may collect personally identifiable information, such as your:
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <ul id="l1">
        <li data-list-text="-">
          <p
            style={{
              paddingTop: "4pt",
              paddingLeft: "53pt",
              textIndent: "-19pt",
              textAlign: "left",
            }}
          >
            First and Last Name
          </p>
        </li>
        <li data-list-text="-">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "53pt",
              textIndent: "-19pt",
              textAlign: "left",
            }}
          >
            E-mail Address
          </p>
        </li>
        <li data-list-text="-">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "53pt",
              textIndent: "-19pt",
              textAlign: "left",
            }}
          >
            Phone Number
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p
            style={{
              paddingTop: "4pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              lineHeight: "106%",
              textAlign: "left",
            }}
          >
            If you purchase Thruhike&#39;s products and services, we collect
            billing and credit card information. This information is used to
            complete the purchase transaction.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p
            style={{
              paddingLeft: "5pt",
              textIndent: "0pt",
              lineHeight: "106%",
              textAlign: "left",
            }}
          >
            Thruhike may also collect anonymous demographic information, which
            is not unique to you, such as your:
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
        </li>
        <li data-list-text="-">
          <p
            style={{
              paddingTop: "4pt",
              paddingLeft: "53pt",
              textIndent: "-19pt",
              textAlign: "left",
            }}
          >
            Age
          </p>
        </li>
        <li data-list-text="-">
          <p
            style={{
              paddingTop: "1pt",
              paddingLeft: "53pt",
              textIndent: "-19pt",
              textAlign: "left",
            }}
          >
            Gender
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p
            style={{
              paddingTop: "4pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              lineHeight: "106%",
              textAlign: "left",
            }}
          >
            We do not collect any personal information about you unless you
            voluntarily provide it to us. However, you may be required to
            provide certain personal information to us when you elect to use
            certain products or services. These may include: (a) registering for
            an "account", (b) entering a sweepstakes or contest sponsored by us
            or one of our "partners", (c) signing up for special offers from
            selected third "parties", (d) sending us an email "message", (e)
            submitting your credit card or other payment information when
            ordering and purchasing products and services. To wit, we will use
            your information for, but not limited to, communicating with you in
            relation to services and/or products you have requested from us. We
            also may gather additional personal or non-personal information in
            the future.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <h2
            style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
          >
            Use of your Personal Information
          </h2>
          <p
            style={{
              paddingLeft: "5pt",
              textIndent: "0pt",
              lineHeight: "106%",
              textAlign: "left",
            }}
          >
            Thruhike collects and uses your personal information to operate and
            deliver the services you have requested.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p
            style={{
              paddingLeft: "5pt",
              textIndent: "0pt",
              lineHeight: "106%",
              textAlign: "left",
            }}
          >
            Thruhike may also use your personally identifiable information to
            inform you of other products or services available from Thruhike and
            its affiliates.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <h2
            style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
          >
            Sharing Information with Third Parties
          </h2>
          <p
            style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
          >
            Thruhike does not sell, rent or lease its customer lists to third
            parties.
          </p>
          <p
            style={{
              paddingTop: "4pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              lineHeight: "106%",
              textAlign: "left",
            }}
          >
            Thruhike may, from time to time, contact you on behalf of external
            business partners about a particular offering that may be of
            interest to you. In those cases, your unique personally identifiable
            information (e-mail, name, address, telephone number) is transferred
            to the third party. Thruhike may share data with trusted partners to
            help perform statistical analysis, send you email or postal mail,
            provide customer support, or arrange for deliveries. All such third
            parties are prohibited from using your personal information except
            to provide these services to Thruhike, and they are required to
            maintain the confidentiality of your information.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <p
            style={{
              paddingLeft: "5pt",
              textIndent: "0pt",
              lineHeight: "106%",
              textAlign: "left",
            }}
          >
            Thruhike may disclose your personal information, without notice, if
            required to do so by law or in the good faith belief that such
            action is necessary to: (a) conform to the edicts of the law or
            comply with legal process served on Thruhike or the "site", (b)
            protect and defend the rights or property of "Thruhike", and/or (c)
            act under exigent circumstances to protect the personal safety of
            users of Thruhike, or the public.
          </p>
          <p style={{ textIndent: "0pt", textAlign: "left" }}>
            <br />
          </p>
          <h2
            style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
          >
            Opt-Out of Disclosure of Personal Information to Third Parties
          </h2>
          <p
            style={{
              paddingLeft: "5pt",
              textIndent: "0pt",
              lineHeight: "106%",
              textAlign: "left",
            }}
          >
            In connection with any personal information we may disclose to a
            third party for a business purpose, you have the right to know:
          </p>
          <ul id="l2">
            <li data-list-text="•">
              <p
                style={{
                  paddingLeft: "62pt",
                  textIndent: "-19pt",
                  lineHeight: "106%",
                  textAlign: "left",
                }}
              >
                The categories of personal information that we disclosed about
                you for a business purpose.
              </p>
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
              <p
                style={{
                  paddingTop: "4pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  lineHeight: "106%",
                  textAlign: "left",
                }}
              >
                You have the right under the California Consumer Privacy Act of
                2018 (CCPA) and certain other privacy and data protection laws,
                as applicable, to opt-out of the disclosure of your personal
                information. If you exercise your right to opt-out of the
                disclosure of your personal information, we will refrain from
                disclosing your personal information, unless you subsequently
                provide express authorization for the disclosure of your
                personal information. To opt-out of the disclosure of your
                personal information, visit this Web page
                <u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </u>.
              </p>
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
              <h2
                style={{
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                Right to Deletion
              </h2>
              <p
                style={{
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                Subject to certain exceptions set out below, on receipt of a
                verifiable request from you, we will:
              </p>
            </li>
            <li data-list-text="•">
              <p
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "62pt",
                  textIndent: "-19pt",
                  textAlign: "left",
                }}
              >
                Delete your personal information from our "records", and
              </p>
            </li>
            <li data-list-text="•">
              <p
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "62pt",
                  textIndent: "-19pt",
                  textAlign: "left",
                }}
              >
                Direct any service providers to delete your personal information
                from their records.
              </p>
              <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
              </p>
              <p
                style={{
                  paddingTop: "4pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  lineHeight: "106%",
                  textAlign: "left",
                }}
              >
                Please note that we may not be able to comply with requests to
                delete your personal information if it is necessary to:
              </p>
            </li>
            <li data-list-text="•">
              <p
                style={{
                  paddingLeft: "62pt",
                  textIndent: "-19pt",
                  lineHeight: "106%",
                  textAlign: "left",
                }}
              >
                Complete the transaction for which the personal information was
                collected, fulfill the terms of a written warranty or product
                recall conducted in accordance with federal law, provide a good
                or service requested by you, or reasonably anticipated within
                the context of our ongoing business relationship with you, or
                otherwise perform a contract between you and "us",
              </p>
            </li>
            <li data-list-text="•">
              <p
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "62pt",
                  textIndent: "-19pt",
                  lineHeight: "106%",
                  textAlign: "left",
                }}
              >
                Detect security incidents, protect against malicious, deceptive,
                fraudulent, or illegal "activity", or prosecute those
                responsible for that "activity",
              </p>
            </li>
            <li data-list-text="•">
              <p
                style={{
                  paddingLeft: "62pt",
                  textIndent: "-19pt",
                  textAlign: "left",
                }}
              >
                Debug to identify and repair errors that impair existing
                intended "functionality",
              </p>
            </li>
            <li data-list-text="•">
              <p
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "62pt",
                  textIndent: "-19pt",
                  textAlign: "left",
                }}
              >
                Exercise free speech, ensure the right of another consumer to
                exercise his or her right
              </p>
              <p
                style={{
                  paddingTop: "3pt",
                  paddingLeft: "62pt",
                  textIndent: "0pt",
                  textAlign: "left",
                }}
              >
                of free speech, or exercise another right provided for by "law",
              </p>
            </li>
            <li data-list-text="•">
              <p
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "62pt",
                  textIndent: "-19pt",
                  textAlign: "left",
                }}
              >
                Comply with the California Electronic Communications Privacy
                "Act",
              </p>
            </li>
            <li data-list-text="•">
              <p
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "62pt",
                  textIndent: "-19pt",
                  lineHeight: "106%",
                  textAlign: "left",
                }}
              >
                Engage in public or peer-reviewed scientific, historical, or
                statistical research in the public interest that adheres to all
                other applicable ethics and privacy laws, when our deletion of
                the information is likely to render impossible or seriously
                impair the achievement of such research, provided we have
                obtained your informed "consent",
              </p>
            </li>
            <li data-list-text="•">
              <p
                style={{
                  paddingLeft: "62pt",
                  textIndent: "-19pt",
                  lineHeight: "106%",
                  textAlign: "left",
                }}
              >
                Enable solely internal uses that are reasonably aligned with
                your expectations based on your relationship with "us",
              </p>
            </li>
            <li data-list-text="•">
              <p
                style={{
                  paddingLeft: "62pt",
                  textIndent: "-19pt",
                  textAlign: "left",
                }}
              >
                Comply with an existing legal "obligation", or
              </p>
            </li>
            <li data-list-text="•">
              <p
                style={{
                  paddingTop: "1pt",
                  paddingLeft: "62pt",
                  textIndent: "-19pt",
                  lineHeight: "106%",
                  textAlign: "left",
                }}
              >
                Otherwise use your personal information, internally, in a lawful
                manner that is compatible with the context in which you provided
                the information.
              </p>
            </li>
          </ul>
        </li>
      </ul>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <h2
        style={{
          paddingTop: "4pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "left",
        }}
      >
        Children Under Thirteen
      </h2>
      <p
        style={{
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "106%",
          textAlign: "left",
        }}
      >
        Thruhike does not knowingly collect personally identifiable information
        from children under the age of thirteen. If you are under the age of
        thirteen, you must ask your parent or guardian for permission to use
        this application.
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <h2 style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
        Opt-Out &"amp", Unsubscribe from Third Party Communications
      </h2>
      <p
        style={{
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "106%",
          textAlign: "left",
        }}
      >
        We respect your privacy and give you an opportunity to opt-out of
        receiving announcements of certain information. Users may opt-out of
        receiving any or all communications from third-party partners of
        Thruhike by contacting us here:
      </p>
      <ul id="l3">
        <li data-list-text="-">
          <p
            style={{
              paddingLeft: "13pt",
              textIndent: -"8pt",
              textAlign: "left",
            }}
          >
            Web page: <u>&nbsp;</u>
          </p>
        </li>
        <li data-list-text="-">
          <p
            style={{
              paddingLeft: "13pt",
              textIndent: -"8pt",
              textAlign: "left",
            }}
          >
            <a href="mailto:admin@gothruhike.com" className="a" target="_blank"  rel="noreferrer">
              Email:{" "}
            </a>
            <a href="mailto:admin@gothruhike.com" target="_blank"  rel="noreferrer">
              admin@gothruhike.com
            </a>
          </p>
        </li>
        <li data-list-text="-">
          <p
            style={{
              paddingLeft: "13pt",
              textIndent: -"8pt",
              textAlign: "left",
            }}
          >
            Phone: <u>&nbsp;</u>
          </p>
        </li>
      </ul>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <h2
        style={{
          paddingTop: "4pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "left",
        }}
      >
        E-mail Communications
      </h2>
      <p
        style={{
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "106%",
          textAlign: "left",
        }}
      >
        From time to time, Thruhike may contact you via email for the purpose of
        providing announcements, promotional offers, alerts, confirmations,
        surveys, and/or other general communication. In order to improve our
        Services, we may receive a notification when you open an email from
        Thruhike or click on a link therein.
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p
        style={{
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "106%",
          textAlign: "left",
        }}
      >
        <a href="mailto:admin@gothruhike.com" className="a" target="_blank"  rel="noreferrer">
          If you would like to stop receiving marketing or promotional
          communications via email from Thruhike, you may opt out of such
          communications by emailing{" "}
        </a>
        admin@gothruhike.com to &quot;STOP PROMOTIONAL EMAILS.&quot;.
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <h2 style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
        External Data Storage Sites
      </h2>
      <p
        style={{
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "106%",
          textAlign: "left",
        }}
      >
        We may store your data on servers provided by third party hosting
        vendors with whom we have contracted.
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <h2 style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
        Changes to this Statement
      </h2>
      <p
        style={{
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "106%",
          textAlign: "left",
        }}
      >
        Thruhike reserves the right to change this Privacy Policy from time to
        time. We will notify you about significant changes in the way we treat
        personal information by sending a notice to the primary email address
        specified in your account, by placing a prominent notice on our
        application, and/or by updating any privacy information. Your continued
        use of the application and/or Services available after such
        modifications will constitute your: (a) acknowledgment of the modified
        Privacy
      </p>
      <p
        style={{
          paddingTop: "4pt",
          paddingLeft: "5pt",
          textIndent: "0pt",
          textAlign: "left",
        }}
      >
        "Policy", and (b) agreement to abide and be bound by that Policy.
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <h2 style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
        Contact Information
      </h2>
      <p
        style={{
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "106%",
          textAlign: "left",
        }}
      >
        Thruhike welcomes your questions or comments regarding this Statement of
        Privacy. If you believe that Thruhike has not adhered to this Statement,
        please contact Thruhike at:
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
        Thruhike LLC
      </p>
      <p
        style={{
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "106%",
          textAlign: "left",
        }}
      >
        221 North Broad Street, Suite 3A Middletown, Delaware 19709
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p
        style={{
          paddingLeft: "5pt",
          textIndent: "0pt",
          lineHeight: "106%",
          textAlign: "left",
        }}
      >
        <a href="mailto:admin@gothruhike.com" className="a" target="_blank"  rel="noreferrer">
          Email Address:{" "}
        </a>
        <a href="mailto:admin@gothruhike.com" target="_blank"  rel="noreferrer">
          admin@gothruhike.com
        </a>
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
        Telephone number:
      </p>
      <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
        9146298594
      </p>
      <p style={{ textIndent: "0pt", textAlign: "left" }}>
        <br />
      </p>
      <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
        Effective as of January 23, 2023
      </p>
    </div>
  );
};
