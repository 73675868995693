import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { postApi } from "./API/postAPI";
import { authApi } from "./API/authAPI";
import authReducer from "./redux/authSlice";
import modalReducer from "./redux/modalSlice";
import { trailApi } from "./API/trailAPI";
import { productApi } from "./API/productAPI";
import { categoryApi } from "./API/categoryAPI";
import { profileApi } from "./API/profileAPI";
import { cliamBusinessApi } from "./API/businessAPI";
import { subscriptionApi } from "./API/subscriptionAPI";
import { headerApi } from "./API/trailHeaderAPI";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    modal: modalReducer,
    [authApi.reducerPath]: authApi.reducer,
    [profileApi.reducerPath]: profileApi.reducer,
    [postApi.reducerPath]: postApi.reducer,
    [trailApi.reducerPath]: trailApi.reducer,
    [productApi.reducerPath]: productApi.reducer,
    [categoryApi.reducerPath]: categoryApi.reducer,
    [cliamBusinessApi.reducerPath]: cliamBusinessApi.reducer,
    [subscriptionApi.reducerPath]: subscriptionApi.reducer,
    [headerApi.reducerPath]: headerApi.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(authApi.middleware).concat(postApi.middleware).concat(profileApi.middleware).concat(trailApi.middleware).concat(categoryApi.middleware).concat(productApi.middleware).concat(cliamBusinessApi.middleware).concat(subscriptionApi.middleware).concat(headerApi.middleware),
});

// configure listeners using the provided defaults
setupListeners(store.dispatch);
