import "./App.css";
import "./Responsive.css";
import "./assets/css/new_style.css";
import "./assets/css/new_responsive.css";
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";
import Page from "./pages";
import { TrailView } from "./pages/trail/TrailView";
import { Home } from "./pages/Home";
import { View as ProductView } from "./pages/product/View";
import { Add as ProductAdd } from "./pages/product/Add";
import { Subscription } from "./pages/subscriptions";
import { TrailList } from "./pages/trail/TrailList";
import { View as PlaceView } from "./pages/place/View";
import { PostDetails } from "./pages/post/PostDetails";
import { useEffect, useState } from "react";
// import Geocode from "react-geocode";
import { config } from "./config";
import { useDispatch, useSelector } from "react-redux";
import { setLocation } from "./redux/modalSlice";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { TermsConditions } from "./pages/TermsConditions";
import { Faq } from "./pages/Faq";
import { AboutUs } from "./pages/about";
import { PostAnalytics } from "./pages/post/PostAnalytics";
import { ErrorComponent } from "./components/ErrorComponent";
import CustomGoogleMap from "./components/map/CustomGoogleMap";
import { VendorLandingPage } from "./pages/VendorLandingPage";
import { LandingPage } from "./pages/LandingPage";
import { TrailLatest } from "./pages/trail/TrailLatest";

const App = () => {
  const dispatch = useDispatch();
  const [latitutde, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const token = localStorage.getItem("token");
  const location = useSelector((state) => state.modal.location);
  // console.log("location ::", location);

  useEffect(() => {
    localStorage.removeItem("location");
    localStorage.removeItem("showFirstTimeBanner");
  }, []);

  // useEffect(() => {
  //   // if (location === "") {
  //   //   if (!navigator.geolocation) {
  //   //     // console.log("location not allowed");
  //   //     dispatch(
  //   //       // setLocation({
  //   //       //   display: "NewYork, United States",
  //   //       //   query: 'NY, United States',
  //   //       // })
  //   //       setLocation("NY, United States")
  //   //     );
  //   //   } else {
  //   //     navigator.geolocation.watchPosition(function (position) {
  //   //       setLatitude(position.coords.latitude);
  //   //       setLongitude(position.coords.longitude);
  //   //       // console.log("Latitude is :", position.coords.latitude);
  //   //       // console.log("Longitude is :", position.coords.longitude);
  //   //     });
  //   //   }

  //   //   if (latitutde !== "" && longitude !== "") {
  //   //     // set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
  //   //     Geocode.setApiKey(config.google_api_key);
  //   //     // Get formatted address, city, state, country from latitude & longitude when
  //   //     // Geocode.setLocationType("ROOFTOP") enabled
  //   //     // the below parser will work for most of the countries
  //   //     Geocode.fromLatLng(latitutde, longitude).then(
  //   //       (response) => {
  //   //         const address = response.results[0].formatted_address;
  //   //         let city, state, country;
  //   //         for (
  //   //           let i = 0;
  //   //           i < response.results[0].address_components.length;
  //   //           i++
  //   //         ) {
  //   //           for (
  //   //             let j = 0;
  //   //             j < response.results[0].address_components[i].types.length;
  //   //             j++
  //   //           ) {
  //   //             switch (response.results[0].address_components[i].types[j]) {
  //   //               case "locality":
  //   //                 city = response.results[0].address_components[i].long_name;
  //   //                 break;
  //   //               case "administrative_area_level_1":
  //   //                 state = response.results[0].address_components[i].long_name;
  //   //                 break;
  //   //               case "country":
  //   //                 country =
  //   //                   response.results[0].address_components[i].long_name;
  //   //                 break;
  //   //             }
  //   //           }
  //   //         }
  //   //         // console.log({ address, city, state, country });
  //   //         if (state !== "" && country !== "") {
  //   //           dispatch(setLocation(state + ", " + country));
  //   //         }
  //   //       },
  //   //       (error) => {
  //   //         console.error(error);
  //   //       }
  //   //     );
  //   //   } else {
  //   //     dispatch(
  //   //       // setLocation({
  //   //       //   display: "NewYork, United States",
  //   //       //   query: 'NY, United States',
  //   //       // })
  //   //       setLocation("United States")
  //   //     );
  //   //
  //   // } else {
  //   //   // console.log("location is not empty ::", location);

  //   //   // Get latitude & longitude from address.
  //   //   Geocode.fromAddress(location).then(
  //   //     (response) => {
  //   //       const { lat, lng } = response.results[0].geometry.location;
  //   //       // console.log(lat, lng);
  //   //       setLatitude(lat);
  //   //       setLongitude(lng);
  //   //     },
  //   //     (error) => {
  //   //       console.error(error);
  //   //     }
  //   //   );
  //   // }
  //   // dispatch(setLocation("United States"));
  // }, [location]);
  const routeArray =
    token !== "" && token !== undefined && token !== null
      ? [
        {
          path: "/*",
          element: <ErrorComponent />,
        },
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/trail",
          element: <TrailView />,
        },
        {
          path: "/trailandfavourite/:id",
          element: <TrailList />,
        },
        {
          path: "/place",
          element: <ProductView />,
        },
        {
          path: "/trail-details/:id",
          // element: <PlaceView />,
          element: <TrailLatest />,
        },
        {
          path: "/addplace",
          element: <ProductAdd />,
        },
        {
          path: "/post-details/:id",
          element: <PostDetails />,
        },
        {
          path: "/privacy-policy",
          element: <PrivacyPolicy />,
        },
        {
          path: "/terms-conditions",
          element: <TermsConditions />,
        },
        {
          path: "/about-us",
          element: <AboutUs />,
        },
        {
          path: "/faq",
          element: <Faq />,
        },
        {
          path: "/view-analytics/:id",
          element: <PostAnalytics />,
        },
        {
          path: "/map",
          element: <CustomGoogleMap />,
        },
        {
          path: "/subscriptions",
          element: <Subscription />,
        },
        {
          path: "/posts",
          element: <Home />,
        },
        {
          path: "/trail-latest",
          element: <TrailLatest />,
        },
        {
          path: "/trail-latest/:id",
          element: <TrailLatest />,
        },
      ]
      : [
        {
          path: "/*",
          element: <ErrorComponent />,
        },
        {
          path: "/",
          element: <LandingPage />,
        },
        {
          path: "/welcome",
          element: <LandingPage />,
        },
        {
          path: "/vendor",
          element: <VendorLandingPage />,
        },
        {
          path: "/trail",
          element: <TrailView />,
        },
        {
          path: "/trailandfavourite/:id",
          element: <TrailList />,
        },
        {
          path: "/trail-details/:id",
          // element: <PlaceView />,
          element: <TrailLatest />,
        },
        {
          path: "/posts",
          element: <Home />,
        },
        {
          path: "/post-details/:id",
          element: <PostDetails />,
        },
        {
          path: "/privacy-policy",
          element: <PrivacyPolicy />,
        },
        {
          path: "/terms-conditions",
          element: <TermsConditions />,
        },
        {
          path: "/about-us",
          element: <AboutUs />,
        },
        {
          path: "/faq",
          element: <Faq />,
        },
        {
          path: "/map",
          element: <CustomGoogleMap />,
        },
        {
          path: "/subscriptions",
          element: <Subscription />,
        },
        {
          path: "/trail-latest",
          element: <TrailLatest />,
        },
        {
          path: "/trail-latest/:id",
          element: <TrailLatest />,
        },
      ];
  const router = createBrowserRouter(
    [
      {
        path: "/",
        element: <Page />,
        children: routeArray,
        errorElement: <ErrorComponent />,
      },
    ],
    {
      basename: "/",
    }
  );

  return <RouterProvider router={router} fallbackElement={<ErrorComponent />} />;
};

export default App;
