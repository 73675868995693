import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useViewProductsQuery } from "../../API/productAPI";
import { useMyPostsQuery } from "../../API/postAPI";
import Card from "../../components/Card";
import { Loader } from "../../components/Loader";
import { useSelector } from "react-redux";

export const View = () => {
  const [cursor, setCursor] = useState("/my-posts?");
  const searchText = useSelector((state) => state.modal.searchText);
  const location = useSelector((state) => state.modal.location);
  const { data: products, isLoading, isSuccess, isFetching } = useMyPostsQuery({ cursor, location, searchText });
  // console.log("products :: ", products);
  const productData = products?.data?.data ?? [];

  useEffect(() => {
    const onScroll = () => {
      const scrolledToBottom = window.innerHeight + window.scrollY >= document.body.offsetHeight;
      if (scrolledToBottom && !isFetching) {
        // console.log("Fetching more data...");
        if (products?.data.next_page_url !== "") {
          setCursor(products.data.next_page_url);
        }
      }
    };

    document.addEventListener("scroll", onScroll);

    return function () {
      document.removeEventListener("scroll", onScroll);
    };
  }, [cursor, isFetching]);

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <div>
        <div className="row your_favriout">
          {isSuccess && productData.length ? (
            productData.map((product) => (
              <div className="col-lg-4 col-md-6 col-sm-6 trail_width_responsive" key={`post-${product.id}`}>
                <Card post={product} showTags={product.status !== "Approved"} showAddYourHashTag={false} />
              </div>
            ))
          ) : (
            <div className="d-flex justify-content-between items-center ">
              <h3>No Post Found.</h3>
            </div>
          )}

          <div className="trail_btn position_fixed">
            <div className="container">
              <Link to="/addplace" className="add_trail">
                <i className="fas fa-plus"></i> Add Product/Place
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
