import React from "react";
import { useDeleteHeaderMutation } from "../../API/trailHeaderAPI";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Modal } from "reactstrap";
import { deleteHeaderModal } from "../../redux/modalSlice";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { config } from "../../config";

export const DeleteHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const headerIdState = useSelector((state) => state.modal.deleteHeaderId);
  const deleteHeaderModalState = useSelector((state) => state.modal.deleteHeaderModal);

  const closeModal = () => {
    dispatch(deleteHeaderModal({ modal: false, id: null }));
  };

  const [deleteHeader, { data: deleteHeaderData, isLoading: deleteHeaderIsLoading, isSuccess: deleteHeaderIsSuccess, isError: deleteHeaderIsError, error: deleteHeaderError }] = useDeleteHeaderMutation();

  const onDeleteHeader = () => {
    console.log("onDeleteHeader :: ", headerIdState);
    dispatch(deleteHeader(headerIdState));
  };

  useEffect(() => {
    if (deleteHeaderIsSuccess) {
      if (deleteHeaderData.status === 200) {
        toast(deleteHeaderData.message, {
          ...config.toast_config,
          type: "success",
        });
        closeModal();
        navigate(0);
      } else {
        toast(deleteHeaderData.message, {
          ...config.toast_config,
          type: "error",
        });
      }
    }
  }, [deleteHeaderData, deleteHeaderIsSuccess]);

  return (
    <Modal isOpen={deleteHeaderModalState} centered={true} contentClassName="login_form" toggle={closeModal}>
      {console.log("deleteHeaderModalState :: ", deleteHeaderModalState)}
      <div className="modal-header">
        <h1 className="modal-title" id="exampleModalLabel">
          Delete Heading?
        </h1>
      </div>
      <div className="modal-body">
        <form className="form_field">
          <label>By Deleting this header all posts are removed from trail.</label>
        </form>
      </div>
      <div className="modal-footer d-block">
        <div className="row m-0">
          <div className="col-md-6 col-6 sol-sm-6">
            <button type="button" aria-label="Close" className="btn btn-primary cancel" onClick={closeModal}>
              Cancel
            </button>
          </div>
          <div className="col-md-6 col-6 sol-sm-6">
            <button type="button" className="btn btn-primary delete" onClick={() => onDeleteHeader()}>
              Delete
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
