import React from "react";
import videojs from "video.js";
import Video from "../components/Video";
import { Link } from "react-router-dom";

export const VendorLandingPage = () => {
  const playerRef = React.useRef(null);

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on("waiting", () => {
      videojs.log("player is waiting");
    });
    // player.on("mouseenter", () => {
    //   videojs.log("player is waiting enter", player);
    //   player.bigPlayButton.handleClick();
    // });
    // player.on("mouseleave", function () {
    //   videojs.log("player is waiting leave", player);
    //   player.pause();
    //   player.bigPlayButton.show();
    // });

    player.on("dispose", () => {
      videojs.log("player will dispose");
    });
  };
  return (
    <>
      {/* <!-- schedule text and video --> */}
      <div className="schedule_demo">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-7 col-sm-12 col-12">
              <h2>
                A pathway to business <u>growth</u> and a better <u>customer experience</u>
              </h2>
            </div>
            <div className="col-lg-4 col-md-5 col-sm-12 col-12 position-relative">
              <img src={require("../assets/images/stroke.png")} alt="" title="" className="stroke_img" />
              {/* <p>We're a platform delivering the power of social content, brand insights, and customer personalization to help you grow your business, promote your offerings, and make a stronger connection with your customers.</p> */}
              <p>84% of people say they have been convinced to buy a product or service after watching a brand's video. Thruhike delivers the power of video, coupled with industry insights, to better connect with customers and promote your offerings.</p>
            </div>
          </div>
          <div className="row mt-5 video_demo">
            <div className="col-md-4 col-lg-4 col-sm-6 col-12 position-relative">
              <a href="javascript:void(0)">
                <div className="trail_post_img video_icon">
                  <Video
                    key={"media_post_video"}
                    options={{
                      preload: "auto",
                      autoplay: false,
                      controls: true,
                      responsive: true,
                      fluid: true,
                      sources: [
                        {
                          src: "https://ik.imagekit.io/h56kvej7o/media_image/media_image_0_1677685508.mp4/ik-master.m3u8?tr=sr-480_720_1080",
                          type: "application/x-mpegURL",
                        },
                      ],
                      poster: "https://ik.imagekit.io/h56kvej7o/media_image/media_image_0_1677685508.mp4/ik-thumbnail.jpg?tr=w-900,h-600,c-force",
                    }}
                    onReady={handlePlayerReady}
                  />
                </div>
              </a>
            </div>
            <div className="col-md-4 col-lg-4 col-sm-6 col-12 position-relative">
              <a href="javascript:void(0)">
                <div className="trail_post_img video_icon">
                  <Video
                    key={"media_post_video"}
                    options={{
                      preload: "auto",
                      autoplay: false,
                      controls: true,
                      responsive: true,
                      fluid: true,
                      sources: [
                        {
                          src: "https://ik.imagekit.io/h56kvej7o/media_image/media_image_6_1677063868.mp4/ik-master.m3u8?tr=sr-480_720_1080",
                          type: "application/x-mpegURL",
                        },
                      ],
                      poster: "https://ik.imagekit.io/h56kvej7o/media_image/media_image_6_1677063868.mp4/ik-thumbnail.jpg?tr=w-900,h-600,c-force",
                    }}
                    onReady={handlePlayerReady}
                  />
                </div>
              </a>
            </div>
            <div className="col-md-4 col-lg-4 col-sm-6 col-12 position-relative">
              <a href="javascript:void(0)">
                <div className="trail_post_img video_icon">
                  <Video
                    key={"media_post_video"}
                    options={{
                      preload: "auto",
                      autoplay: false,
                      controls: true,
                      responsive: true,
                      fluid: true,
                      sources: [
                        {
                          src: "https://ik.imagekit.io/h56kvej7o/media_image/media_image_6_1677063888.mp4/ik-master.m3u8?tr=sr-480_720_1080",
                          type: "application/x-mpegURL",
                        },
                      ],
                      poster: "https://ik.imagekit.io/h56kvej7o/media_image/media_image_6_1677063888.mp4/ik-thumbnail.jpg?tr=w-900,h-600,c-force",
                    }}
                    onReady={handlePlayerReady}
                  />
                </div>
              </a>
            </div>
          </div>
          <div className="about_thruhike p-0 text-center mt-3">
            <a href="https://calendly.com/katherine-thruhike/intro" target="_blank" type="button" className="btn btn-primary save mt-0">
              Schedule a Demo
            </a>
          </div>
        </div>
      </div>

      {/* <!-- start why thruhike --> */}
      <div className="about_thruhike why_thruhike">
        <div className="container bg_line_img position-relative">
          <h3 className="text-center">What is Thruhike?</h3>
          <div className="row justify-content-end">
            <div className="customers_amplify">
              <div className="inside_customer">
                <img src={require("../assets/images/crown.png")} alt="" title="" />
                <div className="customer_detail">
                  <h3>Amplify Customers</h3>
                  <p>
                    We <strong>host your experiences & memberships</strong> on our social platform to help you earn revenue while making your customers feel like VIPs.
                  </p>
                  <br />
                  <p>The payoffs for valued, great experiences are tangible: up to a 16% price premium on products and services, plus increased loyalty.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="customers_amplify">
              <div className="inside_customer">
                <img src={require("../assets/images/review.png")} alt="" title="" />
                <div className="customer_detail">
                  <h3>Retain Customers</h3>
                  <p>
                    <strong>We provide industry analytics and individualized customer profiles</strong> to personalize your offerings and maximize what you do best.
                  </p>
                  <br />
                  <p>86% of buyers are willing to pay more for a great customer experience. </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-start">
            <div className="customers_amplify mb-0">
              <div className="inside_customer">
                <img src={require("../assets/images/customer.png")} alt="" title="" />
                <div className="customer_detail">
                  <h3>New Customers</h3>
                  <p>
                    We <strong>create pro video content and ignite your brand</strong> on social platforms including our own.
                  </p>
                  <br />
                  <p>84% of people say they've been convinced to buy a product or service after watching a brands video.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!--start talk to hike section --> */}
      <div className="talk_to_hike about_thruhike">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
              <h3>Talk to Thruhike ?</h3>
              <p>Get your questions answered with a quick 15 min call</p>
              <a href="https://calendly.com/katherine-thruhike/intro" target="_blank" type="button" className="btn btn-primary save">
                Schedule a Demo
              </a>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
              <h3>Subscription</h3>
              <p>Learn more about what our subscription plans offer below</p>
              <a href="https://www.canva.com/design/DAFR303l0oc/_AGoJN2KXomGgTtncyBXJg/view?utm_content=DAFR303l0oc&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink" target="_blank" type="button" className="btn btn-primary save">
                Get Started
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
