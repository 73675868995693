import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useGetViewTrailGroupQuery } from "../../API/trailAPI";
import { Loader } from "../../components/Loader";
import { TrailListCard } from "../../components/TrailListCard";
import { addTrailModal } from "../../redux/modalSlice";
import NoFoundComponent from "../NoFoundComponent";
import { config } from "../../config";
import { AES, enc } from "crypto-js";

export const TrailList = () => {
  const dispatch = useDispatch();
  let { id } = useParams();
  console.log(id);
  let decryptId = AES.decrypt(id, config.passphrase).toString(enc.Utf8);
  console.log(decryptId);
  // const searchText = useSelector((state) => state.modal.searchText);
  const [searchParams, setSearchParams] = useSearchParams();
  const searchText = searchParams.get("query") || "";
  const token = localStorage.getItem("token") ? localStorage.getItem("token") : undefined;


  const { data: viewTrails, isLoading, isSuccess, isError, error } = useGetViewTrailGroupQuery({ id: decryptId, searchText });

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <>
        {/* <!-- start Your Trails & Favorites --> */}
        <div>
          <h4 className="heading_title">
            <Link to="/trail" className="backarrow">
              <i className="fas fa-arrow-left"></i>
            </Link>
            {viewTrails?.data?.group_name}
          </h4>
          <div className="row">{viewTrails?.data?.trail_bunch && viewTrails?.data?.trail_bunch.length > 0 ? <TrailListCard data={viewTrails?.data?.trail_bunch} classList={"col-lg-3 col-md-4 col-sm-6 col-12"} displayHeart={true} link={true} /> : <NoFoundComponent message={"No Trail Lists Found. Create a Trail List."} />}</div>
        </div>
        {
          token ?

            <div className="trail_btn">
              <Link className="add_trail" onClick={() => dispatch(addTrailModal(true))}>
                <i className="fas fa-plus"></i> Add a trail
              </Link>
            </div>
            : ""
        }
        {/* <!-- start Your Trails & Favorites --> */}
      </>
    );
  }
};
