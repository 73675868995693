import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Modal } from "reactstrap";
import { useChangePasswordMutation } from "../../API/profileAPI";
import { Loader } from "../../components/Loader";
import { changePasswordModal, logInModal } from "../../redux/modalSlice";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Formik } from "formik";
import { config } from "../../config";

export const ChangePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [passwordType, setPasswordType] = useState(true);
  const [newPasswordType, setNewPasswordType] = useState(true);
  const [newConfirmPasswordType, setNewConfirmPasswordType] = useState(true);
  const [passwordMisMatchError, setPasswordMisMatchError] = useState(false);

  const initialVlues = {
    current_password: "",
    password: "",
    confirm_password: "",
  };

  const [changePassword, { data: user, isLoading, error, isError, isSuccess }] = useChangePasswordMutation();

  const changePasswordState = useSelector((state) => {
    return state.modal.changePasswordModal;
  });

  const closeModal = () => {
    dispatch(changePasswordModal({ modal: false }));
    setPasswordMisMatchError("");
  };

  const onSubmit = (values) => {
    changePassword(values);
  };

  useEffect(() => {
    if (isSuccess && user.status === 200) {
      localStorage.clear();
      dispatch(changePasswordModal({ modal: false }));
      toast(user.message, {
        ...config.toast_config,
        type: "success",
      });
      dispatch(logInModal(true));
    } else if (isSuccess && user.status === 422) {
      toast(user.message, {
        ...config.toast_config,
        type: "error",
      });
    }
  }, [isSuccess]);

  const ChangePasswordSchema = Yup.object().shape(
    {
      current_password: Yup.string().matches(config.passwordRegex, "Password should be 8 characters long and contain at least 1 capital letter, 1 lowercase letter, 1 number and 1 special character.").required("Current Password is required."),
      password: Yup.string().matches(config.passwordRegex, "Password should be 8 characters long and contain at least 1 capital letter, 1 lowercase letter, 1 number and 1 special character.").required("Password is required."),
      confirm_password: Yup.string()
        .matches(config.passwordRegex, "Password should be 8 characters long and contain at least 1 capital letter, 1 lowercase letter, 1 number and 1 special character.")
        .required("Confirm Password is required.")
        .oneOf([Yup.ref("password")], "Password and confirm password must match"),
    },
    [["password"]]
  );

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <Modal isOpen={changePasswordState} centered={true} contentClassName="login_form" toggle={closeModal}>
        <div className="modal-header">
          <h1 className="modal-title" id="exampleModalLabel">
            Change Password
          </h1>
          <button type="button" className="btn-close" onClick={() => closeModal()} aria-label="Close">
            <i className="fas fa-times"></i>
          </button>
        </div>
        <Formik
          initialValues={initialVlues}
          validationSchema={ChangePasswordSchema}
          onSubmit={(values) => {
            onSubmit(values);
          }}
        >
          {({ errors, touched, handleSubmit, values, handleChange, isInitialValid, isValid }) => (
            <div>
              <div className="modal-body">
                <form className="form_field">
                  <div className="mb-3">
                    <label className="mb-1">Old Password</label>
                    <div className="position-relative">
                      <input type={passwordType ? "password" : "text"} className="form-control pe-5" name="current_password" onChange={handleChange} placeholder="*********" />
                      <span toggle="#oldpassword" className={passwordType ? "toggle_password " : "toggle_password slash_icon"} onClick={() => setPasswordType(!passwordType)}>
                        <img src={require("../../assets/images/eye.png")} alt="password-icon" title={passwordType ? "Show Password" : "Hide Password"} />
                      </span>
                      {errors.current_password && touched.current_password && <div className="mt-1 text-danger w-100">{errors.current_password}</div>}
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="mb-1">New Password</label>
                    <div className="position-relative">
                      <input type={newPasswordType ? "password" : "text"} className="form-control pe-5" name="password" placeholder="*********" onChange={handleChange} />
                      <span toggle="#new1password" className={newPasswordType ? "toggle_password " : "toggle_password slash_icon"} onClick={() => setNewPasswordType(!newPasswordType)}>
                        <img src={require("../../assets/images/eye.png")} alt="password-icon" title={newPasswordType ? "Show Password" : "Hide Password"} />
                      </span>
                      {errors.password && touched.password && <div className="mb-3 text-danger w-100">{errors.password}</div>}
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="mb-1">Confirm Password</label>
                    <div className="position-relative">
                      <input type={newConfirmPasswordType ? "password" : "text"} className="form-control pe-5" name="confirm_password" placeholder="*********" onChange={handleChange} />
                      <span toggle="#confirm1password" className={newConfirmPasswordType ? "toggle_password " : "toggle_password slash_icon"} onClick={() => setNewConfirmPasswordType(!newConfirmPasswordType)}>
                        <img src={require("../../assets/images/eye.png")} alt="password-icon" title={newConfirmPasswordType ? "Show Password" : "Hide Password"} />
                      </span>
                      {errors.confirm_password && touched.confirm_password && <div className="mb-3 text-danger w-100">{errors.confirm_password}</div>}
                    </div>
                  </div>
                  {passwordMisMatchError && <div className="mb-3 text-danger w-100">Please check entered password and confirm password are not same.</div>}
                </form>
              </div>
              <div className="modal-footer">
                <button type="submit" className="btn btn-primary save" disabled={!isValid} onClick={(e) => handleSubmit(values)}>
                  Save
                </button>
              </div>
            </div>
          )}
        </Formik>
      </Modal>
    );
  }
};
