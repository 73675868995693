import React, { useState } from "react";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import LoginPage from "./auth/LoginPage";
import ForgotPassword from "./auth/ForgotPassword";
import VerifyEmail from "./auth/VerifyEmail";
import ResetPassword from "./auth/ResetPassword";
import Register from "./auth/SignUpPage";
import { Profile } from "./auth/Profile";
import { ThruhikeConcierge } from "./trail/ThruhikeConcierge";
import { AddCustomTrail } from "./trail/AddCustomTrail";
import { CustomTrailThanks } from "./trail/CustomTrailThanks";
import { ThruhikeConciergeThanks } from "./trail/ThruhikeConciergeThanks";
import { Link, Outlet, useLocation } from "react-router-dom";
import { TrailAdd } from "./trail/TrailAdd";
import { AddTag } from "./tag/AddTag";
import { YourTrails } from "./trail/YourTrails";
import { Share } from "./trail/Share";
import { ClaimBusiness } from "./business/ClaimBusiness";
import { ChangePassword } from "./auth/ChangePassword";
import { ToastContainer, toast } from "react-toastify";
import { Updates } from "./post/updates";
import IntroSlider from "./auth/IntroSlider";

const Page = () => {
  const currentPath = useLocation();
  const token = localStorage.getItem("token");
  console.log("=====>", currentPath.pathname);

  return (
    <div className={currentPath.pathname === "/" || currentPath.pathname === "/posts" ? "" : currentPath.pathname.includes("post-details") || currentPath.pathname === "/trail" || currentPath.pathname.includes("trailandfavourite") || currentPath.pathname === "/subscriptions" ? "bg-white" : ""}>
      <Header />
      <div className={(currentPath.pathname === "/" || currentPath.pathname === "/vendor") && !token ? "main_content single_home" : currentPath.pathname === "/subscriptions" ? "main_content subscribe_padding" : "main_content"}>
        <div className={(currentPath.pathname === "/" || currentPath.pathname === "/vendor") && !token ? "" : currentPath.pathname === "/subscriptions" ? "" : "container"}>
          <Outlet />
        </div>

        {/* <!-- Notification --> */}
        <ToastContainer />

        {/* <!-- add all Modal here --> */}

        {/*  <!-- Add sign in Modal --> */}
        <LoginPage />
        {/*  <!-- Add forgot Modal --> */}
        <ForgotPassword />

        {/* <!-- Add sign up Modal --> */}
        <Register />

        {/* <!-- Add View Profile Modal --> */}
        <Profile />

        {/* <!-- Add Verification Code Modal --> */}
        <VerifyEmail />

        {/* <!-- Add Reset Password Modal --> */}
        <ResetPassword />

        {/* <!-- Add Change Password Modal --> */}
        <ChangePassword />

        {/* <!-- Add Custom Trail List --> */}
        <AddCustomTrail />

        {/* <!-- Custom Trail List Thank you --> */}
        <CustomTrailThanks />

        {/* <!-- Add Thruhike Concierge --> */}
        <ThruhikeConcierge />

        {/* <!-- Thruhike Concierge Thank you --> */}
        <ThruhikeConciergeThanks />

        {/* <!-- Add traipopup Modal --> */}
        <YourTrails />

        {/* <!-- Add sharepost Modal --> */}
        <Share />

        {/* <!-- Add intro slider Modal --> */}
        <IntroSlider />

        {/* <div
          className="modal fade"
          id="sharepost"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title" id="exampleModalLabel">
                  Share
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fas fa-times"></i>
                </button>
              </div>
              <div className="modal-body">
                <div className="d-flex justify-content-between">
                  <Link to="#" className="rounded-circle">
                    <img
                      src={require("../assets/images/facebook.png")}
                      alt=""
                      title=""
                    />
                  </Link>
                  <Link to="#" className="rounded-circle">
                    <img
                      src={require("../assets/images/instagram.png")}
                      alt=""
                      title=""
                    />
                  </Link>
                  <Link to="#" className="rounded-circle">
                    <img
                      src={require("../assets/images/tweeter.png")}
                      alt=""
                      title=""
                    />
                  </Link>
                  <Link to="#" className="rounded-circle">
                    <img
                      src={require("../assets/images/whatsapp.png")}
                      alt=""
                      title=""
                    />
                  </Link>
                  <Link to="#" className="rounded-circle">
                    <img
                      src={require("../assets/images/gmail.png")}
                      alt=""
                      title=""
                    />
                  </Link>
                </div>
                <form className="form_field">
                  <div className="input-group mt-3">
                    <input
                      type="text"
                      className="form-control"
                      value="https://gothruhike.com/"
                      aria-describedby="sharelink"
                    />
                    <button
                      className="btn btn_copy"
                      type="button"
                      id="sharelink"
                      onClick={() => {navigator.clipboard.writeText(this.state.textToCopy)}}
                    >
                      Copy
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div> */}

        {/* <!-- Add Claim the Business List --> */}
        <ClaimBusiness />

        {/* <!-- Add shareprofile Modal --> */}
        {/* <!-- <div className="modal fade" id="shareprofile" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title" id="exampleModalLabel">Share Profile</h1>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                    <i className="fas fa-times"></i>
                  </button>
                </div>
                <div className="modal-body">
                  <label>Social</label>
                  <div className="d-flex mt-4">
                    <Link to="#" className="me-4 rounded-circle">
                      <img src={require("../assets/images/linkedin.png")} alt="" title="" />
                    </Link>
                    <Link to="#" className="me-4 rounded-circle">
                      <img src={require("../assets/images/facebook.png")} alt="" title="" />
                    </Link>
                    <Link to="#" className="me-4 rounded-circle">
                      <img src={require("../assets/images/tweeter.png")} alt="" title="" />
                    </Link>
                  </div>
                  <form className="form_field mt-4 mb-4">
                    <label>Link</label>
                    <div className="input-group mt-3">
                      <input type="text" className="form-control" value="https://thruhike.com.in/loremispum" aria-describedby="sharelink" />
                      <button className="btn btn_copy" type="button" id="sharelink">Copy</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div> --> */}

        {/* <!-- Add tag Modal --> */}
        <AddTag />

        {/* <!-- Add createtrail Modal --> */}
        <TrailAdd />

        {/* <!-- post updates Modal --> */}
        <Updates />
      </div>
      <Footer />
    </div>
  );
};

export default Page;
