import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Modal } from "reactstrap";
import { useGenerateOtpMutation, useVerifyUserMutation } from "../../API/authAPI";
import { setUser } from "../../redux/authSlice";
import { introSliderModal, resetPasswordModal, verifyEmailModal } from "../../redux/modalSlice";
import OTPInput, { ResendOTP } from "otp-input-react";
import { config } from "../../config";
import { toast } from "react-toastify";

const VerifyEmail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [second, setSecond] = useState(300);
  const [formError, setFormError] = useState("");

  const verifyEmailState = useSelector((state) => state.modal.verifyEmailModal);
  const [verifyUser, { data, isLoading, error, isError, isSuccess }] = useVerifyUserMutation();
  const [generateOtp, { data: otpData, isLoading: otpDataIsLoading, error: otpDataError, isError: otpDataIsError, isSuccess: otpDataIsSuccess }] = useGenerateOtpMutation();
  const [code, setCode] = useState([]);

  const userEmail = useSelector((state) => state.modal.email);
  const isForgotPassword = useSelector((state) => state.modal.isForgotPassword);

  const submitVerificationCode = () => {
    const formData = {};
    formData["email"] = userEmail;
    formData["code"] = code;
    if (formData && formData.email !== "" && formData.email !== undefined && formData.code !== "") {
      verifyUser(formData);
    }
  };

  //timer code starts
  useEffect(() => {
    let timer;
    if (second > 0) {
      timer = setTimeout(() => setSecond(second - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [verifyEmailState, second]);
  //timer code ends

  useEffect(() => {
    if (isSuccess && data.status === 200) {
      if (isForgotPassword) {
        //if users comes from sign up model then reset password form
        dispatch(resetPasswordModal({ modal: true, email: userEmail }));
      } else {
        toast(data.message, {
          ...config.toast_config,
          type: "success",
        });
        navigate(0);
        //if users comes from sign up model then direct login
        dispatch(setUser(data));
        localStorage.setItem("introSlider", "true");
        // dispatch(introSliderModal(true));
      }
      dispatch(verifyEmailModal({ modal: false, isForgotPassword: false, email: userEmail }));
      setCode("");
      setFormError("");
    } else if (isSuccess && data.status === 422) {
      setFormError(data?.message);
      setCode("");
      toast(data.message, {
        ...config.toast_config,
        type: "error",
      });
    }
  }, [isSuccess]);

  const reSendCode = (e) => {
    setSecond(300);
    generateOtp({ email: userEmail });
  };

  useEffect(() => {
    if (otpDataIsSuccess && otpData.status === 200) {
      toast(otpData.message, {
        ...config.toast_config,
        type: "success",
      });
    } else if (otpDataIsSuccess && otpData.status === 422) {
      toast(otpData.message, {
        ...config.toast_config,
        type: "error",
      });
    }
  }, [otpDataIsSuccess]);

  const convertStoMs = (seconds) => {
    let minutes = Math.floor(seconds / 60);
    let extraSeconds = seconds % 60;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    extraSeconds = extraSeconds < 10 ? "0" + extraSeconds : extraSeconds;
    return minutes + " : " + extraSeconds;
  };

  return (
    <Modal isOpen={verifyEmailState} centered={true} contentClassName="login_form">
      <div className="modal-header">
        <h1 className="modal-title text-center w-100" id="exampleModalLabel">
          <Link to="#" className="d-flex mt-3">
            <i className="fas fa-chevron-left me-3" style={{ color: "#66737F", fontSize: "15px" }}></i>
          </Link>
          <img src={require("../../assets/images/favicon.png")} alt="" title="" />
          <p className="model_title_header">Verification Code</p>
          <span className="text_popup">Enter the code we sent to your registered email</span>
        </h1>
        <button
          type="button"
          className="btn-close"
          onClick={() => {
            setCode("");
            setFormError("");
            dispatch(verifyEmailModal({ modal: false }));
          }}
        >
          <i className="fas fa-times"></i>
        </button>
      </div>
      <div className="modal-body">
        <div className="row">{formError !== "" ? <div className="d-flex text-danger justify-content-center mb-2">{formError}</div> : ""}</div>

        <form className="form_field">
          <div className="userInput validationcode mb-3">
            <div className="row">
              <OTPInput
                value={code}
                onChange={(e) => {
                  // console.log("e ::", e);
                  setFormError("");
                  setCode(e);
                }}
                autoFocus
                OTPLength={5}
                otpType="number"
                disabled={false}
                secure
                inputClassName="form-control"
                className="d-flex justify-content-center"
              />
            </div>
          </div>
          <span className="text_popup d-block text-center">
            Code expires in &nbsp;
            <i style={{ color: "#D30B0B", fontStyle: "normal" }}>{convertStoMs(second)}</i>
          </span>
          <div className="row">
            <div className="col-md-12 my-3">
              <button
                type="button"
                className="btn btn-primary save"
                // data-bs-toggle="modal"
                // data-bs-target="#ResetPassword"
                onClick={() => submitVerificationCode()}
              >
                Confirm
              </button>
            </div>
          </div>
          <p className="m-0 text-center link_text">
            Didn't receive a code? &nbsp;
            <Link href="#" onClick={(e) => reSendCode(e)} disabled={second > 0}>
              Resend code
            </Link>
          </p>
        </form>
      </div>
    </Modal>
  );
};

export default VerifyEmail;
